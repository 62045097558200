import { dateInitFormats } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import { PARTICIPANTS_PATHS, RESOURCES_PATHS } from '@common/network/ApiPaths';

import createFields from './createFields';
import initialValues from './initialValues';

export const getFieldsConfig = (setFieldValue, setValues, values, currency) => {
  return mapPropsToComponent(createFields, {
    ['hotel.id']: {
      tokenValue:
        values?.hotel?.id && values?.hotel?.name
          ? [
              {
                title: `${values.hotel.name} `
              }
            ]
          : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setFieldValue('hotel', initialValues.hotel);
          setFieldValue('roomType', initialValues.accommodation);
        }
      },
      selectItem: (value) => {
        setFieldValue('hotel', { ...value });
        setFieldValue('hotel.id', value?.id);
      }
    },
    ['accommodation.id']: {
      disabled: Boolean(!values?.hotel?.id),
      initQueryState: { id: values?.hotel?.id },
      fetchDataPath: PARTICIPANTS_PATHS.HOTELS_ACCOMMODATIONS.replace(':id', values?.hotel?.id),
      tokenValue: values.accommodation?.roomType?.value
        ? [
            {
              title: `${values.accommodation?.roomType?.value} `
            }
          ]
        : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setFieldValue('accommodation', initialValues.accommodation);
          setFieldValue('maxOccupancy', initialValues.maxOccupancy);
        }
      },
      selectItem: (value) => {
        setValues({
          ...values,
          accommodation: { ...value },
          maxOccupancy: value.maxOccupancy
        });
      }
    },
    ['startDate']: {
      ampm: false,
      onChange: (value) => setFieldValue('startDate', dateToFormat(value, dateInitFormats.dateTime))
    },
    ['endDate']: {
      ampm: false,
      onChange: (value) => setFieldValue('endDate', dateToFormat(value, dateInitFormats.dateTime))
    },
    ['validUntil']: {
      ampm: false,
      onChange: (value) =>
        setFieldValue('validUntil', dateToFormat(value, dateInitFormats.dateTime))
    },
    ['currency']: {
      path: RESOURCES_PATHS.CURRENCIES,
      options: currency
    }
  });
};

export default getFieldsConfig;
