import React, { useEffect, useRef, useCallback } from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { SORTING_ORDER } from '@common/Constants';
import { onTabsTableFilterChange } from '@common/helpers/tables/getAdvancedFiltersForMUITable';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import {
  getFilterOperatorsBasedOnType,
  getFilterValueOptionsBasedOnType
} from '@config/filterOperators';
import { TABLE_OPTIONS } from '@config/network';
import {
  fetchParticipations,
  resetState,
  selectFilter,
  selectIsLoading,
  selectList,
  selectSelectionModel,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  setSelectionModel
} from '@pages/Employees/components/Tabs/ParticipationTable/participationTableSlice';
import { selectDetails as selectEmployeeDetails } from '@pages/Employees/employeesSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  content: {
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  },
  cellCheckbox: {
    padding: '5px 40px 5px 5px !important'
  },
  customTable: {
    overflowX: 'hidden',
    width: '92rem',
    '&:lastChild': {
      width: '100%',
      minWidth: 200
    }
  }
}));

const ParticipationTable = ({ columns = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const trackFilterModel = useRef(null);
  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);
  const details = useSelector(selectEmployeeDetails);

  const {
    data: {
      type: { data: eventsType },
      participantStatuses: { data: participantStatus }
    }
  } = useMatch();

  useEffect(() => {
    const { personDto } = details || {};
    const id = personDto?.id;

    if (id) {
      dispatch(fetchParticipations({ entityId: id, filter }));
    }
  }, [details, filter?.size, filter?.page, filter?.sortBy, filter?.sortDirection, filter?.filters]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const onFilterChange = (values) => {
    const advancedFilters = onTabsTableFilterChange(values, trackFilterModel, () =>
      dispatch(setFilterParams({ key: 'filters', value: null }))
    );
    if (advancedFilters) dispatch(setFilterParams({ key: 'filters', value: { advancedFilters } }));
  };

  const onSortChange = (sortModel) =>
    dispatch(
      setFilterParams([
        { key: 'sortBy', value: sortModel[0]?.field },
        { key: 'sortDirection', value: sortModel[0]?.sort }
      ])
    );

  const getSingleSelectValueOptions = useCallback((field) => {
    switch (field) {
      case 'status': {
        return participantStatus.map(({ value }) => value);
      }
      case 'type': {
        return eventsType.map(({ value }) => value);
      }
      default:
        return [];
    }
  }, []);

  return (
    <>
      <div className={classes.root}>
        <TabContent className={classes.content}>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('Labels.Participations')}
            </Typography>
            <Toolbar className={classes.toolbar}></Toolbar>
          </div>
          <TabContainer>
            <StyledDataGrid
              keepNonExistentRowsSelected={true}
              loading={isLoading}
              rows={data}
              columns={[
                ...columns.map((column) => ({
                  ...column,
                  headerName: Localize.get(column.headerName),
                  filterOperators: getFilterOperatorsBasedOnType(column?.type),
                  valueOptions: getFilterValueOptionsBasedOnType(
                    column,
                    getSingleSelectValueOptions
                  ),
                  renderCell: (params) => (
                    <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                      {['name', 'location', 'type'].includes(params.field) ? (
                        <Tooltip title={params.value} arrow>
                          <Typography
                            noWrap
                            variant="body"
                            component="div"
                            sx={{
                              maxWidth: params?.colDef?.width - 10 // 10 px for three dots
                            }}
                          >
                            {params.value}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography variant="body" component="div">
                          {params.value}
                        </Typography>
                      )}
                    </div>
                  )
                }))
              ]}
              initialState={{
                pagination: {
                  pageSize: totalPages,
                  rowCount: totalElements,
                  page: filter?.page
                }
              }}
              onSelectionModelChange={(newSelectionModel) => {
                dispatch(setSelectionModel(newSelectionModel));
              }}
              selectionModel={selectionModel}
              checkboxSelection
              localeText={getLocalesText(Localize)}
              pagination
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              disableColumnMenu
              page={filter?.page}
              pageSize={filter?.size}
              rowCount={totalElements}
              rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
              disableSelectionOnClick
              components={{
                Toolbar: () => <TableToolbar />
              }}
              onPageChange={(page) =>
                dispatch(setFilterParams({ key: 'page', value: page >= 0 ? page : 0 }))
              }
              onPageSizeChange={(value) => dispatch(setFilterParams([{ key: 'size', value }]))}
              sortingOrder={SORTING_ORDER}
              onSortModelChange={onSortChange}
              onFilterModelChange={onFilterChange}
            />
          </TabContainer>
        </TabContent>
      </div>
    </>
  );
};

export default ParticipationTable;
