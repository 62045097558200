import { copyObjectWithoutRef } from '@common/helpers/helpers';
import scrubDtos from '@common/helpers/scrubDtos';

import initialValues from '../initialValues';

export const formatParticipantForDetails = (res) => {
  return {
    ...res,
    personDto: {
      ...res.personDto,
      birthDate: res?.personDto?.birthDate ?? null,
      birthPlace: res?.personDto?.birthPlace ?? '',
      title: res?.personDto?.title ?? { id: 0, value: '' }
    },
    cancellationReason: res?.cancellationReason ?? '',
    company: res?.company ?? '',
    hotelReservation: res?.hotelReservation ?? initialValues.hotelReservation,
    nativeLanguage: res?.nativeLanguage ? res?.nativeLanguage : '',
    status: res?.status ?? '',
    result: res?.result ?? '',
    biography: res?.biography ?? '',
    emailDtos: res?.emailDtos?.map((el) => ({ ...el, email: el?.email ?? '' }))
  };
};

export const formatParticipantForEdit = (res) => {
  return {
    ...res,
    personDto: {
      ...res.personDto,
      birthDate: res?.personDto?.birthDate ?? null,
      birthPlace: res?.personDto?.birthPlace ?? '',
      website: res?.personDto?.website ?? null
    },
    statusId: res?.status?.id ?? null,
    nativeLanguageId: res?.nativeLanguage?.id ?? null,
    companyId: res?.company?.id ?? null,
    invoiceCompanyId: res?.invoiceCompany?.id ?? null,
    cancellationReasonId: res?.cancellationReason?.id ?? null,
    resultId: res?.result?.id ?? null,
    eventId: res?.event?.id ?? null,
    addressDtos: scrubDtos(res, 'addressDtos'),
    emailDtos: scrubDtos(res, 'emailDtos'),
    phoneDtos: scrubDtos(res, 'phoneDtos'),
    typeId: res?.type?.id ?? null
  };
};

export const formatParticipantForSave = (participant) => {
  const localParticipant = copyObjectWithoutRef(participant);
  let hasHotel = localParticipant?.hotelReservation?.id?.hotel?.id;

  if (!hasHotel) {
    localParticipant.hotelReservation = null;
  }

  if (!localParticipant.masterEventParticipantId) localParticipant.masterEventParticipantId = null;

  localParticipant.allergies = localParticipant.allergies.filter((allergy) => allergy.id !== '');

  localParticipant.addressDtos = scrubDtos(localParticipant, 'addressDtos');
  localParticipant.emailDtos = scrubDtos(localParticipant, 'emailDtos');
  localParticipant.phoneDtos = scrubDtos(localParticipant, 'phoneDtos');

  return {
    ...localParticipant,
    personDto: {
      ...localParticipant.personDto,
      birthDate: localParticipant?.personDto?.birthDate ?? null,
      birthPlace: localParticipant?.personDto?.birthPlace ?? null,
      website: localParticipant?.personDto?.website ?? null,
      gender: localParticipant?.personDto?.gender ?? null
    },
    eventId: localParticipant.event?.id ?? null,
    companyId: localParticipant.company?.id ?? null,
    statusId: localParticipant.status ?? null,
    cancellationReasonId: localParticipant?.cancellationReason?.id ?? null,
    resultId: localParticipant?.result.id ?? null,
    nativeLanguageId: localParticipant.nativeLanguage?.id
      ? localParticipant.nativeLanguage.id
      : null,
    invoiceCompanyId: localParticipant.invoiceCompany?.id ?? null,
    isEventParticipant: true,
    typeId: 1
  };
};
