import { useEffect, useRef } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';

import { SORTING_ORDER } from '@common/Constants';
import { onTabsTableFilterChange } from '@common/helpers/tables/getAdvancedFiltersForMUITable';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import { openConfirmDialog, CONFIRM_ACTIONS } from '@components/ConfirmDialog';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarButton from '@components/ToolbarButton';
import { getFilterOperatorsBasedOnType } from '@config/filterOperators';
import { TABLE_OPTIONS } from '@config/network';

import {
  resetState,
  selectIsLoading,
  selectData,
  selectFilter,
  selectTotalElements,
  selectSelectionModel,
  setFilterParams,
  setSelectionModel,
  getAttachments,
  removeAttachments,
  uploadAttachment
} from './attachmentsTableSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`
    }
  }
}));

const AttachmentsTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarButtons = [],
  setDetails = () => {}
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const trackFilterModel = useRef(null);

  const isLoading = useSelector(selectIsLoading);
  const data = useSelector(selectData);
  const filter = useSelector(selectFilter);
  const totalElements = useSelector(selectTotalElements);
  const selectionModel = useSelector(selectSelectionModel);

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(getAttachments({ entityId, entityType, filter }));
    }
  }, [
    entityId,
    entityType,
    filter?.size,
    filter?.page,
    filter?.sortBy,
    filter?.sortDirection,
    filter?.filters,
    totalElements
  ]);

  const onToolbarButtonClick = (name, files) => {
    switch (name) {
      case 'add':
        if (files.length > 1) {
          alert('Batch upload currently not supported');
          return;
        }

        dispatch(uploadAttachment({ entityId, entityType, file: files[0], setDetails }));
        break;

      case 'delete': {
        if (selectionModel.length > 1) {
          alert('Batch delete currently not supported');
          return;
        }

        dispatch(
          openConfirmDialog({
            title: Localize.get('ConfirmationMessages.Delete', {
              item: Localize.get('Labels.Attachment')?.toLowerCase()
            }),
            confirmButton: Localize.get('Buttons.Delete'),
            cancelButton: Localize.get('Buttons.Cancel')
          })
        )
          .unwrap()
          .then((result) => {
            if (result === CONFIRM_ACTIONS.Confirm) {
              dispatch(
                removeAttachments({
                  entityId,
                  entityType,
                  id: selectionModel[0],
                  setDetails
                })
              );
            }
          });

        break;
      }

      default:
        break;
    }
  };

  const isToolbarButtonDisabled = (name) => {
    switch (name) {
      case 'add':
        return !entityId || !entityType;
      case 'delete':
        return selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onFilterChange = (values) => {
    const advancedFilters = onTabsTableFilterChange(values, trackFilterModel, () =>
      dispatch(setFilterParams({ key: 'filters', value: null }))
    );
    if (advancedFilters) dispatch(setFilterParams({ key: 'filters', value: { advancedFilters } }));
  };

  const onSortChange = (sortModel) =>
    dispatch(
      setFilterParams([
        { key: 'sortBy', value: sortModel[0]?.field },
        { key: 'sortDirection', value: sortModel[0]?.sort }
      ])
    );

  return (
    <div className={classes.root}>
      <TabContent>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6" component="h6" color="primary">
            {Localize.get('Labels.Attachments')}
          </Typography>
          <Toolbar className={classes.toolbar}>
            {toolbarButtons?.map((button, index) => (
              <ToolbarButton
                key={index}
                button={button}
                isDisabled={button.disabled || isToolbarButtonDisabled(button.name)}
                onToolbarButtonClick={onToolbarButtonClick}
              />
            ))}
          </Toolbar>
        </div>
        <TabContainer>
          <DataGrid
            loading={isLoading}
            rows={data}
            getRowClassName={() => classes.tableRow}
            columns={[
              ...columns.map((column) => ({
                ...column,
                headerName: Localize.get(column.headerName),
                filterOperators: getFilterOperatorsBasedOnType(column?.type),
                renderCell: (params) => {
                  return (
                    <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                      {['name', 'createdBy'].includes(params.field) ? (
                        <Tooltip title={params.value} arrow>
                          <Typography
                            noWrap
                            variant="body"
                            component="div"
                            sx={{
                              maxWidth: params?.colDef?.width - 10 // 10 px for three dots,
                            }}
                          >
                            {params.value}
                          </Typography>
                        </Tooltip>
                      ) : (
                        <Typography variant="body" component="div">
                          {params.value}
                        </Typography>
                      )}
                    </div>
                  );
                }
              }))
            ]}
            initialState={{
              pagination: {
                pageSize: 1,
                rowCount: totalElements,
                page: filter?.page
              }
            }}
            localeText={getLocalesText(Localize)}
            paginationMode="server"
            pagination
            sortingMode="server"
            filterMode="server"
            checkboxSelection
            disableColumnMenu
            page={filter?.page}
            pageSize={filter?.size}
            rowCount={totalElements}
            rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
            disableSelectionOnClick
            components={{ Toolbar: TableToolbar }}
            onSelectionModelChange={(values) => dispatch(setSelectionModel(values))}
            onPageChange={(page) =>
              dispatch(setFilterParams({ key: 'page', value: page >= 0 ? page : 0 }))
            }
            sortingOrder={SORTING_ORDER}
            onPageSizeChange={(value) => dispatch(setFilterParams([{ key: 'size', value }]))}
            onSortModelChange={onSortChange}
            onFilterModelChange={onFilterChange}
          />
        </TabContainer>
      </TabContent>
    </div>
  );
};

export default AttachmentsTable;
