import React, { useEffect, useMemo } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import { omit } from 'lodash';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import { copyObjectWithoutRef } from '@common/helpers/helpers';
import CreateForm from '@components/CreateForm';
import InlineCreateHeaderContainer from '@components/InlineCreateHeaderContainer/InlineCreateHeaderContainer';
import { setCreatedBusinessPartner } from '@pages/BusinessPartners/businessPartnersSlice';
import { selectDetails, WEBINAR_TYPE_ID } from '@pages/IltSession/iltSessionSlice';
import { classroomFields, webinarFields } from '@pages/IltSession/util/fields/createTeam';

import { fetchSessionDays, selectIsLoading, selectList } from '../../SessionDays/sessionDaysSlice';
import { createTeam } from '../teamTableSlice';
import createTeamSchema from './createTeamSchema';
import getFieldsConfig from './getFieldsConfig';
import initialValues from './initialValues';

const CreateTeam = ({ entityId = null }) => {
  const {
    data: {
      teamMemberRoles: { data: teamMemberRoles }
    }
  } = useMatch();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const sessionDays = useSelector(selectList);
  const session = useSelector(selectDetails);
  const isSessionDaysLoading = useSelector(selectIsLoading);

  useEffect(() => window.scrollTo(0, 0), []);

  useEffect(() => {
    if (entityId) {
      dispatch(fetchSessionDays({ filter: { sessionId: entityId, page: 0, size: 50 } }));
    }
  }, [entityId]);

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(createTeam(values))
      .unwrap()
      .then(() =>
        navigate({
          search: (previousUrlParams) => ({ ...omit(previousUrlParams, 'mode') })
        })
      )
      .catch(() => setSubmitting(false));
  };

  const onCancel = () => {
    dispatch(setCreatedBusinessPartner(null));
    navigate({ search: (previousUrlParams) => ({ ...omit(previousUrlParams, 'mode') }) });
  };

  const getFieldsForSessionDays = useMemo(() => {
    // If there are no session days added to this Ilt Session
    if (!sessionDays.length) {
      return session?.type?.id === WEBINAR_TYPE_ID ? webinarFields : classroomFields;
    }

    if (sessionDays.length) {
      const fields =
        session?.type?.id === WEBINAR_TYPE_ID
          ? copyObjectWithoutRef(webinarFields)
          : copyObjectWithoutRef(classroomFields);

      fields.push(
        {
          groupName: 'IltSession.IltSessionRole',
          fields: [
            {
              label: 'IltSession.IltSessionName',
              name: 'iltSessionName',
              component: 'text',
              disabled: true,
              column: 5,
              noWrap: true,
              value: session?.name
            },
            {
              label: 'IltSession.SelectRole',
              name: 'iltSessionRole',
              required: true,
              disabled: false,
              component: 'select',
              options: teamMemberRoles,
              column: 5
            }
          ]
        },
        {
          groupName: 'IltSession.SessionDays',
          fields: Array.from(sessionDays, (el) => [
            {
              label: 'IltSession.SessionDayName',
              name: `sessionName-${el.id}`,
              required: false,
              disabled: true,
              component: 'text',
              column: 5,
              value: el.name
            },
            {
              label: 'IltSession.SelectRole',
              name: `sessionIdRole-${el.id}`,
              required: true,
              disabled: false,
              component: 'select',
              options: teamMemberRoles,
              column: 5
            }
          ]).flat()
        }
      );

      return fields;
    }
  }, [sessionDays.length]);

  const getInitialValues = useMemo(() => {
    if (!sessionDays?.length) {
      return initialValues;
    }

    const sessionDayObjects = sessionDays.reduce(
      (obj, item) => ((obj[`sessionIdRole-${item.id}`] = 1), obj),
      {}
    );

    return { ...initialValues, ...sessionDayObjects };
  }, [sessionDays?.length]);

  return !isSessionDaysLoading ? (
    <Formik
      enableReinitialize={true}
      validateOnMount
      initialValues={getInitialValues}
      onSubmit={onSubmit}
      validationSchema={createTeamSchema}
    >
      {({ isSubmitting, values, setFieldValue, handleSubmit, isValid, setValues }) => (
        <>
          <InlineCreateHeaderContainer>
            <Button
              data-test-id="cancel-btn"
              variant="outlined"
              onClick={onCancel}
              color="error"
              sx={{ mr: 1 }}
              startIcon={<CancelIcon />}
            >
              {Localize.get('Buttons.Cancel')}
            </Button>
            <Button
              data-test-id="save-btn"
              variant="outlined"
              disabled={isSubmitting || !isValid}
              onClick={handleSubmit}
              startIcon={<SaveIcon />}
            >
              {Localize.get('Buttons.Save')}
            </Button>
          </InlineCreateHeaderContainer>

          <CreateForm
            values={values}
            fieldsConfig={getFieldsConfig(
              values,
              setFieldValue,
              setValues,
              getFieldsForSessionDays
            )}
          />
        </>
      )}
    </Formik>
  ) : null;
};

export default CreateTeam;
