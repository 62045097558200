import { FOCUS_FIELD_TIMEOUT } from '@common/Constants';
import mapPropsToComponent from '@common/helpers/mapPropsToComponent';
import ContactTypeDropdown from '@components/ContactTypeDropdown/ContactTypeDropdown';

import initialValues from './initialValues';

export const getFieldsConfig = (values, setFieldValue, setValues, getFieldsForSessionDays) => {
  return mapPropsToComponent(getFieldsForSessionDays, {
    ['company']: {
      tokenValue: values?.company?.name ? [{ title: values.company?.name }] : undefined,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setFieldValue('company', initialValues.company);
        }
      },
      selectItem: (value) => setFieldValue('company', value)
    },
    ['hotelRequest']: {
      onChange: (e) => {
        setFieldValue('hotelRequest', e.target.checked);
        if (e.target.checked) {
          setTimeout(
            () => document.getElementsByName('arrivalDate')[0]?.focus(),
            FOCUS_FIELD_TIMEOUT
          );
        }
      }
    },
    ['arrivalDate']: {
      disabled: !values?.hotelRequest,
      required: values?.hotelRequest,
      onChange: (value) => setFieldValue('arrivalDate', value)
    },
    ['departureDate']: {
      disabled: !values?.hotelRequest,
      required: values?.hotelRequest,
      onChange: (value) => setFieldValue('departureDate', value)
    },
    ['person.id']: {
      tokenValue: values?.person?.name ? [{ title: values.person.name }] : null,
      onIconClick: (e) => {
        if (e?.target?.name === 'delete') {
          setFieldValue('person', initialValues.person);
        }
      },
      selectItem: (value) => {
        setValues({
          ...values,
          person: {
            name: `${value.firstName} ${value.lastName}`,
            id: value?.id || null
          }
        });
      },
      selectionModel: values?.person?.id,
      extendedComponents: ContactTypeDropdown
    }
  });
};

export default getFieldsConfig;
