import { TAB_KEYS } from '@common/Constants';
import AttachmentsTable from '@components/AttachmentsTable';
import NotesTable from '@components/NotesTable';

import LocationsOverview from '../Overview/LocationsOverview';
import AccommodationTable from './AccommodationTable/AccommodationTable';
import ContactTable from './ContactTable/ContactTable';
import ContingentTable from './ContingentTable';
import HotelBookingTable from './HotelBooking/HotelBookingTable';
import MeetingRoomTable from './MeetingRoomTable/MeetingRoomTable';

export const LOCATIONS_TABS_COMPONENTS = {
  [TAB_KEYS.OVERVIEW]: LocationsOverview,
  [TAB_KEYS.NOTE]: NotesTable,
  [TAB_KEYS.ATTACHMENT]: AttachmentsTable,
  [TAB_KEYS.CONTINGENT]: ContingentTable,
  [TAB_KEYS.CONTACT]: ContactTable,
  [TAB_KEYS.HOTEL_BOOKING]: HotelBookingTable,
  [TAB_KEYS.ACCOMMODATION]: AccommodationTable,
  [TAB_KEYS.MEETING_ROOM]: MeetingRoomTable
};

const hotelTabs = {
  activeIndex: 0,
  tabs: [
    {
      label: 'Labels.Overview',
      key: TAB_KEYS.OVERVIEW,
      icon: 'info',
      isCountVisible: false
    },
    {
      label: 'Labels.Accommodations',
      key: TAB_KEYS.ACCOMMODATION,
      icon: 'king_bed',
      toolbarButtons: [
        {
          name: 'add_accommodation',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 120,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'roomType',
          headerName: 'Locations.RoomType',
          width: 250,
          editable: false,
          headerAlign: 'left',
          type: 'singleSelect',
          valueGetter: (params) => params?.row?.roomType?.value
        },
        {
          field: 'maxOccupancy',
          headerName: 'Locations.MaxOccupancy',
          width: 150,
          editable: false,
          type: 'number',
          headerAlign: 'left',
          align: 'left'
        },
        {
          field: 'description',
          headerName: 'Labels.Description',
          editable: false,
          width: 250,
          headerAlign: 'left'
        }
      ]
    },
    {
      label: 'Labels.Contingent',
      key: TAB_KEYS.CONTINGENT,
      icon: 'night_shelter',
      toolbarButtons: [
        {
          name: 'add',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 100,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'contingent',
          headerName: 'Labels.ContingentAmount',
          width: 150,
          editable: false,
          headerAlign: 'left',
          type: 'number',
          align: 'left'
        },
        {
          field: 'booked',
          headerName: 'Labels.Booked',
          width: 100,
          editable: false,
          headerAlign: 'left',
          filterable: false,
          sortable: false
        },
        {
          field: 'roomType',
          headerName: 'Hotel.RoomType',
          width: 150,
          editable: true,
          headerAlign: 'left',
          type: 'singleSelect',
          valueGetter: (params) => params?.row?.accommodation?.roomType?.value
        },
        {
          field: 'price',
          headerName: 'ResourcesTile.Price',
          width: 150,
          editable: false,
          defaultValue: '',
          headerAlign: 'left',
          type: 'number',
          align: 'left'
        },
        {
          field: 'usedIn',
          headerName: 'Labels.UsedIn',
          width: 200,
          editable: false,
          defaultValue: '',
          headerAlign: 'left',
          valueGetter: (params) => params?.row?.event?.name
        },
        {
          field: 'startDate',
          headerName: 'Events.StartDate',
          width: 150,
          editable: false,
          defaultValue: '',
          headerAlign: 'left',
          type: 'date'
        },
        {
          field: 'endDate',
          headerName: 'Events.EndDate',
          width: 150,
          editable: false,
          defaultValue: '',
          headerAlign: 'left',
          type: 'date'
        },
        {
          field: 'validTo',
          headerName: 'Labels.ValidUntil',
          width: 150,
          editable: false,
          defaultValue: '',
          headerAlign: 'left',
          type: 'date',
          valueGetter: (params) => params?.row?.validUntil
        }
      ]
    },
    {
      label: 'IltSession.HotelBooking',
      key: TAB_KEYS.HOTEL_BOOKING,
      icon: 'hotel',
      toolbarButtons: [
        {
          name: 'add',
          disabled: true,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 100,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'participantName',
          headerName: 'IltSession.ParticipantName',
          width: 200,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'companyName',
          headerName: 'Labels.CompanyName',
          width: 200,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'bookedIn',
          headerName: 'Labels.BookedIn',
          editable: false,
          width: 200,
          headerAlign: 'left',
          valueGetter: (params) => params?.row?.hotelName
        },
        {
          field: 'startDate',
          headerName: 'IltSession.Arrival',
          editable: false,
          headerAlign: 'left',
          width: 200,
          type: 'date',
          valueGetter: (params) => params?.row?.arrival
        },
        {
          field: 'endDate',
          headerName: 'IltSession.Departure',
          editable: false,
          headerAlign: 'left',
          width: 200,
          type: 'date',
          valueGetter: (params) => params?.row?.departure
        },
        {
          field: 'roomType',
          headerName: 'Hotel.RoomType',
          editable: false,
          headerAlign: 'left',
          width: 200,
          type: 'singleSelect'
        },
        {
          field: 'contingent',
          headerName: 'Labels.Contingent',
          editable: false,
          headerAlign: 'left',
          width: 200,
          type: 'number',
          align: 'left'
        }
      ]
    },
    {
      label: 'Launchpad.Contacts',
      key: TAB_KEYS.CONTACT,
      icon: 'contacts',
      toolbarButtons: [
        {
          name: 'add_contact',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 110,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'department',
          headerName: 'ContactsTile.Department',
          editable: false,
          width: 250,
          headerAlign: 'left'
        },
        {
          field: 'function',
          headerName: 'ContactsTile.Function',
          editable: false,
          headerAlign: 'left',
          width: 300
        },
        {
          field: 'jobTitle',
          headerName: 'Labels.Job',
          editable: false,
          headerAlign: 'left',
          width: 200
        }
      ]
    },
    {
      label: 'Labels.Attachments',
      key: TAB_KEYS.ATTACHMENT,
      icon: 'attachments',
      toolbarButtons: [
        {
          name: 'add',
          disabled: false,
          icon: 'add',
          type: 'upload'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'size',
          headerName: 'Labels.Size',
          width: 250,
          editable: false,
          defaultValue: '0 KB',
          headerAlign: 'left',
          filterable: false
        },
        {
          field: 'createdBy',
          headerName: 'Labels.CreatedBy',
          width: 250,
          editable: false,
          headerAlign: 'left',
          valueGetter: (params) => params?.value?.name || 'Unknown User'
        }
      ]
    },
    {
      label: 'Labels.Notes',
      key: TAB_KEYS.NOTE,
      icon: 'note_alt',
      toolbarButtons: [
        {
          name: 'add_note',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'type',
          headerName: 'Labels.Type',
          width: 250,
          editable: false,
          headerAlign: 'left',
          type: 'singleSelect'
        },
        {
          field: 'text',
          headerName: 'Labels.Text',
          width: 250,
          editable: false,
          headerAlign: 'left',
          filterable: false,
          sortable: false
        }
      ]
    }
  ]
};

const hotelAndVenueTabs = {
  activeIndex: 0,
  tabs: [
    {
      label: 'Labels.Overview',
      key: TAB_KEYS.OVERVIEW,
      icon: 'info',
      isCountVisible: false
    },
    {
      label: 'Labels.Accommodations',
      key: TAB_KEYS.ACCOMMODATION,
      icon: 'king_bed',
      toolbarButtons: [
        {
          name: 'add_accommodation',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 90,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'roomType',
          headerName: 'Locations.RoomType',
          width: 250,
          editable: false,
          headerAlign: 'left',
          type: 'singleSelect',
          valueGetter: (params) => params?.row?.roomType?.value
        },
        {
          field: 'maxOccupancy',
          headerName: 'Locations.MaxOccupancy',
          width: 150,
          editable: false,
          headerAlign: 'left',
          type: 'number',
          align: 'left'
        },
        {
          field: 'description',
          headerName: 'Labels.Description',
          editable: false,
          width: 250,
          headerAlign: 'left'
        }
      ]
    },
    {
      label: 'Labels.Contingent',
      key: TAB_KEYS.CONTINGENT,
      icon: 'night_shelter',
      toolbarButtons: [
        {
          name: 'add',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 100,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'contingent',
          headerName: 'Labels.ContingentAmount',
          width: 150,
          editable: false,
          headerAlign: 'left',
          type: 'number',
          align: 'left'
        },
        {
          field: 'booked',
          headerName: 'Labels.Booked',
          width: 100,
          editable: false,
          headerAlign: 'left',
          filterable: false,
          sortable: false
        },
        {
          field: 'roomType',
          headerName: 'Hotel.RoomType',
          width: 150,
          editable: true,
          headerAlign: 'left',
          type: 'singleSelect',
          valueGetter: (params) => params?.row?.accommodation?.roomType?.value
        },
        {
          field: 'price',
          headerName: 'ResourcesTile.Price',
          width: 150,
          editable: false,
          defaultValue: '',
          headerAlign: 'left',
          type: 'number',
          align: 'left'
        },
        {
          field: 'usedIn',
          headerName: 'Labels.UsedIn',
          width: 200,
          editable: false,
          defaultValue: '',
          headerAlign: 'left',
          valueGetter: (params) => params?.row?.event?.name
        },
        {
          field: 'startDate',
          headerName: 'Events.StartDate',
          width: 150,
          editable: false,
          defaultValue: '',
          headerAlign: 'left',
          type: 'date'
        },
        {
          field: 'endDate',
          headerName: 'Events.EndDate',
          width: 150,
          editable: false,
          defaultValue: '',
          headerAlign: 'left',
          type: 'date'
        },
        {
          field: 'validUntil',
          headerName: 'Labels.ValidUntil',
          width: 150,
          editable: false,
          defaultValue: '',
          headerAlign: 'left',
          type: 'date',
          valueGetter: (params) => params?.row?.validUntil
        }
      ]
    },
    {
      label: 'IltSession.HotelBooking',
      key: TAB_KEYS.HOTEL_BOOKING,
      icon: 'hotel',
      toolbarButtons: [
        {
          name: 'add',
          disabled: true,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 100,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'participantName',
          headerName: 'IltSession.ParticipantName',
          width: 200,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'companyName',
          headerName: 'Labels.CompanyName',
          width: 200,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'bookedIn',
          headerName: 'Labels.BookedIn',
          editable: false,
          width: 200,
          headerAlign: 'left',
          valueGetter: (params) => params?.row?.hotelName
        },
        {
          field: 'startDate',
          headerName: 'IltSession.Arrival',
          editable: false,
          headerAlign: 'left',
          width: 200,
          type: 'date',
          valueGetter: (params) => params?.row?.arrival
        },
        {
          field: 'endDate',
          headerName: 'IltSession.Departure',
          editable: false,
          headerAlign: 'left',
          width: 200,
          type: 'date',
          valueGetter: (params) => params?.row?.departure
        },
        {
          field: 'roomType',
          headerName: 'Hotel.RoomType',
          editable: false,
          headerAlign: 'left',
          width: 200,
          type: 'singleSelect'
        },
        {
          field: 'contingent',
          headerName: 'Labels.Contingent',
          editable: false,
          headerAlign: 'left',
          width: 200,
          type: 'number',
          align: 'left'
        }
      ]
    },
    {
      label: 'Launchpad.Rooms',
      key: TAB_KEYS.MEETING_ROOM,
      icon: 'meeting_room',
      toolbarButtons: [
        {
          name: 'add_meeting_room',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: true,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 90,
          editable: false,
          headerAlign: 'left'
        },

        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'availableSeats',
          headerName: 'MeetingRooms.AvailableSeats',
          editable: false,
          width: 200,
          type: 'number',
          headerAlign: 'left',
          align: 'left'
        },
        {
          field: 'floor',
          headerName: 'MeetingRooms.Floor',
          editable: false,
          width: 200,
          headerAlign: 'left'
        },
        {
          field: 'phone',
          headerName: 'ContactsTile.Phone',
          editable: false,
          width: 200,
          headerAlign: 'left',
          valueGetter: (params) => params?.row?.phoneDtos[0]?.phone
        }
      ]
    },
    {
      label: 'Launchpad.Contacts',
      key: TAB_KEYS.CONTACT,
      icon: 'contacts',
      toolbarButtons: [
        {
          name: 'add_contact',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 110,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        // {
        //   field: 'company',
        //   headerName: 'Labels.Company',
        //   width: 200,
        //   editable: false,
        //   headerAlign: 'left'
        // },
        {
          field: 'department',
          headerName: 'ContactsTile.Department',
          editable: false,
          width: 250,
          headerAlign: 'left'
        },
        {
          field: 'function',
          headerName: 'ContactsTile.Function',
          editable: false,
          headerAlign: 'left',
          width: 300
        },
        {
          field: 'jobTitle',
          headerName: 'Labels.Job',
          editable: false,
          headerAlign: 'left',
          width: 200
        }
      ]
    },
    {
      label: 'Labels.Attachments',
      key: TAB_KEYS.ATTACHMENT,
      icon: 'attachments',
      toolbarButtons: [
        {
          name: 'add',
          disabled: false,
          icon: 'add',
          type: 'upload'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'size',
          headerName: 'Labels.Size',
          width: 250,
          editable: false,
          defaultValue: '0 KB',
          headerAlign: 'left',
          filterable: false
        },
        {
          field: 'createdBy',
          headerName: 'Labels.CreatedBy',
          width: 250,
          editable: false,
          headerAlign: 'left',
          valueGetter: (params) => params?.value?.name || 'Unknown User'
        }
      ]
    },
    {
      label: 'Labels.Notes',
      key: TAB_KEYS.NOTE,
      icon: 'note_alt',
      toolbarButtons: [
        {
          name: 'add_note',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'type',
          headerName: 'Labels.Type',
          width: 250,
          editable: false,
          headerAlign: 'left',
          type: 'singleSelect'
        },
        {
          field: 'text',
          headerName: 'Labels.Text',
          width: 250,
          editable: false,
          headerAlign: 'left',
          filterable: false,
          sortable: false
        }
      ]
    }
  ]
};

const venueTabs = {
  activeIndex: 0,
  tabs: [
    {
      label: 'Labels.Overview',
      key: TAB_KEYS.OVERVIEW,
      icon: 'info',
      isCountVisible: false
    },
    {
      label: 'Launchpad.Rooms',
      key: TAB_KEYS.MEETING_ROOM,
      icon: 'meeting_room',
      toolbarButtons: [
        {
          name: 'add_meeting_room',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: true,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 90,
          editable: false,
          headerAlign: 'left'
        },

        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'availableSeats',
          headerName: 'MeetingRooms.AvailableSeats',
          editable: false,
          width: 200,
          type: 'number',
          headerAlign: 'left',
          align: 'left'
        },
        {
          field: 'floor',
          headerName: 'MeetingRooms.Floor',
          editable: false,
          width: 200,
          headerAlign: 'left'
        },
        {
          field: 'phone',
          headerName: 'ContactsTile.Phone',
          editable: false,
          width: 200,
          headerAlign: 'left',
          valueGetter: (params) => params?.row?.phoneDtos[0]?.phone
        }
      ]
    },
    {
      label: 'Launchpad.Contacts',
      key: TAB_KEYS.CONTACT,
      icon: 'contacts',
      toolbarButtons: [
        {
          name: 'add_contact',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 100,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        // {
        //   field: 'company',
        //   headerName: 'Labels.Company',
        //   width: 200,
        //   editable: false,
        //   headerAlign: 'left'
        // },
        {
          field: 'department',
          headerName: 'ContactsTile.Department',
          editable: false,
          width: 250,
          headerAlign: 'left'
        },
        {
          field: 'function',
          headerName: 'ContactsTile.Function',
          editable: false,
          headerAlign: 'left',
          width: 350
        },
        {
          field: 'jobTitle',
          headerName: 'Labels.Job',
          editable: false,
          headerAlign: 'left',
          width: 300
        }
      ]
    },
    {
      label: 'Labels.Attachments',
      key: TAB_KEYS.ATTACHMENT,
      icon: 'attachments',
      toolbarButtons: [
        {
          name: 'add',
          disabled: false,
          icon: 'add',
          type: 'upload'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'size',
          headerName: 'Labels.Size',
          width: 250,
          editable: false,
          defaultValue: '0 KB',
          headerAlign: 'left',
          filterable: false
        },
        {
          field: 'createdBy',
          headerName: 'Labels.CreatedBy',
          width: 250,
          editable: false,
          headerAlign: 'left',
          valueGetter: (params) => params?.value?.name || 'Unknown User'
        }
      ]
    },
    {
      label: 'Labels.Notes',
      key: TAB_KEYS.NOTE,
      icon: 'note_alt',
      toolbarButtons: [
        {
          name: 'add_note',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'type',
          headerName: 'Labels.Type',
          width: 250,
          editable: false,
          headerAlign: 'left',
          type: 'singleSelect'
        },
        {
          field: 'text',
          headerName: 'Labels.Text',
          width: 250,
          editable: false,
          headerAlign: 'left',
          filterable: false,
          sortable: false
        }
      ]
    }
  ]
};

export default {
  activeIndex: 0,
  tabs: [
    {
      label: 'Labels.Overview',
      key: TAB_KEYS.OVERVIEW,
      icon: 'info',
      isCountVisible: false
    },
    {
      label: 'Launchpad.Contacts',
      key: TAB_KEYS.CONTACT,
      icon: 'contacts',
      toolbarButtons: [
        {
          name: 'add_contact',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 100,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        // {
        //   field: 'company',
        //   headerName: 'Labels.Company',
        //   width: 200,
        //   editable: false,
        //   headerAlign: 'left'
        // },
        {
          field: 'department',
          headerName: 'ContactsTile.Department',
          editable: false,
          width: 250,
          headerAlign: 'left'
        },
        {
          field: 'function',
          headerName: 'ContactsTile.Function',
          editable: false,
          headerAlign: 'left',
          width: 350
        },
        {
          field: 'jobTitle',
          headerName: 'Labels.Job',
          editable: false,
          headerAlign: 'left',
          width: 300
        }
      ]
    },
    {
      label: 'Labels.Attachments',
      key: TAB_KEYS.ATTACHMENT,
      icon: 'attachments',
      toolbarButtons: [
        {
          name: 'add',
          disabled: false,
          icon: 'add',
          type: 'upload'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'size',
          headerName: 'Labels.Size',
          width: 250,
          editable: false,
          defaultValue: '0 KB',
          headerAlign: 'left',
          filterable: false
        },
        {
          field: 'createdBy',
          headerName: 'Labels.CreatedBy',
          width: 250,
          editable: false,
          headerAlign: 'left',
          valueGetter: (params) => params?.value?.name || 'Unknown User'
        }
      ]
    },
    {
      label: 'Labels.Notes',
      key: TAB_KEYS.NOTE,
      icon: 'note_alt',
      toolbarButtons: [
        {
          name: 'add_note',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'type',
          headerName: 'Labels.Type',
          width: 250,
          editable: false,
          headerAlign: 'left',
          type: 'singleSelect'
        },
        {
          field: 'text',
          headerName: 'Labels.Text',
          width: 250,
          editable: false,
          headerAlign: 'left',
          filterable: false,
          sortable: false
        }
      ]
    }
  ]
};

export { hotelTabs, hotelAndVenueTabs, venueTabs };
