export const PARTICIPANTS_PATHS = {
  GET_MASTER_LIST: '/participants',
  GET_DETAILS: '/participants/:id',
  PUT: '/participants/:id',
  POST: '/participants',
  DELETE: '/participants/:id',
  PARTICIPANT_FORM: '/participants/dropdown', // get countries, languages & allergies,
  HOTELS_ACCOMMODATIONS: '/hotels/:id/accommodations',
  UPDATE_HOTELS_ACCOMMODATIONS: '/hotels/accommodations/:id',
  PARTICIPANTS_CANCELLATION_REASON: '/participants/cancellation-reasons',
  PARTICIPANTS_ALLERGIES: '/participants/allergies',
  PARTICIPANT_STATUSES: '/participants/statuses',
  PARTICIPANT_RESULTS: '/participants/participant-results'
};

export const DIALOG_PATHS = {
  HOTELS: '/dialogs/hotels',
  EVENTS: '/dialogs/events',
  VENUES: '/dialogs/venues',
  ACCOUNTS: '/dialogs/business-partners',
  PERSONS: '/dialogs/persons',
  INSTRUCTOR: '/instructors/dialog',
  MEETING_ROOMS: '/meeting-rooms/dialog',
  COURSES: '/courses',
  ILT_MODULE: '/ilt-modules/dialog',
  TASKS: '/activities/tasks'
};

export const EVENT_TEAM = {
  GET: '/event-team/ilt-session-team-roles'
};

export const HOTEL_PATHS = {
  GET_CONTINGENTS: '/hotels/:id/contingents',
  ROOM_TYPES: '/hotels/room-types',
  GET_CONTINGENT_BOOKINGS: '/hotels/:hotelId/contingent-bookings'
};

export const RESOURCES_PATHS = Object.freeze({
  GET_MASTER_LIST: '/resources',
  GET_DETAILS: '/resources/:id',
  GET: {
    DROPDOWNS: [
      '/resources/categories',
      '/resources/gl-accounts',
      '/locales/currencies',
      '/resources/gift-warehouses'
    ]
  },
  PUT: '/resources/:id',
  POST: '/resources',
  DELETE: '/resources/:id',
  RESOURCE_CATEGORIES: '/resources/categories',
  GL_ACCOUNTS: '/resources/gl-accounts',
  CURRENCIES: '/locales/currencies',
  GIFT_WAREHOUSES: '/resources/gift-warehouses'
});

export const CONTACTS_PATHS = {
  GET_MASTER_LIST: '/contacts',
  POST: '/contacts',
  GET_BY_ID: '/contacts/:id',
  PUT: '/contacts/:id',
  EMAIL_TYPES: '/contacts/email-types',
  CONTACTS_PHONE_TYPES: '/contacts/phone-types',
  DELETE: '/contacts/:id',
  GET_CONTACTS_TAB: '/business-partners/:id/contacts',
  GET_PARTICIPATIONS: '/persons/participations/:id'
};

export const INDIVIDUAL_CUSTOMERS_PATHS = Object.freeze({
  GET_BY_ID: '/individual-customers/:id'
});

export const DOCUMENTS_PATHS = Object.freeze({
  GET_DOCUMENT_TYPES: '/documents/document-types',
  TEMPLATE_PREVIEW: '/documents/document-types/:type/events/:id/preview',
  SEND_DOCUMENT: 'documents/document-types/:type/events/:id',
  SEND_DOCUMENT_WITH_FORCE: 'documents/document-types/:type/events/:id?force=true'
});

export const EMPLOYEE_PATHS = Object.freeze({
  GET_BY_ID: '/employees/:id'
});

export const SESSION_PATHS = Object.freeze({
  GET: '/sessions',
  GET_DETAILS: '/sessions/:id',
  TYPES: 'sessions/types',
  POST: '/sessions',
  PATCH: '/sessions/:id'
});

export const TAB_PATHS = Object.freeze({
  ATTACHMENTS: {
    GET: '/attachments/:entityId/:entityType',
    DELETE: '/attachments/:entityId/:entityType/:id',
    POST: '/attachments',
    DOWNLOAD: '/attachments/download/attachment',
    PATCH: '/attachments/:id'
  },
  NOTES: {
    GET: '/notes/:id/:entity',
    POST: '/notes/:entityType/:noteType/:entityId',
    DELETE: `/notes/:entityId/:entityType/:id`,
    PUT: `/notes/:id`,
    GET_TYPES: '/notes/types'
  },
  SESSIONS: {
    GET_SESSIONS: '/events/:eventId/sessions',
    GET_DETAILS: '/:entityType/:entityId/sessions',
    POST_PARTICIPANTS: '/participants/:entityId/sessions/:postData',
    DELETE_PARTICIPANTS: '/participants/:participantId/sessions/:sessionIds'
  }
});

export const MEETING_ROOMS_PATHS = Object.freeze({
  PUT: '/meeting-rooms/:id',
  POST: '/meeting-rooms',
  PATCH: '/meeting-rooms/archive/:id',
  GET_DETAILS: '/meeting-rooms/:id',
  DELETE: '/meeting-rooms/:id',
  GET: '/meeting-rooms'
});

export const LOCATION_PATHS = Object.freeze({
  GET_MEETING_ROOM: '/location/:id/meeting-rooms'
});

export const BUSINESS_PARTNERS_PATHS = Object.freeze({
  PUT: '/business-partners/:id',
  POST: '/business-partners',
  PATCH: '/business-partners/:id',
  GET_DETAILS: '/business-partners/:id',
  DELETE: '/business-partners/:id',
  GET: '/business-partners',
  BUSINESS_PARTNER_ROLE_TYPES: '/business-partners/roles',
  BUSINESS_PARTNER_TYPES: '/business-partners/types',
  SUPPLIER_TYPES: '/business-partners/supplier-types',
  ABC_CLASSIFICATION: '/business-partners/abc-classifications',
  CONTACTS_TAB: '/business-partners/:id/contacts',
  GET_PARTICIPATIONS: '/persons/participations/:id'
});

export const ORG_STRUCTURE_PATHS = Object.freeze({
  PUT: '/org-structure/:id',
  POST: '/org-structure',
  PATCH: '/org-structure/archive/:id',
  GET_DETAILS: '/org-structure/:id',
  DELETE: '/org-structure/:id',
  GET: '/org-structures/master'
});

export const EMPLOYEES_PATHS = Object.freeze({
  PUT: '/employees/:id',
  POST: '/employees',
  PATCH: '/employees/:id',
  GET_DETAILS: '/employees/:id',
  DELETE: '/employees/:id',
  GET: '/employees',
  GET_PARTICIPATIONS: '/persons/participations/:id'
});

export const ACTIVITIES_PATHS = Object.freeze({
  PUT: '/activities/:id',
  POST: '/activities',
  PATCH: '/activities/:id',
  GET_DETAILS: '/activities/:id',
  DELETE: '/activities/:id',
  GET: '/activities',
  ACTIVITIES_PRIORITY: '/activities/priorities',
  ACTIVITIES_STATUSES: '/activities/statuses',
  ACTIVITIES_TASK_CATEGORIES: '/activities/task-categories',
  ACTIVITIES_APPOINTMENTS_CATEGORIES: '/activities/appointment-categories',
  ACTIVITIES_TYPES: '/activities/types'
});

export const EVENTS_PATHS = Object.freeze({
  GET: '/events',
  GET_DETAILS: '/events/:id',
  EVENTS_TYPES: '/events/types',
  EVENTS_STATUSES: '/events/statuses',
  EVENTS_CONST_CENTERS: '/events/cost-centers',
  EVENTS_LANGUAGES: '/locales/native-languages',
  DELETE: '/events/:id',
  POST: '/events',
  PATCH: '/events/:id',
  GET_SESSIONS: '/events/:id/sessions',
  GET_PARTICIPANTS: '/events/:id/participants',
  GET_CONTINGENTS: '/events/:id/contingents',
  GET_PRICES: '/events/:id/prices',
  DELETE_PRICE: '/events/:id/prices/:priceIds',
  POST_PRICES: '/events/:id/prices',
  DELETE_CONTINGENT: '/events/:id/contingents/:contingentIds',
  POST_CONTINGENT: '/events/:id/contingents',
  PATCH_CONTINGENTS: '/events/:eventId/contingents/:contingentId',
  PATCH_PRICES: '/events/:eventId/prices/:id',
  GET_TASKS: '/events/:id/tasks',
  DELETE_TASK: '/events/:id/tasks/:taskIds',
  POST_TASK: '/events/:id/tasks',
  POST_TASKS: '/events/:eventId/tasks/:taskIds',
  PRICE_TYPES: '/events/price-types',
  GET_CONTINGENTS_HOTEL: '/events/contingents/:id'
});

export const COURSES_PATHS = Object.freeze({
  PUT: '/courses/:id',
  POST: '/courses',
  PATCH: '/courses/archive/:id',
  GET_DETAILS: '/courses/:id',
  DELETE: '/courses/:id',
  GET: '/courses/tree'
});

export const ILT_SESSION_PATHS = Object.freeze({
  PUT: '/iltSession/:id',
  POST: '/ilt-sessions',
  PATCH: '/ilt-sessions/:id',
  GET_DETAILS: '/ilt-sessions/:id',
  DELETE: '/ilt-sessions/:id',
  GET: '/ilt-sessions',
  GET_EXTERNALS: '/ilt-sessions/externals/:id',
  GET_SESSION_DAY: '/ilt-sessions/:id/session-days',
  GET_CONTINGENTS: '/ilt-sessions/:id/contingents',
  POST_CONTINGENT: '/ilt-sessions/:id/contingents',
  POST_PARTICIPANT: '/ilt-sessions/:id/participant',
  GET_PARTICIPANTS: '/ilt-sessions/:id/participants',
  PATCH_PARTICIPANT: '/ilt-sessions/:id/participants/:participantId',
  PATCH_PARTICIPANT_WAITING_LIST: '/ilt-sessions/:id/participants/:participantId/waitingList',
  DELETE_CONTINGENT: '/ilt-sessions/:id/contingents/:contingentIds',
  GET_CONTINGENTS_HOTEL: '/ilt-sessions/contingents/:id',
  GET_CONTINGENT_BOOKINGS: '/ilt-sessions/:sessionId/contingent-bookings/:contingentId',
  GET_PRICES: '/ilt-sessions/:id/prices',
  POST_PRICES: '/ilt-sessions/:id/prices',
  PATCH_PRICES: '/ilt-sessions/:iltSessionId/prices/:id',
  GET_TASKS: '/ilt-sessions/:id/tasks',
  POST_TASK: '/ilt-sessions/:id/tasks',
  DELETE_TASK: '/ilt-sessions/:id/tasks/:taskIds',
  UPDATE_CONTINGENT_BOOKINGS:
    '/ilt-sessions/:sessionId/contingent-bookings/:contingentId/:contingentBookingId',
  ADD_CONTINGENT_BOOKINGS: '/ilt-sessions/:sessionId/contingent-bookings',
  DELETE_CONTINGENT_BOOKINGS: '/ilt-sessions/:id/contingent-bookings/:contingentId/:contingentIds',
  GET_PARTICIPANTS_ON_CONTINGENT_BOOKINGS:
    '/ilt-sessions/:sessionId/contingent-bookings/:contingentId/participantList',
  DELETE_PRICE: '/ilt-sessions/:id/prices/:priceIds',
  PUT_CONTINGENTS: '/ilt-sessions/:iltSessionId/contingents/:id',
  EXPORT_PARTICIPANTS:
    '/ilt-sessions/:iltSessionId/participants/exports?fileType=:fileType&fieldsToExport=:fieldsToExport',
  EXPORT_SESSION_DAYS:
    '/ilt-sessions/:iltSessionId/session-days/exports?fileType=:fileType&fieldsToExport=:fieldsToExport',
  EXPORT_CONTINGENTS:
    '/ilt-sessions/:iltSessionId/contingents/exports?fileType=:fileType&fieldsToExport=:fieldsToExport',
  EXPORT_HOTEL_CONTINGENTS:
    '/ilt-sessions/:iltSessionId/contingents/:contingentId/contingent-bookings/exports?fileType=:fileType&fieldsToExport=:fieldsToExport',
  POST_TASKS: '/ilt-sessions/:iltSessionId/tasks/:taskIds',
  PRICE_TYPES: '/ilt-sessions/price-types-ilt',
  GET_WAITING_LISTS: '/ilt-sessions/:iltSessionId/participants/waitingList',
  POST_WAITING_LIST: '/ilt-sessions/:id/participant/waitingList',
  DELETE_PARTICIPANTS: '/ilt-sessions/:id/participants/:participantIds',
  PATCH_WAITING_LIST: '/ilt-sessions/:iltSessionId/participants/:participantId/waitingList',
  PATCH_BULK_WAITING_LIST:
    '/ilt-sessions/:iltSessionId/participants/:participantIds/bulkUpdateWaitingList?participantStatusId=:participantStatusId',
  GET_TEAMS: '/ilt-sessions/:iltSessionId/team-members'
});

export const ILT_SESSION_DAY_PATHS = Object.freeze({
  POST: '/session-days',
  GET_SESSION_DAY: '/session-days/:id',
  PATCH: '/session-days/:id',
  DELETE: '/session-days/:id'
});

export const SETTINGS_PATH = Object.freeze({
  GET: '/settings/:tile'
});

export const ADDRESS_PATH = Object.freeze({
  ADDRESS_TYPES: '/addresses/types',
  GET_REGION_BY_ID: '/addresses/countries/:id/regions'
});

export const LOCALES_PATHS = Object.freeze({
  COUNTRIES: '/locales/countries',
  CURRENCIES: '/locales/currencies'
});

export const PERSONS_PATHS = Object.freeze({
  GENDERS: '/persons/genders'
});

export const LOGIN_PATH = Object.freeze({
  POST: '/user/login',
  LOGIN: '/user/login',
  REFRESH_TOKEN: '/user/refresh-token',
  LOGOUT: '/saml-logout',
  SAML_LOGIN: '/saml2/authenticate/one',
  CHECK: '/auth',
  CREATE_TEMP_TOKEN: '/user/create-temp-token'
});

export const LAUNCHPAD_PATHS = Object.freeze({
  GET: '/launchpads'
});

export const EXPORTS_PATHS = Object.freeze({
  GET: '/exports/columns?className=:className'
});
