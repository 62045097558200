import React from 'react';

import { Box, Typography } from '@mui/material';

const DescriptionComponent = ({ label = '', noWrap = false }) => {
  return (
    <Box
      sx={{
        marginTop: '0.5rem',
        overflow: noWrap ? 'hidden' : 'visible',
        textOverflow: noWrap ? 'ellipsis' : 'unset'
      }}
    >
      <Typography noWrap={noWrap} component="label">
        {label}
      </Typography>
    </Box>
  );
};

export default DescriptionComponent;
