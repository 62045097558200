import React, { useMemo, useState } from 'react';
import { Outlet, ReactLocation, Router } from 'react-location';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import styled from '@mui/material/styles/styled';
import { makeStyles } from '@mui/styles';

import ConfirmDialog from '@components/ConfirmDialog';
import FilterDialog from '@components/FilterDialog';
import NavBar from '@components/NavBar';
import SideBar from '@components/SideBar';
import SnackbarComponent from '@components/Snackbar';
import { selectToken } from '@pages/Login/loginSlice';

import InitializeError from './components/InitializeError';
import privateRoutes from './routes/private';
import publicRoutes from './routes/public';

const PublicRoute = () => {
  return (
    <Box>
      <Outlet />
    </Box>
  );
};

const location = new ReactLocation();

export const useAppStyles = makeStyles(() => {
  return {
    blur: {
      pointerEvents: ({ isBlur }) => (isBlur ? 'none' : 'auto'),
      filter: ({ isBlur }) => (isBlur ? 'blur(1px)' : 'none')
    }
  };
});

const StyledContainer = styled('div')(() => ({
  position: 'relative'
}));

// TODO: Will be used later
// const StyledLoader = styled(Box, {
//   shouldForwardProp: (prop) => prop !== 'innerIsPending'
// })(({ theme, innerIsPending }) => ({
//   width: '100%',
//   top: theme.sizes.appHeader,
//   height: `calc(100vh - ${theme.sizes.appHeader})`,
//   position: 'absolute',
//   backgroundColor:
//     theme.palette.mode === 'light' ? theme.palette.common.white : theme.palette.grey[900],
//   zIndex: 999999,
//   display: 'flex',
//   flexDirection: 'column',
//   alignItems: 'center',
//   justifyContent: 'center',
//   visibility: innerIsPending ? 'visible' : 'hidden'
// }));

const PrivateRoute = () => {
  const [hideSideBar, setHideSideBar] = useState(false);

  return (
    <StyledContainer>
      <NavBar hideSideBar={hideSideBar} setHideSideBar={setHideSideBar} />
      <SideBar hideSideBar={hideSideBar} setHideSideBar={setHideSideBar} />
      <Outlet />
    </StyledContainer>
  );
};

const AppRouter = ({ isAuthEnabled = true }) => {
  const jwtToken = useSelector(selectToken);

  const isLoggedIn = useMemo(() => {
    // Authentication is enabled, check for jwt token
    if (isAuthEnabled) {
      return Boolean(jwtToken);
    }

    // Authentication is not enabled, enable private routes for development purposes.
    if (!isAuthEnabled) {
      // Remove auth key if preset, no need to have it when auth is disabled
      localStorage.removeItem('auth');
      return true;
    }
  }, [isAuthEnabled]);

  return (
    <>
      {isLoggedIn ? (
        <Router
          defaultErrorElement={<InitializeError />}
          location={location}
          routes={privateRoutes}
        >
          <PrivateRoute />
          <ConfirmDialog />
          <FilterDialog />
          <SnackbarComponent />
        </Router>
      ) : (
        <Router defaultErrorElement={<InitializeError />} location={location} routes={publicRoutes}>
          <PublicRoute />
          <SnackbarComponent />
        </Router>
      )}
    </>
  );
};
export default AppRouter;
