import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useSearch, useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import { omit } from 'lodash';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { ACTION_MODES, SORTING_ORDER } from '@common/Constants';
import getAdvancedFiltersForMUITable from '@common/helpers/tables/getAdvancedFiltersForMUITable';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import ToolbarButton from '@components/ToolbarButton';
import { getFilterOperatorsBasedOnType } from '@config/filterOperators';
import { TABLE_OPTIONS } from '@config/network';

import CreateTeam from './CreateTeam/CreateTeam';
import {
  selectList,
  fetchTeams,
  selectFilter,
  selectIsLoading,
  resetState,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  selectSelectionModel,
  setSelectionModel,
  deleteTeams
} from './teamTableSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  noNightsBooked: { color: theme.palette.error.main },
  someNightsBooked: { color: theme.palette.warning.main },
  allNightsBooked: { color: theme.palette.success.light }
}));

const statusColor = (nightsBookedData, classes) => {
  if (!nightsBookedData) {
    return;
  }

  const nightsBooked = nightsBookedData.slice(0, nightsBookedData.indexOf('/'));
  const nightsTotal = nightsBookedData.slice(nightsBookedData.indexOf('/') + 1);

  return nightsBooked === '0'
    ? classes.noNightsBooked
    : nightsBooked !== nightsTotal
    ? classes.someNightsBooked
    : classes.allNightsBooked;
};

const TeamTable = ({ columns = [], entityId = null, entityType = null, toolbarButtons = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const search = useSearch();
  const navigate = useNavigate();

  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(fetchTeams({ entityId: entityId, filter: filter }));
    }
  }, [
    entityId,
    filter?.page,
    filter?.sortBy,
    filter?.sortDirection,
    filter?.size,
    filter?.filters
  ]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarButtonDisabled = (name) => {
    switch (name) {
      case 'add_participant':
        return !entityId || !entityType;

      case 'delete':
        return selectionModel.length === 0;

      default:
        return false;
    }
  };

  const onToolbarButtonClick = (name) => {
    switch (name) {
      case 'add_participant':
        navigate({
          search: (previousUrlParams) => ({ ...previousUrlParams, mode: ACTION_MODES.Create })
        });
        break;

      case 'delete':
        {
          dispatch(
            openConfirmDialog({
              title: Localize.get('ConfirmationMessages.Delete', {
                item: Localize.get('Events.SingularOrPluralTeam', {
                  selectionModel: selectionModel.length
                })
              }),
              confirmButton: Localize.get('Buttons.Delete'),
              cancelButton: Localize.get('Buttons.Cancel')
            })
          )
            .unwrap()
            .then((result) => {
              if (result === CONFIRM_ACTIONS.Confirm) {
                dispatch(deleteTeams({ participantIds: selectionModel, id: entityId }));
              }
            });
        }
        break;

      default:
        break;
    }
  };

  const onFilterChange = (values) => {
    const advancedFilters = getAdvancedFiltersForMUITable(values, () =>
      dispatch(fetchTeams({ entityId: entityId, filter: { ...omit(filter, 'filters') } }))
    );

    dispatch(setFilterParams({ key: 'filters', value: { advancedFilters } }));
  };

  const onSortChange = (sortModel) =>
    dispatch(
      setFilterParams([
        { key: 'sortBy', value: sortModel[0].field },
        { key: 'sortDirection', value: sortModel[0].sort }
      ])
    );

  return (
    <div className={classes.root}>
      {search?.mode === ACTION_MODES.Create ? (
        <CreateTeam entityId={entityId} />
      ) : (
        <TabContent>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('IltSession.Teams')}
            </Typography>
            <Toolbar className={classes.toolbar}>
              {toolbarButtons?.map((button, index) => (
                <ToolbarButton
                  key={index}
                  button={button}
                  isDisabled={button.disabled || isToolbarButtonDisabled(button.name)}
                  onToolbarButtonClick={onToolbarButtonClick}
                />
              ))}
            </Toolbar>
          </div>
          <TabContainer>
            <StyledDataGrid
              keepNonExistentRowsSelected={true}
              loading={isLoading}
              rows={data}
              columns={columns.map((column) => ({
                ...column,
                headerName: Localize.get(column.headerName),
                filterOperators: getFilterOperatorsBasedOnType(column?.type),
                renderCell: (params) => (
                  <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                    {['nightsBooked'].includes(params.field) ? (
                      <Typography
                        className={statusColor(params?.value, classes)}
                        variant="body"
                        component="div"
                      >
                        {params.value}
                      </Typography>
                    ) : [
                        'name',
                        'company',
                        'hotel',
                        'email',
                        'teamRole',
                        'companyName',
                        'hotel'
                      ].includes(params.field) ? (
                      <Tooltip title={params.value} arrow>
                        <Typography
                          noWrap
                          variant="body"
                          component="div"
                          sx={{
                            maxWidth: params?.colDef?.width - 10 // 10 px for three dots,
                          }}
                        >
                          {params.value}
                        </Typography>
                      </Tooltip>
                    ) : ['sessionRoleResponse'].includes(params.field) ? (
                      <Tooltip
                        title={Object.values(params?.row?.sessionRoleResponse)
                          .map((role) => role?.value)
                          .map((value, index) => (
                            <li key={index}>{value}</li>
                          ))}
                        arrow
                      >
                        <Typography
                          noWrap
                          variant="body"
                          component="div"
                          sx={{
                            maxWidth: params?.colDef?.width - 10 // 10 px for three dots,
                          }}
                        >
                          {params.value}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography variant="body" component="div">
                        {params.value}
                      </Typography>
                    )}
                  </div>
                )
              }))}
              initialState={{
                pagination: {
                  pageSize: totalPages,
                  rowCount: totalElements,
                  page: filter?.page
                }
              }}
              onSelectionModelChange={(newSelectionModel) => {
                dispatch(setSelectionModel(newSelectionModel));
              }}
              selectionModel={selectionModel}
              checkboxSelection
              localeText={getLocalesText(Localize)}
              pagination
              paginationMode="server"
              disableColumnMenu
              page={filter?.page}
              pageSize={filter?.size}
              rowCount={totalElements}
              rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
              disableSelectionOnClick
              components={{ Toolbar: () => <TableToolbar /> }}
              onPageChange={(page) => dispatch(setFilterParams({ page, selectionModel }))}
              onPageSizeChange={(value) =>
                dispatch(setFilterParams([{ key: 'size', value, selectionModel }]))
              }
              sortingOrder={SORTING_ORDER}
              onSortModelChange={onSortChange}
              onFilterModelChange={onFilterChange}
            />
          </TabContainer>
        </TabContent>
      )}
    </div>
  );
};

export default TeamTable;
