import React, { useCallback, useEffect, useState, useRef } from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import { isObject } from 'lodash';
import moment from 'moment';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { dateInitFormats } from '@common/Constants';
import { enumerateDaysBetweenDates } from '@common/helpers/dates';
import percentage from '@common/helpers/numbers/percentage';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import {
  FILTER_ACTIONS,
  openFilterDialog,
  resetFilterState,
  scrubFiltersForBE,
  selectIsActive
} from '@components/FilterDialog/filtersSlice';
import Header from '@components/Header/Header';
import LayoutContainer, {
  LeftContainer,
  RightContainer
} from '@components/LayoutContainer/LayoutContainer';
import MasterList from '@components/MasterList/MasterList';
import MasterListFooter from '@components/MasterListFooter/MasterListFooter';
import MasterListTitle from '@components/MasterListTitle/MasterListTitle';
import MasterListToolbar from '@components/MasterListToolbar/MasterListToolbar';
import SortDialog from '@components/SortDialog/SortDialog';
import { SEARCH_INPUT_DELAY } from '@config/inputs';
import useDebounce from '@hooks/handlers/useDebounce';
import { setOptions, localeDe, localeEn } from '@mobiscroll/react';
import bookingFilters from '@pages/IltSession/util/fields/bookingFilters';
import { BOOKING_SORT_DATA } from '@pages/IltSession/util/sortConfig';
import { selectLanguage } from '@pages/Users/usersSlice';

import {
  addContingentBooking,
  fetchBookings,
  fetchParticipants,
  initialState,
  resetState,
  selectFilter,
  selectIsEdit,
  selectIsParticipantListLoading,
  selectListOfParticipants,
  selectTotalElements,
  selectTotalPages,
  selectViewSettings,
  setFilterParams,
  setIsEdit,
  setIsParticipantsLoading,
  selectContingentBookings,
  deleteContingentBookings,
  revertContingentBookingsUpdate,
  revertBookingCreate,
  selectResources,
  updateContingentBooking
} from '../bookingSlice';
import BookingHeader from './Header/Header';
import Participant from './Participant/Participant';
import BookingPopup from './Popup/Popup';
import { StyledEvent, StyledEventCalendar, StyledEventStatus, StyledEventTitle } from './styled';

const localeMap = {
  en: localeEn,
  de: localeDe
};

const isDouble = (event, inst) => {
  const events = inst.getEvents();

  const doubleEvent = events.find((existing) => {
    return (
      moment(existing.start).format(moment.HTML5_FMT.DATE) ===
        moment(event.start).format(moment.HTML5_FMT.DATE) &&
      existing.resource === event.resource &&
      existing.id !== event.id
    );
  });

  return doubleEvent;
};

const BookingCalendar = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const {
    params: { sessionId, id },
    data: {
      hotel: { data: hotel }
    }
  } = useMatch();

  const lang = useSelector(selectLanguage);

  const [myColors, setColors] = useState([]);
  const [invalidSlots, setInvalidSlots] = useState([]);
  const [hoverEvent, setHoverEvent] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const [anchor, setAnchor] = useState(null);
  const timerRef = useRef(null);
  const [isOpenDialogSort, setIsOpenDialogSort] = useState(false);

  const isEdit = useSelector(selectIsEdit);
  const viewSettings = useSelector(selectViewSettings);
  const listOfParticipants = useSelector(selectListOfParticipants);
  const isParticipantListLoading = useSelector(selectIsParticipantListLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const filter = useSelector(selectFilter);
  const contingentBookings = useSelector(selectContingentBookings);
  const resources = useSelector(selectResources);
  const isFilterActive = useSelector(selectIsActive);

  const [selectedEvents, setSelectedEvents] = useState([]);

  // Get initial participant list, fetch current bookings and configure the UI
  useEffect(() => {
    dispatch(fetchParticipants({ filter, sessionId: sessionId, contingentId: id }));
    dispatch(fetchBookings({ sessionId: sessionId, contingentId: id, hotel }));
  }, []);

  // Get participant list on filter change
  useEffect(() => {
    dispatch(fetchParticipants({ filter, sessionId: sessionId, contingentId: id }));
  }, [filter.sortBy, filter.sortDirection]);

  // Get participant on search change
  useDebounce(
    () => dispatch(fetchParticipants({ filter, sessionId: sessionId, contingentId: id })),
    SEARCH_INPUT_DELAY,
    [filter.search]
  );

  // Change the theme of the booking library
  useEffect(
    () => setOptions({ themeVariant: theme.palette.mode, theme: 'ios' }),
    [theme.palette.mode]
  );

  // Reset the state on component destroy
  useEffect(() => {
    return () => {
      dispatch(resetState());
      dispatch(resetFilterState());
    };
  }, []);

  const onFilterClick = useCallback(
    () =>
      dispatch(
        openFilterDialog({
          title: `${Localize.get('ParticipantsTile.Item')} ${Localize.get('Labels.Filter')}`,
          config: bookingFilters.map((filter) => ({
            ...filter,
            label: Localize.get(filter.label),
            operator: { label: Localize.get(filter.operator.label), key: filter.operator.key },
            options: filter.options?.map((option) =>
              isObject(option)
                ? { label: Localize.get(option?.label), key: option.key }
                : Localize.get(option)
            )
          }))
        })
      ).then(({ payload }) => {
        if (payload.action === FILTER_ACTIONS.Cancel) {
          return;
        }

        if (payload.action === FILTER_ACTIONS.Reset) {
          dispatch(fetchParticipants({ filter, sessionId: sessionId, contingentId: id }));
          return;
        }

        dispatch(
          fetchParticipants({
            filter: {
              filters: { advancedFilters: scrubFiltersForBE(payload.filters, false) },
              ...filter
            },
            sessionId: sessionId,
            contingentId: id
          })
        );
      }),
    []
  );

  const onSelectedEventsChange = useCallback(
    (args) => setSelectedEvents(args.events),
    [setSelectedEvents]
  );

  const renderMasterListItem = useCallback(
    (item) => <Participant isEdit={isEdit} key={item.id} data={item} />,
    [isEdit]
  );

  const onEventDragStart = useCallback(
    (e) => {
      const {
        event: { arrivalDate, departureDate, nightsBooked, nightsToBeBooked }
      } = e;
      const { fromTo = {} } = viewSettings;

      // If status is Completed (participant already finished booking) don't allow drag&drop
      if (nightsBooked === nightsToBeBooked) {
        setInvalidSlots([
          {
            recurring: {
              repeat: 'daily'
            }
          },
          {
            start: fromTo?.arrivalDate,
            end: fromTo?.departureDate
          }
        ]);
        setColors([
          {
            background: theme.palette.grey[100]
          },
          {
            start: fromTo?.arrivalDate,
            end: fromTo?.departureDate
          }
        ]);
        return;
      }

      const invalidArrivalDates = enumerateDaysBetweenDates(fromTo.arrivalDate, arrivalDate) || [];
      const invalidDepartureDates =
        enumerateDaysBetweenDates(departureDate, fromTo.departureDate) || [];

      // Set invalid slots for Participant, e.g. Participant wants to participate in 2023-09-05 until 2023-09-10
      // disable all dates for this booking before and after those dates, e.g. Booking starts 2023-09-01 and ends 2023-09-30
      // disabled dates should be ['2023-09-01','2023-09-02', '2023-09-03','2023-09-04','2023-09-11','2023-09-12', ...]
      setInvalidSlots([...invalidArrivalDates, ...invalidDepartureDates]);
      setColors([
        {
          background: theme.palette.grey[300]
        },
        {
          start: invalidArrivalDates[0],
          end: invalidArrivalDates[invalidArrivalDates.length - 1]
        },
        {
          background: theme.palette.grey[300]
        },
        {
          start: invalidDepartureDates[0],
          end: invalidDepartureDates[invalidDepartureDates.length - 1]
        }
      ]);
    },
    [viewSettings]
  );

  const onEventDragEnd = useCallback(() => {
    setColors([]);
    setInvalidSlots([]);
  }, []);

  const onEventCreated = useCallback(({ event }, inst) => {
    if (isDouble(event, inst)) {
      dispatch(revertBookingCreate(event));
    } else {
      if (event.resource && Number.isInteger(event.id)) {
        const data = [
          {
            contingentId: parseInt(id, 10),
            eventParticipantId: parseInt(event.id, 10),
            bookedNightDate: moment(event.start).format(dateInitFormats.basicDate),
            roomPosition: parseInt(event.resource.split('.')[0], 10),
            bedPosition: parseInt(event.resource.split('.')[1], 10)
          }
        ];

        dispatch(
          addContingentBooking({ sessionId: sessionId, contingentId: id, data, event })
        ).then(() => {
          setTimeout(() => {
            setHoverEvent(null);
            setOpen(false);
          }, 100);
        });
      }
    }
  }, []);

  const onEventUpdated = useCallback(({ event, oldEvent }, inst) => {
    if (isDouble(event, inst)) {
      dispatch(revertContingentBookingsUpdate(oldEvent));
    } else {
      const data = { sessionId: sessionId, contingentId: id, contingentBookingId: event.id, event };
      dispatch(updateContingentBooking(data)).then(() => setColors([]));
    }
    setColors([]);
  }, []);

  const onEventDelete = useCallback(() => {
    dispatch(
      openConfirmDialog({
        title: Localize.get('ConfirmationMessages.Delete', {
          item:
            selectedEvents?.length === 1
              ? Localize.get('ParticipantsTile.Item')?.toLowerCase()
              : Localize.get('Launchpad.Participants')?.toLowerCase()
        }),
        confirmButton: Localize.get('Buttons.Delete'),
        cancelButton: Localize.get('Buttons.Cancel')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Cancel) {
          return;
        }

        dispatch(
          deleteContingentBookings({
            sessionId: sessionId,
            contingentId: id,
            contingentIds: selectedEvents.map((e) => e.id),
            selectedEvents: selectedEvents
          })
        ).then(() => setSelectedEvents([]));
      });
  }, [contingentBookings, selectedEvents]);

  const onMouseEnter = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
  }, []);

  const onMouseLeave = useCallback(() => {
    timerRef.current = setTimeout(() => {
      setOpen(false);
    }, 200);
  }, []);

  const onEventHoverIn = useCallback((args) => {
    const event = args.event;
    setHoverEvent(event);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }

    setAnchor(args.domEvent.target);
    setOpen(true);
  }, []);

  const onEventHoverOut = useCallback(() => {
    timerRef.current = setTimeout(() => {
      setOpen(false);
      setHoverEvent(null);
    }, 200);
  }, []);

  const renderCustomHeader = useCallback(
    () => (
      <BookingHeader
        data={viewSettings?.header}
        isEdit={isEdit}
        setIsEdit={(isEdit) => dispatch(setIsEdit(isEdit))}
        onEventDelete={onEventDelete}
        selectedEvents={selectedEvents}
        contingentBookings={contingentBookings}
      />
    ),
    [selectedEvents, onEventDelete, viewSettings, isEdit]
  );

  const renderCustomEvent = useCallback(
    ({ original, style }) => {
      const isSelected = selectedEvents.find((e) => e.id === original.id);
      const bookingPercentage = percentage(original.nightsBooked, original.nightsToBeBooked);
      const color =
        bookingPercentage === 100 ? theme.palette.success.main : theme.palette.warning.main;

      return (
        <StyledEvent
          display="flex"
          flexDirection="column"
          color={color}
          backgroundColor={style.background}
          isSelected={isSelected}
          count={viewSettings.timeline.size}
        >
          <StyledEventStatus>
            {[
              Localize.get('Labels.Status'),
              `${original.nightsBooked}/${original.nightsToBeBooked}`
            ].join(' ')}
          </StyledEventStatus>
          <StyledEventTitle count={viewSettings.timeline.size}>{original.title}</StyledEventTitle>
        </StyledEvent>
      );
    },
    [viewSettings?.timeline?.size, selectedEvents]
  );

  const renderCustomResource = (resource) => {
    if (!resource) {
      return null;
    }

    // Resource id is in 1.5 format, 1 is the main resource - number of the room,
    // and 5 is number of the bed, so 1.5 is the id of the 5th bed in the 1st room.
    const className = parseInt(resource.id.split('.')[1], 10) === 1 ? 'mainResource' : '';

    return (
      <Box sx={{ color: resource.color }} className={className}>
        {resource.name}
      </Box>
    );
  };

  const getSelectedDate = () => {
    const { selectedDate, refDate } = viewSettings;

    if (moment(selectedDate).diff(moment(refDate)) === 0) {
      return moment(viewSettings.selectedDate).add(-1, 'd').toDate();
    }

    return moment(viewSettings.selectedDate).toDate();
  };

  return (
    <LayoutContainer>
      <LeftContainer>
        <Header>
          <MasterListTitle>{`${Localize.get(
            'IltSession.ParticipantsAndTeamMember'
          )} (${totalElements})`}</MasterListTitle>
        </Header>
        <MasterListToolbar
          isFilterActive={isFilterActive}
          onFilterClick={onFilterClick}
          onSortClick={() => setIsOpenDialogSort(true)}
          isSortActive={
            filter.sortBy !== initialState.filter.sortBy ||
            filter.sortDirection !== initialState.filter.sortDirection
          }
          searchProps={{
            value: filter.search,
            onSearchChange: (e) => {
              dispatch(setIsParticipantsLoading(true));
              dispatch(setFilterParams({ key: 'search', value: e.target.value }));
            }
          }}
        />

        <MasterList
          isLoading={isParticipantListLoading}
          data={listOfParticipants}
          renderMasterItem={renderMasterListItem}
        />
        <MasterListFooter
          onPageChange={(e, page) =>
            dispatch(
              fetchParticipants({
                filter,
                page: page - 1,
                sessionId: sessionId,
                contingentId: id
              })
            )
          }
          pagination={{ totalPages, page: filter.page + 1 }}
          isAddVisible={false}
        />

        <SortDialog
          onCancel={() => setIsOpenDialogSort(false)}
          open={isOpenDialogSort}
          sortState={filter}
          fields={BOOKING_SORT_DATA}
          initialState={initialState.filter}
          sortHandler={({ sortBy, sortDirection }) => {
            dispatch(
              setFilterParams([
                { key: 'sortBy', value: sortBy },
                { key: 'sortDirection', value: sortDirection }
              ])
            );
          }}
        />
      </LeftContainer>
      <RightContainer>
        <StyledEventCalendar
          locale={localeMap[lang]}
          data={JSON.parse(JSON.stringify(contingentBookings))}
          view={{ timeline: viewSettings.timeline }}
          selectedDate={getSelectedDate()} // from
          refDate={viewSettings.refDate} // to
          resources={JSON.parse(JSON.stringify(resources))}
          renderResource={renderCustomResource}
          renderScheduleEvent={renderCustomEvent}
          renderHeader={renderCustomHeader}
          colors={myColors}
          invalid={invalidSlots}
          dragToMove
          externalDrop
          rowHeight={'equal'}
          showControls
          selectedEvents={selectedEvents}
          onSelectedEventsChange={onSelectedEventsChange}
          selectMultipleEvents
          onEventDragStart={onEventDragStart}
          onEventDragEnd={onEventDragEnd}
          onEventCreated={onEventCreated}
          showEventTooltip={false}
          onEventHoverIn={onEventHoverIn}
          onEventHoverOut={onEventHoverOut}
          onEventUpdated={onEventUpdated}
        />

        <BookingPopup
          isOpen={isOpen}
          anchor={anchor}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          hoverEvent={hoverEvent}
          information={viewSettings.header}
        />
      </RightContainer>
    </LayoutContainer>
  );
};

export default BookingCalendar;
