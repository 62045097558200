import React, { useEffect, useMemo } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import { omit } from 'lodash';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';

import { ACTION_MODES, TAB_KEYS } from '@common/Constants';
import { errorMessageFormatter } from '@common/helpers/MessageFormatter';
import CreateFooter from '@components/CreateFooter';
import CreateForm from '@components/CreateForm';
import Header from '@components/Header';
import InlineCreateHeaderContainer from '@components/InlineCreateHeaderContainer/InlineCreateHeaderContainer';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { eventsPath, participantsPath } from '@navigation/routes/Routes';
import {
  selectCreatedBusinessPartner,
  setCreatedBusinessPartner
} from '@pages/BusinessPartners/businessPartnersSlice';
import { createParticipant, selectOccupancy } from '@pages/Participants/participantSlice';
import initialValues from '@pages/Participants/utils/initialValues';
import participantSchema from '@pages/Participants/utils/schemas/participantSchema';

import getFieldsConfig, { mapPropsToAddress } from './getFieldsConfig';

const CreateParticipant = ({ event = null }) => {
  const {
    data: {
      participantData: {
        data: { allergy, language }
      },
      participantStatuses: { data: statuses },
      phoneTypes: { data: phoneTypes },
      emailTypes: { data: emailTypes },
      addressTypes: { data: addressTypes }
    }
  } = useMatch();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isNavigatedFromParticipantTabInEvents = Boolean(event?.id);

  const occupancy = useSelector(selectOccupancy);
  const createdBusinessPartner = useSelector(selectCreatedBusinessPartner);

  useEffect(() => window.scrollTo(0, 0), []);

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(createParticipant({ data: values, isNavigatedFromParticipantTabInEvents }))
      .unwrap()
      .then(() => {
        dispatch(setCreatedBusinessPartner(null));
        navigate({
          to: isNavigatedFromParticipantTabInEvents ? eventsPath : participantsPath,
          search: (previousUrlParams) => ({
            ...omit(previousUrlParams, 'mode', 'eventId'),
            activeTab: isNavigatedFromParticipantTabInEvents
              ? TAB_KEYS.PARTICIPANTS
              : TAB_KEYS.OVERVIEW
          }),
          replace: false
        });
      })
      .catch(({ data = {} }) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(data, 'PARTICIPANT', ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
      })
      .finally(() => setSubmitting(false));
  };

  const onCancel = () => {
    dispatch(setCreatedBusinessPartner(null));
    if (isNavigatedFromParticipantTabInEvents) {
      navigate({
        to: eventsPath,
        search: (previousUrlParams) => ({
          ...omit(previousUrlParams, 'eventId', 'mode'),
          activeTab: TAB_KEYS.PARTICIPANTS
        }),
        replace: false
      });
    } else {
      navigate({
        to: participantsPath,
        search: (previousUrlParams) => ({
          ...omit(previousUrlParams, 'eventId'),
          activeTab: TAB_KEYS.OVERVIEW
        }),
        replace: false
      });
    }
  };

  const memoizedInitialValues = useMemo(() => {
    return {
      ...initialValues,
      company: {
        id: createdBusinessPartner?.organizationPerson?.id || '',
        name: createdBusinessPartner?.organizationPerson?.name
      },
      event: isNavigatedFromParticipantTabInEvents
        ? { id: event?.id, name: event?.name }
        : initialValues.event
    };
  }, [createdBusinessPartner, isNavigatedFromParticipantTabInEvents, event]);

  return (
    <>
      {!isNavigatedFromParticipantTabInEvents && (
        <Header>{Localize.get('Participants.Create')}</Header>
      )}
      <Formik
        enableReinitialize
        validateOnMount
        initialValues={memoizedInitialValues}
        onSubmit={onSubmit}
        validationSchema={participantSchema}
      >
        {({ isSubmitting, values, setFieldValue, handleSubmit, isValid, setValues }) => (
          <>
            {!isNavigatedFromParticipantTabInEvents ? (
              <>
                <CreateForm
                  values={values}
                  mapPropsToAddress={mapPropsToAddress}
                  fieldsConfig={getFieldsConfig(
                    values,
                    setFieldValue,
                    setValues,
                    isNavigatedFromParticipantTabInEvents,
                    allergy,
                    language,
                    occupancy,
                    phoneTypes,
                    emailTypes,
                    addressTypes,
                    statuses,
                    dispatch
                  )}
                />

                <CreateFooter>
                  <Button
                    data-test-id="cancel-btn"
                    variant="outlined"
                    onClick={onCancel}
                    color="error"
                    sx={{ mr: 1 }}
                    startIcon={<CancelIcon />}
                  >
                    {Localize.get('Buttons.Cancel')}
                  </Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button
                    data-test-id="save-btn"
                    variant="outlined"
                    disabled={isSubmitting || !isValid}
                    onClick={handleSubmit}
                    startIcon={<SaveIcon />}
                  >
                    {Localize.get('Buttons.Save')}
                  </Button>
                </CreateFooter>
              </>
            ) : (
              <>
                <CreateForm
                  values={values}
                  mapPropsToAddress={mapPropsToAddress}
                  fieldsConfig={getFieldsConfig(
                    values,
                    setFieldValue,
                    setValues,
                    isNavigatedFromParticipantTabInEvents,
                    allergy,
                    language,
                    occupancy,
                    phoneTypes,
                    emailTypes,
                    addressTypes,
                    statuses,
                    dispatch
                  )}
                />
                <InlineCreateHeaderContainer>
                  <Button
                    data-test-id="cancel-btn"
                    variant="outlined"
                    onClick={onCancel}
                    color="error"
                    sx={{ mr: 1 }}
                    startIcon={<CancelIcon />}
                  >
                    {Localize.get('Buttons.Cancel')}
                  </Button>
                  <Button
                    data-test-id="save-btn"
                    variant="outlined"
                    disabled={isSubmitting || !isValid}
                    onClick={handleSubmit}
                    startIcon={<SaveIcon />}
                  >
                    {Localize.get('Buttons.Save')}
                  </Button>
                </InlineCreateHeaderContainer>
              </>
            )}
          </>
        )}
      </Formik>
    </>
  );
};

export default CreateParticipant;
