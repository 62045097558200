import React, { useCallback, useEffect, useRef } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import { get } from 'lodash';

import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';

import { SORTING_ORDER, TAB_KEYS } from '@common/Constants';
import { onTabsTableFilterChange } from '@common/helpers/tables/getAdvancedFiltersForMUITable';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarButton from '@components/ToolbarButton';
import {
  getFilterOperatorsBasedOnType,
  getFilterValueOptionsBasedOnType
} from '@config/filterOperators';
import { TABLE_OPTIONS } from '@config/network';
import { eventsDetailPath, iltSessionsDetailPath } from '@navigation/routes/Routes';
import {
  selectList,
  fetchHotelBookings,
  selectFilter,
  selectIsLoading,
  resetState,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  selectSelectionModel,
  setSelectionModel
} from '@pages/Locations/components/Tabs/HotelBooking/hotelBookingTableSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  },
  cellCheckbox: {
    padding: '5px 40px 5px 5px !important'
  }
}));

const HotelBookingTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarButtons = [],
  details = null
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: {
      roomTypes: { data: roomTypes }
    }
  } = useMatch();
  const trackFilterModel = useRef(null);

  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);
  const { roles } = details;
  const hotelId = roles[0]?.hotel?.id;

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(fetchHotelBookings({ entityId: hotelId, filter: filter }));
    }
  }, [
    entityId,
    hotelId,
    filter?.page,
    filter?.sortBy,
    filter?.sortDirection,
    filter?.size,
    filter?.filters
  ]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarButtonDisabled = (name) => {
    switch (name) {
      case 'add_participant':
        return !entityId || !entityType;
      case 'delete':
        return true; // selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onToolbarButtonClick = (name) => {
    switch (name) {
      case 'add_participant':
        return !entityId || !entityType;
      case 'delete':
        return true;
      default:
        return false;
    }
  };

  const onFilterChange = (values) => {
    const advancedFilters = onTabsTableFilterChange(values, trackFilterModel, () =>
      dispatch(setFilterParams({ key: 'filters', value: null }))
    );
    if (advancedFilters) dispatch(setFilterParams({ key: 'filters', value: { advancedFilters } }));
  };

  const onSortChange = (sortModel) =>
    dispatch(
      setFilterParams([
        { key: 'sortBy', value: sortModel[0]?.field },
        { key: 'sortDirection', value: sortModel[0]?.sort }
      ])
    );

  const getSingleSelectValueOptions = useCallback((field) => {
    switch (field) {
      case 'roomType': {
        return roomTypes.map(({ value }) => value);
      }
      default:
        return [];
    }
  }, []);

  return (
    <>
      <div className={classes.root}>
        <TabContent>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('IltSession.HotelBooking')}
            </Typography>
            <Toolbar className={classes.toolbar}>
              {toolbarButtons?.map((button, index) => (
                <ToolbarButton
                  key={index}
                  button={button}
                  isDisabled={button.disabled || isToolbarButtonDisabled(button.name)}
                  onToolbarButtonClick={onToolbarButtonClick}
                />
              ))}
            </Toolbar>
          </div>
          <TabContainer>
            <DataGrid
              keepNonExistentRowsSelected={true}
              loading={isLoading}
              rows={data}
              columns={[
                ...columns.map((column) => ({
                  ...column,
                  headerName: Localize.get(column.headerName),
                  filterOperators: getFilterOperatorsBasedOnType(column?.type),
                  valueOptions: getFilterValueOptionsBasedOnType(
                    column,
                    getSingleSelectValueOptions
                  ),
                  renderCell: (params) => {
                    return (
                      <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                        {['participantName', 'companyName', 'roomType'].includes(params.field) ? (
                          <Tooltip title={params.value} arrow>
                            <Typography
                              variant="body"
                              component="div"
                              noWrap
                              sx={{
                                width: 190
                              }}
                            >
                              {params.value}
                            </Typography>
                          </Tooltip>
                        ) : ['bookedIn'].includes(params.field) ? (
                          <Tooltip title={params.value} arrow>
                            <Typography
                              noWrap
                              variant="body"
                              component="div"
                              sx={{
                                maxWidth: params?.colDef?.width - 10 // 10 px for three dots,
                              }}
                            >
                              <Link
                                sx={{ cursor: 'pointer' }}
                                onClick={() => {
                                  navigate({
                                    to: params.row.isEvent
                                      ? eventsDetailPath.replace(':eventId', params.row.event.id)
                                      : iltSessionsDetailPath.replace(
                                          ':sessionId',
                                          params.row.event.id
                                        ),
                                    search: { activeTab: TAB_KEYS.OVERVIEW }
                                  });
                                }}
                              >
                                {params?.value}
                              </Link>
                            </Typography>
                          </Tooltip>
                        ) : (
                          get(params.row, params.field)
                        )}
                      </div>
                    );
                  }
                }))
              ]}
              initialState={{
                pagination: {
                  pageSize: totalPages,
                  rowCount: totalElements,
                  page: filter?.page
                }
              }}
              onSelectionModelChange={(newSelectionModel) => {
                dispatch(setSelectionModel(newSelectionModel));
              }}
              selectionModel={selectionModel}
              checkboxSelection
              localeText={getLocalesText(Localize)}
              pagination
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              disableColumnMenu
              page={filter?.page}
              pageSize={filter?.size}
              rowCount={totalElements}
              rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
              disableSelectionOnClick
              components={{ Toolbar: () => <TableToolbar /> }}
              onPageChange={(page) =>
                dispatch(setFilterParams({ key: 'page', value: page >= 0 ? page : 0 }))
              }
              onPageSizeChange={(value) => dispatch(setFilterParams([{ key: 'size', value }]))}
              sortingOrder={SORTING_ORDER}
              onSortModelChange={onSortChange}
              onFilterModelChange={onFilterChange}
            />
          </TabContainer>
        </TabContent>
      </div>
    </>
  );
};

export default HotelBookingTable;
