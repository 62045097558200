import { TAB_KEYS } from '@common/Constants';
import AttachmentsTable from '@components/AttachmentsTable';
import NotesTable from '@components/NotesTable';

import { BusinessPartnersOverview } from '..';
import ContactTable from './ContactTable/ContactTable';
import ParticipationTable from './ParticipationTable/ParticipationTable';

export const BUSINESS_PARTNERS_TABS_COMPONENTS = {
  [TAB_KEYS.OVERVIEW]: BusinessPartnersOverview,
  [TAB_KEYS.NOTE]: NotesTable,
  [TAB_KEYS.ATTACHMENT]: AttachmentsTable,
  [TAB_KEYS.CONTACT]: ContactTable,
  [TAB_KEYS.PARTICIPATION]: ParticipationTable
};

const accountTabs = {
  activeIndex: 0,
  tabs: [
    {
      label: 'Labels.Overview',
      key: TAB_KEYS.OVERVIEW,
      icon: 'info',
      isCountVisible: false
    },
    {
      label: 'Launchpad.Contacts',
      key: TAB_KEYS.CONTACT,
      icon: 'contacts',
      toolbarButtons: [
        {
          name: 'add_participant',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 110,
          maxWidth: 110,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          maxWidth: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'department',
          headerName: 'ContactsTile.Department',
          editable: false,
          width: 250,
          maxWidth: 250,
          headerAlign: 'left'
        },
        {
          field: 'function',
          headerName: 'ContactsTile.Function',
          editable: false,
          headerAlign: 'left',
          width: 300,
          maxWidth: 300
        },
        {
          field: 'jobTitle',
          headerName: 'Labels.Job',
          editable: false,
          headerAlign: 'left',
          width: 200,
          maxWidth: 200
        }
      ]
    },
    {
      label: 'Labels.Attachments',
      key: TAB_KEYS.ATTACHMENT,
      icon: 'attachments',
      toolbarButtons: [
        {
          name: 'add',
          disabled: false,
          icon: 'add',
          type: 'upload'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'size',
          headerName: 'Labels.Size',
          width: 250,
          editable: false,
          defaultValue: '0 KB',
          headerAlign: 'left'
        },
        {
          field: 'createdBy',
          headerName: 'Labels.CreatedBy',
          width: 250,
          editable: false,
          headerAlign: 'left',
          valueGetter: (params) => params?.value?.name || 'Unknown User'
        }
      ]
    },
    {
      label: 'Labels.Notes',
      key: TAB_KEYS.NOTE,
      icon: 'note_alt',
      toolbarButtons: [
        {
          name: 'add_note',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'type.value',
          headerName: 'Labels.Type',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'text',
          headerName: 'Labels.Text',
          width: 250,
          editable: false,
          headerAlign: 'left'
        }
      ]
    }
  ]
};

const individualCustomerTabs = {
  activeIndex: 0,
  tabs: [
    {
      label: 'Labels.Overview',
      key: TAB_KEYS.OVERVIEW,
      icon: 'info',
      isCountVisible: false
    },
    {
      label: 'Labels.Participations',
      key: TAB_KEYS.PARTICIPATION,
      icon: 'event',
      columns: [
        {
          field: 'name',
          headerName: 'IltSession.Name',
          width: 200,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'startDateTime',
          headerName: 'Events.StartDate',
          width: 200,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'endDateTime',
          headerName: 'Events.EndDate',
          width: 200,
          editable: true,
          headerAlign: 'left'
        },
        {
          field: 'status',
          headerName: 'IltSession.Status',
          width: 200,
          editable: false,
          defaultValue: '',
          headerAlign: 'left',
          valueGetter: ({ row }) => {
            return row?.status?.value;
          }
        },
        {
          field: 'location',
          headerName: 'Labels.Location',
          width: 200,
          editable: false,
          defaultValue: '',
          headerAlign: 'left'
        },
        {
          field: 'type',
          headerName: 'IltSession.Type',
          width: 200,
          editable: false,
          defaultValue: '',
          headerAlign: 'left'
        }
      ]
    },
    {
      label: 'Launchpad.Contacts',
      key: TAB_KEYS.CONTACT,
      icon: 'contacts',
      toolbarButtons: [
        {
          name: 'add_participant',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 110,
          maxWidth: 110,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          maxWidth: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'department',
          headerName: 'ContactsTile.Department',
          editable: false,
          width: 250,
          maxWidth: 250,
          headerAlign: 'left'
        },
        {
          field: 'function',
          headerName: 'ContactsTile.Function',
          editable: false,
          headerAlign: 'left',
          width: 300,
          maxWidth: 300
        },
        {
          field: 'jobTitle',
          headerName: 'Labels.Job',
          editable: false,
          headerAlign: 'left',
          width: 200,
          maxWidth: 200
        }
      ]
    },
    {
      label: 'Labels.Attachments',
      key: TAB_KEYS.ATTACHMENT,
      icon: 'attachments',
      toolbarButtons: [
        {
          name: 'add',
          disabled: false,
          icon: 'add',
          type: 'upload'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'size',
          headerName: 'Labels.Size',
          width: 250,
          editable: false,
          defaultValue: '0 KB',
          headerAlign: 'left'
        },
        {
          field: 'createdBy',
          headerName: 'Labels.CreatedBy',
          width: 250,
          editable: false,
          headerAlign: 'left',
          valueGetter: (params) => params?.value?.name || 'Unknown User'
        }
      ]
    },
    {
      label: 'Labels.Notes',
      key: TAB_KEYS.NOTE,
      icon: 'note_alt',
      toolbarButtons: [
        {
          name: 'add_note',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'type.value',
          headerName: 'Labels.Type',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'text',
          headerName: 'Labels.Text',
          width: 250,
          editable: false,
          headerAlign: 'left'
        }
      ]
    }
  ]
};

export default {
  activeIndex: 0,
  tabs: [
    {
      label: 'Labels.Overview',
      key: TAB_KEYS.OVERVIEW,
      icon: 'info',
      isCountVisible: false
    },
    {
      label: 'Launchpad.Contacts',
      key: TAB_KEYS.CONTACT,
      icon: 'contacts',
      toolbarButtons: [
        {
          name: 'add_participant',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 110,
          maxWidth: 110,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          maxWidth: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'department',
          headerName: 'ContactsTile.Department',
          editable: false,
          width: 250,
          maxWidth: 250,
          headerAlign: 'left'
        },
        {
          field: 'function',
          headerName: 'ContactsTile.Function',
          editable: false,
          headerAlign: 'left',
          width: 300,
          maxWidth: 300
        },
        {
          field: 'jobTitle',
          headerName: 'Labels.Job',
          editable: false,
          headerAlign: 'left',
          width: 200,
          maxWidth: 200
        }
      ]
    },
    {
      label: 'Labels.Attachments',
      key: TAB_KEYS.ATTACHMENT,
      icon: 'attachments',
      toolbarButtons: [
        {
          name: 'add',
          disabled: false,
          icon: 'add',
          type: 'upload'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'size',
          headerName: 'Labels.Size',
          width: 250,
          editable: false,
          defaultValue: '0 KB',
          headerAlign: 'left'
        },
        {
          field: 'createdBy',
          headerName: 'Labels.CreatedBy',
          width: 250,
          editable: false,
          headerAlign: 'left',
          valueGetter: (params) => params?.value?.name || 'Unknown User'
        }
      ]
    },
    {
      label: 'Labels.Notes',
      key: TAB_KEYS.NOTE,
      icon: 'note_alt',
      toolbarButtons: [
        {
          name: 'add_note',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'type.value',
          headerName: 'Labels.Type',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'text',
          headerName: 'Labels.Text',
          width: 250,
          editable: false,
          headerAlign: 'left'
        }
      ]
    }
  ]
};

export { accountTabs, individualCustomerTabs };
