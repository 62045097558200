import React, { useState } from 'react';

import NoData from '@components/NoData';
import Tabs from '@components/Tabs';

import tabsConfig, { PARTICIPANTS_TABS_COMPONENTS } from './tabsConfig';

const ParticipantTabs = ({ eventId, entityId, details = null, setDetails = () => {} }) => {
  const [config] = useState(tabsConfig());

  return (
    <Tabs
      tabsConfig={config}
      count={details?.counts}
      renderContent={(tab) => {
        const DynamicComponent = PARTICIPANTS_TABS_COMPONENTS[tab?.key];
        return DynamicComponent ? (
          <DynamicComponent
            eventId={eventId}
            entityId={entityId}
            entityType={tab.path}
            columns={tab.columns}
            toolbarButtons={tab?.toolbarButtons}
            allowedEntityId={8}
            setDetails={setDetails}
            key={entityId}
          />
        ) : (
          <NoData />
        );
      }}
    ></Tabs>
  );
};

export default ParticipantTabs;
