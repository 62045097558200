import { FILTER_KEYS, FILTER_TYPES } from '@components/FilterDialog';

const participantsFilters = [
  {
    label: 'Participants.EventName',
    id: 'eventName',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Participants.Title.Title',
    id: 'title',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' },
    options: [
      { label: 'Participants.Title.Mr', key: 'Participants.Title.Mr', translateKey: true },
      { label: 'Participants.Title.Ms', key: 'Participants.Title.Ms', translateKey: true },
      { label: 'Participants.Title.Mrs', key: 'Participants.Title.Mrs', translateKey: true }
    ]
  },
  {
    label: 'Participants.FirstName',
    id: 'firstName',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Participants.LastName',
    id: 'lastName',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Participants.CompanyName',
    id: 'companyName',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Participants.CompanyInvoice',
    id: 'invoiceCompanyName',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Participants.Status.Title',
    id: 'status',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' },
    options: []
  },
  {
    label: 'Participants.CancelationReasons.Title',
    id: 'cancellationReason',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' },
    options: [
      {
        label: 'Participants.CancelationReasons.EventCancelled',
        key: 'Participants.CancelationReasons.EventCancelled',
        translateKey: true
      },
      {
        label: 'Participants.CancelationReasons.CourseCancelled',
        key: 'Participants.CancelationReasons.CourseCancelled',
        translateKey: true
      },
      {
        label: 'Participants.CancelationReasons.ParticipantCancelled',
        key: 'Participants.CancelationReasons.ParticipantCancelled',
        translateKey: true
      }
    ]
  },
  {
    label: 'Participants.StartDate',
    id: 'startDate',
    type: FILTER_TYPES.date,
    operator: { key: FILTER_KEYS.IsOn, label: 'Tables.FilterValues.IsOn' }
  },
  {
    label: 'Participants.EndDate',
    id: 'endDate',
    type: FILTER_TYPES.date,
    operator: { key: FILTER_KEYS.IsOn, label: 'Tables.FilterValues.IsOn' }
  },
  {
    label: 'Participants.Country',
    id: 'country',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' },
    options: []
  },
  {
    label: 'Participants.City',
    id: 'city',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  }
];

export default participantsFilters;
