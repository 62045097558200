import React, { useEffect } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useSearch, useMatch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { ACTION_MODES } from '@common/Constants';
import { checkChangedFields } from '@common/helpers/helpers';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import EntityTypes from '@common/network/EntityTypes';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import {
  closeSidebar,
  openSidebar,
  setMode as setRightSidebarMode
} from '@components/RightSidebar/rightSidebarSlice';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import ToolbarButton from '@components/ToolbarButton';
import { TABLE_OPTIONS } from '@config/network';
import {
  fetchPrices,
  deletePrices,
  resetState,
  selectFilter,
  selectIsLoading,
  selectList,
  selectSelectionModel,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  setSelectionModel,
  setDetails,
  selectDetails,
  patchPrices
} from '@pages/Events/components/Tabs/Price/priceTableSlice';

import CreatePrice from './CreatePrice/CreatePrice';
import { pricesSchema } from './CreatePrice/pricesSchema';
import sidebarConfig from './sidebarConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  },
  cellCheckbox: {
    padding: '5px 40px 5px 5px !important'
  },
  customTable: {
    overflowX: 'hidden',
    width: '92rem',
    '&:lastChild': {
      width: '100%',
      minWidth: 200
    }
  }
}));

const PriceTable = ({ columns = [], entityId = null, entityType = null, toolbarButtons = [] }) => {
  const {
    data: {
      priceType: { data: priceType },
      currencyType: { data: currencyType }
    }
  } = useMatch();
  const classes = useStyles();
  const dispatch = useDispatch();
  const data = useSelector(selectList);
  const navigate = useNavigate();
  const search = useSearch();
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);
  const details = useSelector(selectDetails);

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(fetchPrices({ entityId: entityId, filter: filter }));
    }
  }, [entityId, filter?.page, filter?.sortBy, filter?.sortDirection, filter?.size]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarButtonDisabled = (name) => {
    switch (name) {
      case 'add_price':
        return !entityId || !entityType;
      case 'delete':
        return selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onToolbarButtonClick = (name) => {
    switch (name) {
      case 'add_price':
        navigate({
          search: (previousUrlParams) => ({
            ...previousUrlParams,
            mode: ACTION_MODES.Create
          })
        });
        break;

      case 'delete':
        {
          dispatch(
            openConfirmDialog({
              title: Localize.get('ConfirmationMessages.Delete', {
                item: Localize.get('Events.SingularOrPluralPrice', {
                  selectionModel: selectionModel.length
                })?.toLowerCase()
              }),
              confirmButton: Localize.get('Buttons.Delete'),
              cancelButton: Localize.get('Buttons.Cancel')
            })
          )
            .unwrap()
            .then((result) => {
              if (result === CONFIRM_ACTIONS.Confirm) {
                dispatch(deletePrices({ priceIds: selectionModel, id: entityId }));
              }
            });
        }
        break;

      default:
        return false;
    }
  };

  const extendedConfig = (setFieldValue) => {
    return {
      ['currency.id']: {
        options: currencyType,
        onChange: (event) => {
          setFieldValue('currency', {
            id: event.target.value,
            value: currencyType.find((s) => s.id === event.target.value).value
          });
        }
      },
      ['type.id']: {
        options: priceType,
        onChange: (event) => {
          setFieldValue('type', {
            id: event.target.value,
            value: priceType.find((s) => s.id === event.target.value).value
          });
        }
      },
      ['validTo']: {
        onChange: (value) => setFieldValue('validTo', value)
      },
      ['validFrom']: {
        onChange: (value) => setFieldValue('validFrom', value)
      }
    };
  };

  useEffect(() => {
    if (!details) {
      return;
    }

    dispatch(
      openSidebar({
        config: {
          title: Localize.get('ResourcesTile.PriceDetails'),
          fieldsConfig: sidebarConfig
        },
        data: details,
        extendedConfig: extendedConfig,
        validationSchema: pricesSchema,
        onSave: (values) => {
          return dispatch(
            patchPrices({
              data: checkChangedFields(details, values),
              id: details.id,
              eventId: entityId
            })
          )
            .unwrap()
            .then(() => {
              dispatch(setRightSidebarMode('read'));
              return Promise.resolve();
            })
            .catch((rejectedValueOrSerializedError) => {
              return Promise.reject({
                rejectedValueOrSerializedError,
                entityType: EntityTypes.PRICES
              });
            });
        },
        onDelete: () => {
          dispatch(
            openConfirmDialog({
              title: Localize.get('ConfirmationMessages.Delete', {
                item: Localize.get('ResourcesTile.Price')?.toLowerCase()
              }),
              confirmButton: Localize.get('Buttons.Delete'),
              cancelButton: Localize.get('Buttons.Cancel')
            })
          )
            .unwrap()
            .then((result) => {
              if (result === CONFIRM_ACTIONS.Confirm) {
                dispatch(deletePrices({ priceIds: [details.id], id: entityId }));
                dispatch(setRightSidebarMode('read'));
                dispatch(closeSidebar());
              }
            });
        }
      })
    );
  }, [details]);

  const onRowClick = ({ row }) => dispatch(setDetails(row));

  return (
    <>
      <div className={classes.root}>
        {search?.mode === ACTION_MODES.Create ? (
          <CreatePrice />
        ) : (
          <TabContent>
            <div className={classes.toolbarContainer}>
              <Typography variant="h6" component="h6" color="primary">
                {Localize.get('Labels.Prices')}
              </Typography>
              <Toolbar className={classes.toolbar}>
                {toolbarButtons?.map((button, index) => (
                  <ToolbarButton
                    key={index}
                    button={button}
                    isDisabled={button.disabled || isToolbarButtonDisabled(button.name)}
                    onToolbarButtonClick={onToolbarButtonClick}
                  />
                ))}
              </Toolbar>
            </div>
            <TabContainer>
              <StyledDataGrid
                onRowClick={onRowClick}
                keepNonExistentRowsSelected
                loading={isLoading}
                rows={data}
                columns={[
                  ...columns.map((column) => ({
                    ...column,
                    headerName: Localize.get(column.headerName)
                  }))
                ]}
                initialState={{
                  pagination: {
                    pageSize: totalPages,
                    rowCount: totalElements,
                    page: filter?.page
                  }
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  dispatch(setSelectionModel(newSelectionModel));
                }}
                selectionModel={selectionModel}
                checkboxSelection
                localeText={getLocalesText(Localize)}
                pagination
                paginationMode="server"
                disableColumnMenu
                page={filter?.page}
                pageSize={filter?.size}
                rowCount={totalElements}
                rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
                disableSelectionOnClick
                components={{ Toolbar: TableToolbar }}
                onPageChange={(page) => dispatch(setFilterParams({ page, selectionModel }))}
                onPageSizeChange={(value) =>
                  dispatch(setFilterParams([{ key: 'size', value, selectionModel }]))
                }
              />
            </TabContainer>
          </TabContent>
        )}
      </div>
    </>
  );
};

export default PriceTable;
