import { useRef } from 'react';
import Localize from 'react-intl-universal';

import CancelIcon from '@mui/icons-material/Cancel';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const FilePicker = ({
  field: { name, onBlur, value },
  onFileInputChange = () => {},
  onClearInputChange = () => {},
  ...rest
}) => {
  const { disabled, ...newRest } = rest;
  const uploadRef = useRef();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '85px'
      }}
    >
      <Button
        disabled={disabled || !!value}
        component="label"
        variant="contained"
        startIcon={<CloudUploadIcon />}
      >
        {Localize.get('IltSession.UploadFile')}
        <VisuallyHiddenInput
          sx={{ padding: 2 }}
          ref={uploadRef}
          accept="image/*,application/pdf,.xls,.xlsx,.csv,.docx"
          type="file"
          name={name}
          onBlur={onBlur}
          {...newRest}
          onChange={onFileInputChange}
        />
      </Button>
      <Box
        visibility={value ? 'visible' : 'hidden'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          mt: 1,
          height: '40px',
          pl: 2,
          opacity: disabled ? 0.3 : 1
        }}
      >
        <Tooltip title={value ? value.name : ''} arrow>
          <Typography
            noWrap
            variant="body"
            component="div"
            sx={{
              maxWidth: '300px'
            }}
          >
            {value ? value.name : ''}
          </Typography>
        </Tooltip>
        <Button
          disabled={disabled || !value}
          startIcon={value ? <CancelIcon color="error" /> : null}
          onClick={() => onClearInputChange(uploadRef)}
        />
      </Box>
    </Box>
  );
};

export default FilePicker;
