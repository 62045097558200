export const PARTICIPANT_STATUSES = {
  TO_BE_INVITED: 1,
  COMPLETED: 2,
  RESERVED: 3,
  CONFIRMED: 4,
  CANCELLED: 5,
  REGISTERED: 6,
  INVITED: 7,
  WAITING_CANCELLED: 8,
  NOT_COMPLETED: 9
};
