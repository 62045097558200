import moment from 'moment';

export default {
  person: { id: '', name: '' },
  arrivalDate: new Date(),
  departureDate: moment(new Date()).add(1, 'd').toDate(),
  hotelRequest: false,
  company: { id: '', name: '' },
  iltSessionRole: 1 // trainer selected,
};
