import moment from 'moment';

import { MUI_TABLE_FILTER_OPERATORS, isNumberOperator } from '@config/filterOperators';

import { isDateStringValid } from '../dates';

const getAdvancedFiltersForMUITable = (values = {}, isValueOptional) => {
  if (!values?.items?.length) return;
  const { columnField, value, operatorValue } = values.items[0];

  if (!columnField || !operatorValue || (!isValueOptional && !value)) {
    return;
  }

  return isValueOptional
    ? [
        {
          key: columnField,
          operation: MUI_TABLE_FILTER_OPERATORS[operatorValue]
        }
      ]
    : [
        {
          key: columnField,
          operation: MUI_TABLE_FILTER_OPERATORS[operatorValue],
          value: isDateStringValid(value) ? moment(value).format('YYYY-MM-DD') : value
        }
      ];
};

export const onTabsTableFilterChange = (values, trackFilterModel, resetFilter) => {
  const isValueOptional = ['isNotEmpty', 'isEmpty'].includes(values?.items[0]?.operatorValue);
  // reset filter on delete
  if (
    (trackFilterModel.current?.value && !values?.items[0]?.value && !isValueOptional) ||
    !values?.items[0]
  ) {
    resetFilter();
    trackFilterModel.current = values.items[0];
    return;
  }

  if (isNumberOperator(values?.items[0].operatorValue) && isNaN(Number(values?.items[0].value))) {
    trackFilterModel.current = values.items[0];
    return;
  }

  const advancedFilters = getAdvancedFiltersForMUITable(values, isValueOptional);
  if (!advancedFilters) {
    trackFilterModel.current = values.items[0];
    return;
  }

  trackFilterModel.current = values.items[0];
  return advancedFilters;
};

export default getAdvancedFiltersForMUITable;
