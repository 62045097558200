import { DIALOG_PATHS } from '@common/network/ApiPaths';
import IndividualTypes from '@common/network/IndividualTypes';

const classroomFields = [
  {
    groupName: 'Events.Information',
    fields: [
      {
        label: 'ParticipantsTile.Person',
        name: 'person.id',
        required: true,
        disabled: false,
        dialogTitle: 'Labels.SearchPerson',
        component: 'tokenValue',
        fetchDataPath: DIALOG_PATHS.PERSONS,
        value: '',
        initQueryState: {
          type: IndividualTypes.CONTACT,
          firstName: '',
          lastName: '',
          companyName: '',
          personTypeId: ''
        },
        dialogColumns: [
          {
            field: 'personTypeId',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'firstName',
            headerName: 'Labels.FirstName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'lastName',
            headerName: 'Labels.LastName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'companyName',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.CompanyName',
        name: 'company',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchCompany',
        component: 'tokenValue',
        fetchDataPath: DIALOG_PATHS.ACCOUNTS,
        create: {},
        value: '',
        initQueryState: {
          type: IndividualTypes.ORGANIZATION,
          name: '',
          id: ''
        },
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      }
    ]
  },
  {
    groupName: 'Locations.Hotel',
    fields: [
      {
        label: 'IltSession.HotelRequest',
        name: 'hotelRequest',
        required: false,
        disabled: false,
        component: 'switch',
        column: 2,
        alignItems: 'center'
      },
      {
        label: 'Hotel.ArrivalDate',
        name: 'arrivalDate',
        required: false,
        disabled: false,
        column: 5,
        component: 'datepicker'
      },
      {
        label: 'Hotel.DepartureDate',
        name: 'departureDate',
        required: false,
        disabled: false,
        component: 'datepicker',
        column: 5
      }
    ]
  }
];

const webinarFields = [
  {
    groupName: 'Events.Information',
    fields: [
      {
        label: 'ParticipantsTile.Person',
        name: 'person.id',
        required: true,
        disabled: false,
        dialogTitle: 'Labels.SearchPerson',
        component: 'tokenValue',
        fetchDataPath: DIALOG_PATHS.PERSONS,
        value: '',
        initQueryState: {
          type: IndividualTypes.CONTACT,
          firstName: '',
          lastName: '',
          companyName: '',
          personTypeId: ''
        },
        dialogColumns: [
          {
            field: 'personTypeId',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'firstName',
            headerName: 'Labels.FirstName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'lastName',
            headerName: 'Labels.LastName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'companyName',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      },
      {
        label: 'Labels.CompanyName',
        name: 'company',
        required: false,
        disabled: false,
        dialogTitle: 'Labels.SearchCompany',
        component: 'tokenValue',
        fetchDataPath: DIALOG_PATHS.ACCOUNTS,
        create: {},
        value: '',
        initQueryState: {
          type: IndividualTypes.ORGANIZATION,
          name: '',
          id: ''
        },
        dialogColumns: [
          {
            field: 'id',
            headerName: 'Labels.ID',
            width: 100,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          },
          {
            field: 'name',
            headerName: 'Labels.CompanyName',
            width: 350,
            editable: false,
            defaultValue: '',
            headerAlign: 'left',
            sortable: false
          }
        ]
      }
    ]
  }
];

export { classroomFields, webinarFields };
