import { useEffect, useRef } from 'react';
import Localize from 'react-intl-universal';
import { useDispatch, useSelector } from 'react-redux';

import DownloadIcon from '@mui/icons-material/Download';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { dateFormatTime, SORTING_ORDER } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import { checkChangedFields } from '@common/helpers/helpers';
import { onTabsTableFilterChange } from '@common/helpers/tables/getAdvancedFiltersForMUITable';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import EntityTypes from '@common/network/EntityTypes';
import { openConfirmDialog, CONFIRM_ACTIONS } from '@components/ConfirmDialog';
import {
  closeSidebar,
  openSidebar,
  setMode as setRightSidebarMode
} from '@components/RightSidebar/rightSidebarSlice';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import ToolbarButton from '@components/ToolbarButton';
import { getFilterOperatorsBasedOnType } from '@config/filterOperators';
import { TABLE_OPTIONS } from '@config/network';
import { patchAttachmentSchema } from '@pages/IltSession/util/schemas/createAttachmentSchema';
import { getByPathAndParams } from '@services/BaseApi';

import {
  resetState,
  selectIsLoading,
  selectData,
  selectFilter,
  selectTotalElements,
  selectSelectionModel,
  setSelectionModel,
  getAttachments,
  removeAttachments,
  toggleCreateDialog,
  selectIsCreateDialogOpen,
  selectDetails,
  patchAttachment,
  setAttachmentDetails,
  setFilterParams
} from './attachmentsTableSlice';
import CreateAttachment from './CreateAttachment/CreateAttachment';
import sidebarConfig from './sidebarConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  }
}));

const AttachmentsTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarButtons = [],
  setDetails = () => {}
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const trackFilterModel = useRef(null);

  const isLoading = useSelector(selectIsLoading);
  const data = useSelector(selectData);
  const filter = useSelector(selectFilter);
  const totalElements = useSelector(selectTotalElements);
  const selectionModel = useSelector(selectSelectionModel);
  const isCreateDialogOpen = useSelector(selectIsCreateDialogOpen);
  const details = useSelector(selectDetails);

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(getAttachments({ entityId, entityType, filter }));
    }
  }, [
    entityId,
    entityType,
    filter?.size,
    filter?.page,
    filter?.sortBy,
    filter?.sortDirection,
    filter?.filters,
    totalElements
  ]);

  const handleDownloadClick = (event, url = '', name = '', type = '*') => {
    event.preventDefault();

    getByPathAndParams({
      path: url,
      responseType: 'arraybuffer'
    })
      .then((payload) => {
        if (payload?.data) {
          const blob = new Blob([payload.data], { type });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.setAttribute('download', name);
          link.style = 'display: none';
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error('Invalid or empty response data when download attachment file');
        }
      })
      .catch((error) => error);
  };

  const onToolbarButtonClick = (name) => {
    switch (name) {
      case 'add_attachment':
        dispatch(toggleCreateDialog(true));
        return;
      case 'delete': {
        if (selectionModel.length > 1) {
          alert('Batch delete currently not supported');
          return;
        }

        dispatch(
          openConfirmDialog({
            title: Localize.get('ConfirmationMessages.Delete', {
              item: Localize.get('Labels.Attachment')?.toLowerCase()
            }),
            confirmButton: Localize.get('Buttons.Delete'),
            cancelButton: Localize.get('Buttons.Cancel')
          })
        )
          .unwrap()
          .then((result) => {
            if (result === CONFIRM_ACTIONS.Confirm) {
              dispatch(
                removeAttachments({
                  entityId,
                  entityType,
                  id: selectionModel[0],
                  setDetails
                })
              );
            }
          });

        return;
      }

      default:
        return false;
    }
  };

  const isToolbarButtonDisabled = (name) => {
    switch (name) {
      case 'add_attachment':
        return !entityId || !entityType;
      case 'delete':
        return selectionModel.length === 0;
      default:
        return false;
    }
  };

  const extendedConfig = (setFieldValue) => {
    return {
      ['file']: {
        onFileInputChange: (event) => {
          setFieldValue('file', event.currentTarget.files[0]);
        },
        onClearInputChange: (uploadRef) => {
          setFieldValue('file', null);
          if (uploadRef.current) {
            uploadRef.current.value = '';
          }
        }
      }
    };
  };

  useEffect(() => {
    if (!details) {
      return;
    }

    dispatch(
      openSidebar({
        config: {
          title: Localize.get('IltSession.AttachmentDetails'),
          fieldsConfig: sidebarConfig
        },
        data: details,
        extendedConfig: extendedConfig,
        validationSchema: patchAttachmentSchema,
        onSave: (values) => {
          return dispatch(
            patchAttachment({
              data: checkChangedFields(details, values),
              id: details.id
            })
          )
            .unwrap()
            .then(() => {
              dispatch(setRightSidebarMode('read'));
              return Promise.resolve();
            })
            .catch((rejectedValueOrSerializedError) => {
              return Promise.reject({
                rejectedValueOrSerializedError,
                entityType: EntityTypes.PRICES
              });
            });
        },
        onDelete: () => {
          dispatch(
            openConfirmDialog({
              title: Localize.get('ConfirmationMessages.Delete', {
                item: Localize.get('Labels.Attachment')?.toLowerCase()
              }),
              confirmButton: Localize.get('Buttons.Delete'),
              cancelButton: Localize.get('Buttons.Cancel')
            })
          )
            .unwrap()
            .then((result) => {
              if (result === CONFIRM_ACTIONS.Confirm) {
                dispatch(
                  removeAttachments({
                    entityId,
                    entityType,
                    id: details.id,
                    setDetails
                  })
                );
                dispatch(setRightSidebarMode('read'));
                dispatch(closeSidebar());
              }
            });
        }
      })
    );
  }, [details]);

  const onFilterChange = (values) => {
    const advancedFilters = onTabsTableFilterChange(values, trackFilterModel, () =>
      dispatch(setFilterParams({ key: 'filters', value: null }))
    );
    if (advancedFilters) dispatch(setFilterParams({ key: 'filters', value: { advancedFilters } }));
  };

  const onSortChange = (sortModel) =>
    dispatch(
      setFilterParams([
        { key: 'sortBy', value: sortModel[0]?.field },
        { key: 'sortDirection', value: sortModel[0]?.sort }
      ])
    );

  const onRowClick = ({ row }) => {
    dispatch(setAttachmentDetails({ ...row, file: { name: row.name } }));
  };

  return (
    <div className={classes.root}>
      <TabContent>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6" component="h6" color="primary">
            {Localize.get('Labels.Attachments')}
          </Typography>
          <Toolbar className={classes.toolbar}>
            {toolbarButtons?.map((button, index) => (
              <ToolbarButton
                key={index}
                button={button}
                isDisabled={button.disabled || isToolbarButtonDisabled(button.name)}
                onToolbarButtonClick={onToolbarButtonClick}
              />
            ))}
          </Toolbar>
        </div>
        <TabContainer>
          <StyledDataGrid
            onRowClick={onRowClick}
            loading={isLoading}
            rows={data}
            getRowClassName={() => classes.tableRow}
            columns={[
              ...columns.map((column) => ({
                ...column,
                headerName: Localize.get(column.headerName),
                filterOperators: getFilterOperatorsBasedOnType(column?.type),
                renderCell: (params) => {
                  return (
                    <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                      {[
                        'displayName',
                        'name',
                        'visibilityAccessGranted',
                        'extension',
                        'createdBy'
                      ].includes(params.field) ? (
                        <Tooltip title={params.value} arrow>
                          <Typography
                            noWrap
                            variant="body"
                            component="div"
                            sx={{
                              maxWidth: params?.colDef?.width - 10 // 10 px for three dots,
                            }}
                          >
                            {params.value}
                          </Typography>
                        </Tooltip>
                      ) : ['url'].includes(params.field) ? (
                        <a
                          style={{ width: '100%' }}
                          href={params?.row?.url}
                          rel="noreferrer"
                          onClick={(event) => {
                            event.stopPropagation();
                            handleDownloadClick(
                              event,
                              params?.row?.url,
                              params?.row?.name,
                              params?.row?.type
                            );
                          }}
                        >
                          <DownloadIcon color="primary" />
                        </a>
                      ) : ['createdAt'].includes(params.field) ? (
                        dateToFormat(params.value, dateFormatTime.dateTime)
                      ) : ['extension'].includes(params.field) ? (
                        `Image (.${params.value})`
                      ) : (
                        params.value
                      )}
                    </div>
                  );
                }
              }))
            ]}
            initialState={{
              pagination: {
                pageSize: 1,
                rowCount: totalElements,
                page: filter?.page
              }
            }}
            localeText={getLocalesText(Localize)}
            paginationMode="server"
            pagination
            sortingMode="server"
            filterMode="server"
            checkboxSelection
            disableColumnMenu
            page={filter?.page}
            pageSize={filter?.size}
            rowCount={totalElements}
            rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
            disableSelectionOnClick
            components={{ Toolbar: TableToolbar }}
            onSelectionModelChange={(values) => dispatch(setSelectionModel(values))}
            onPageChange={(page) =>
              dispatch(setFilterParams({ key: 'page', value: page >= 0 ? page : 0 }))
            }
            onPageSizeChange={(value) =>
              dispatch(setFilterParams([{ key: 'size', value, selectionModel }]))
            }
            onSortModelChange={onSortChange}
            onFilterModelChange={onFilterChange}
            sortingOrder={SORTING_ORDER}
          />
        </TabContainer>
        <CreateAttachment
          isOpen={isCreateDialogOpen}
          entityId={entityId}
          entityType={entityType}
          setDetails={setDetails}
        />
      </TabContent>
    </div>
  );
};

export default AttachmentsTable;
