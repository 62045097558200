import React from 'react';
import Localize from 'react-intl-universal';
import { useSelector } from 'react-redux';

import Overview from '@components/Overview';
import { participantsNewPath } from '@navigation/routes/Routes';
import { selectDetails, selectIsLoading } from '@pages/Participants/participantSlice';

const ParticipantsOverview = () => {
  const details = useSelector(selectDetails);
  const isLoading = useSelector(selectIsLoading);

  let dynamicDetails = [
    {
      label: 'Labels.EventStatus',
      name: 'event.status.value'
    },
    {
      label: 'Event.CancellationReason',
      name: 'cancellationReason.value'
    },
    {
      label: 'Event.Remarks',
      name: 'remarks'
    },
    {
      label: 'ParticipantsTile.Result',
      name: 'result.value'
    }
  ];

  if (details?.attendeeDto?.isLead) {
    dynamicDetails = [
      ...dynamicDetails,
      {
        label: 'ParticipantsTile.LeadName',
        name: 'attendeeDto.leadName'
      },
      {
        label: 'ParticipantsTile.LeadStatus',
        name: 'attendeeDto.leadStatus'
      }
    ];
  }

  return (
    <Overview
      invite={{
        text: Localize.get('Links.InviteLink', {
          entity: Localize.get('ParticipantsTile.Item')
        }),
        link: participantsNewPath
      }}
      isLoading={isLoading}
      data={details}
      config={{
        width: '500px',
        title: 'ParticipantsTile.Overview',
        skeletonCount: 6,
        header: {
          icon: 'school',
          title: 'Event.Attending',
          subTitle: details?.event?.name ? ['event.name'] : 'Event.NoAssigned'
        },
        details: dynamicDetails
      }}
    />
  );
};
export default ParticipantsOverview;
