const webinarSidebar = [
  {
    groupName: 'ParticipantsTile.Information',
    fields: [
      {
        label: 'Labels.FirstName',
        name: 'personDto.firstName',
        required: false,
        disabled: true,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'Labels.LastName',
        name: 'personDto.lastName',
        required: false,
        disabled: true,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'Labels.Company',
        name: 'company',
        required: false,
        disabled: true,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'Labels.Status',
        name: 'status.value',
        required: true,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Event.CancellationReason',
        name: 'cancellationReason',
        required: false,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Event.CancellationComment',
        name: 'cancellationComment',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  }
];

const classroomSidebar = [
  {
    groupName: 'ParticipantsTile.Information',
    fields: [
      {
        label: 'Labels.FirstName',
        name: 'personDto.firstName',
        required: false,
        disabled: true,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'Labels.LastName',
        name: 'personDto.lastName',
        required: false,
        disabled: true,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'Labels.Company',
        name: 'company',
        required: false,
        disabled: true,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'Labels.Status',
        name: 'status.value',
        required: true,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Event.CancellationReason',
        name: 'cancellationReason',
        required: false,
        disabled: false,
        component: 'select'
      },
      {
        label: 'Event.CancellationComment',
        name: 'cancellationComment',
        required: false,
        disabled: false,
        component: 'text'
      }
    ]
  },
  {
    groupName: 'Hotel.Information',
    name: 'hotels',
    fields: [
      {
        label: 'IltSession.HotelRequest',
        name: 'hotelRequest',
        required: false,
        disabled: false,
        component: 'switch'
      },
      {
        label: 'Hotel.Name',
        name: 'hotels[0].name',
        required: false,
        disabled: true,
        component: 'text',
        maxLength: 30
      },
      {
        label: 'Hotel.ArrivalDate',
        name: 'arrivalDate',
        required: false,
        disabled: false,
        component: 'datepicker'
      },
      {
        label: 'Hotel.DepartureDate',
        name: 'departureDate',
        required: false,
        disabled: false,
        component: 'datepicker'
      }
    ]
  }
];

export { webinarSidebar, classroomSidebar };
