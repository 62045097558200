import React, { useState, useEffect, useCallback } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useMatch } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import { get } from 'lodash';
import moment from 'moment';

import EntityTypes from '@common/network/EntityTypes';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import { selectIsFullScreen } from '@components/DetailsToolbar/detailsToolbarSlice';
import {
  FILTER_ACTIONS,
  openFilterDialog,
  resetFilterState,
  scrubFiltersForBE,
  selectIsActive
} from '@components/FilterDialog/filtersSlice';
import Header from '@components/Header';
import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';
import MasterList from '@components/MasterList/MasterList';
import MasterListFooter from '@components/MasterListFooter';
import MasterListItem from '@components/MasterListItem';
import MasterListTitle from '@components/MasterListTitle';
import MasterListToolbar from '@components/MasterListToolbar';
import { selectIsOpen, setMode } from '@components/RightSidebar/rightSidebarSlice';
import SortDialog from '@components/SortDialog';
import { DATE_FORMAT, SEARCH_INPUT_DELAY } from '@config/inputs';
import useDebounce from '@hooks/handlers/useDebounce';
import { participantsNewPath } from '@navigation/routes/Routes';

import ParticipantsDetails from './components/Details/ParticipantsDetails';
import Sidebar from './components/Sidebar';
import ParticipantTabs from './components/Tabs/ParticipantTabs';
import {
  initialState,
  selectId,
  selectList,
  setLoading,
  selectFilter,
  selectTotalElements,
  selectTotalPages,
  selectDetails,
  selectIsLoading,
  selectIsDetailsLoading,
  setSelectedId,
  setFilterParams,
  fetchParticipantDetails,
  fetchParticipants,
  saveParticipant,
  resetState,
  setDetails,
  deleteParticipant
} from './participantSlice';
import participantsFilters from './utils/fields/filters';
import { masterSortKeys } from './utils/helpers/Constants';

const Participants = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data: {
      participantStatuses: { data: status },
      countriesList: { data: countriesList }
    }
  } = useMatch();

  const selectedId = useSelector(selectId);
  const filter = useSelector(selectFilter);
  const data = useSelector(selectList);
  const details = useSelector(selectDetails);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);
  const isLoading = useSelector(selectIsLoading);
  const isOpen = useSelector(selectIsOpen);
  const isFullScreen = useSelector(selectIsFullScreen);
  const isFilterActive = useSelector(selectIsActive);

  const [isOpenDialogSort, setIsOpenDialogSort] = useState(false);

  participantsFilters.find((el) => el.id === 'status').options = status.map((el) => ({
    label: el.value,
    key: el.value
  }));

  useEffect(
    () => dispatch(fetchParticipants(filter)),
    [filter.sortBy, filter.sortDirection, filter.page]
  );

  useEffect(() => dispatch(fetchParticipantDetails(selectedId)), [selectedId]);

  useDebounce(() => dispatch(fetchParticipants(filter)), SEARCH_INPUT_DELAY, [filter.search]);

  useEffect(() => {
    return () => {
      dispatch(resetFilterState());
      dispatch(resetState());
    };
  }, []);

  const onAddNewClick = () => {
    navigate({ to: participantsNewPath, replace: true });
  };

  const onEditSave = (values) => {
    return dispatch(saveParticipant(values))
      .unwrap()
      .then(() => {
        return Promise.resolve();
      })
      .catch((rejectedValueOrSerializedError) => {
        return Promise.reject({
          rejectedValueOrSerializedError,
          entityType: EntityTypes.PARTICIPANT
        });
      });
  };

  const onDelete = () => {
    dispatch(
      openConfirmDialog({
        title: Localize.get('ConfirmationMessages.Delete', {
          item: Localize.get('ParticipantsTile.Item')?.toLowerCase()
        }),
        confirmButton: Localize.get('Buttons.Delete'),
        cancelButton: Localize.get('Buttons.Cancel')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Cancel) {
          return;
        }
        dispatch(deleteParticipant(selectedId))
          .unwrap()
          .then(() => dispatch(setMode('read')))
          .catch((rejectedValueOrSerializedError) => console.error(rejectedValueOrSerializedError));
      });
  };

  const onFilterClick = useCallback(() => {
    const countryFilter = participantsFilters.find((item) => item.id === 'country');
    if (countryFilter)
      countryFilter.options = countriesList.map((item) => ({
        label: item.value,
        key: item.value,
        translateKey: false
      }));

    dispatch(
      openFilterDialog({
        title: `${Localize.get('ParticipantsTile.Item')} ${Localize.get('Labels.Filter')}`,
        config: participantsFilters.map((filter) => ({
          ...filter,
          label: Localize.get(filter.label),
          operator: { label: Localize.get(filter.operator.label), key: filter.operator.key },
          options: filter.options?.map((option) => {
            if (option?.label) {
              return {
                ...option,
                key: option?.translateKey ? Localize.get(option.key) : option.key,
                label: option?.translateKey ? Localize.get(option.label) : option.label
              };
            }
            return Localize.get(option);
          })
        }))
      })
    ).then(({ payload }) => {
      if (payload.action === FILTER_ACTIONS.Cancel) {
        return;
      }

      if (payload.action === FILTER_ACTIONS.Reset) {
        dispatch(fetchParticipants(filter));
        return;
      }

      dispatch(
        fetchParticipants({
          ...filter,
          filters: { advancedFilters: scrubFiltersForBE(payload.filters) }
        })
      );
    });
  }, [filter?.sortBy, filter?.sortDirection, filter?.page]);

  // Render Master List Item
  const renderMasterListItem = useCallback(
    ({ id, event, personDto, status }) => (
      <MasterListItem
        key={id}
        id={id}
        heading={{ left: [personDto?.firstName, personDto?.lastName].join(' '), right: id }}
        leftDetails={{
          firstRow: moment(get(event, 'startDateTime', '')).format(DATE_FORMAT),
          secondRow: get(event, 'name', '')
        }}
        rightDetails={{ firstRow: status?.value ?? '' }}
        selectedId={selectedId}
        onItemSelect={(payload) => dispatch(setSelectedId(payload))}
      />
    ),
    [selectedId]
  );

  return (
    <LayoutContainer>
      <LeftContainer isFullScreen={isFullScreen}>
        <Header>
          <MasterListTitle>{`${Localize.get(
            'ParticipantsTile.All'
          )} (${totalElements})`}</MasterListTitle>
        </Header>

        <MasterListToolbar
          isFilterActive={isFilterActive}
          isDisabled={data?.length === 0}
          onSortClick={() => setIsOpenDialogSort(true)}
          onFilterClick={onFilterClick}
          isSortActive={
            filter.sortBy !== initialState.filter.sortBy ||
            filter.sortDirection !== initialState.filter.sortDirection
          }
          searchProps={{
            value: filter.search,
            onSearchChange: (e) => {
              dispatch(setLoading(true));
              dispatch(setFilterParams({ key: 'search', value: e.target.value }));
            }
          }}
        />

        <MasterList isLoading={isLoading} data={data} renderMasterItem={renderMasterListItem} />
        <MasterListFooter
          onAddClick={onAddNewClick}
          onPageChange={(e, page) => dispatch(fetchParticipants({ ...filter, page: page - 1 }))}
          pagination={{ totalPages, page: filter.page + 1 }}
        />

        <SortDialog
          open={isOpenDialogSort}
          onCancel={() => setIsOpenDialogSort(false)}
          fields={masterSortKeys}
          sortHandler={({ sortBy, sortDirection }) => {
            dispatch(
              setFilterParams([
                { key: 'sortBy', value: sortBy },
                { key: 'sortDirection', value: sortDirection }
              ])
            );
          }}
          sortState={filter}
        />
      </LeftContainer>

      <RightContainer open={isOpen} isFullScreen={isFullScreen}>
        <ParticipantsDetails details={details} entityId={selectedId}>
          <ParticipantTabs
            eventId={details?.event?.id}
            entityId={selectedId}
            entityType={EntityTypes.PARTICIPANT}
            details={details}
            setDetails={setDetails}
          />
        </ParticipantsDetails>
      </RightContainer>

      <Sidebar
        details={details}
        isDetailsLoading={isDetailsLoading}
        onSave={onEditSave}
        onDelete={onDelete}
      />
    </LayoutContainer>
  );
};

export default Participants;
