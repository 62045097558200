import Localize from 'react-intl-universal';

import moment from 'moment';
import * as yup from 'yup';

export default function () {
  return yup.object().shape({
    currency: yup
      .object()
      .shape({
        id: yup.number().nullable(true),
        value: yup.string()
      })
      .nullable(true),
    validUntil: yup
      .date()
      .max(yup.ref('startDate'), Localize.get('ValidationMessages.ValidUntilBeforeStartDate'))
      .nullable(),
    startDate: yup
      .date()
      .typeError(Localize.get('ValidationMessages.DateTimeInvalid'))
      .required(Localize.get('ValidationMessages.StartDate')),
    endDate: yup
      .date()
      .typeError(Localize.get('ValidationMessages.DateTimeInvalid'))
      .required(Localize.get('ValidationMessages.EndDate'))
      .test(
        'is-after-start-one-day',
        Localize.get('ValidationMessages.EndDateStartDate'),
        function (value, e) {
          const endDate = moment(value).toDate();
          const startDate = moment(e?.parent?.startDate).toDate();
          return moment(endDate).isAfter(startDate);
        }
      ),
    price: yup
      .number()
      .typeError(Localize.get('ValidationMessages.PriceRequired'))
      .min(1, Localize.get('ValidationMessages.PositiveNumber'))
      .required(Localize.get('ValidationMessages.PriceRequired')),
    contingent: yup
      .number()
      .typeError(Localize.get('ValidationMessages.ContingentAmount'))
      .min(1, Localize.get('ValidationMessages.PositiveNumber'))
      .required(Localize.get('ValidationMessages.ContingentAmount')),
    accommodation: yup
      .object()
      .required(Localize.get('ValidationMessages.RoomTypeRequired'))
      .nullable()
  });
}
