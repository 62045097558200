import { FILTER_KEYS, FILTER_TYPES } from '@components/FilterDialog';

export const activitiesFilters = [
  {
    label: 'Labels.Subject',
    id: 'subject',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.Account',
    id: 'account',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.Status',
    id: 'status',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: [
      {
        label: 'Event.Statuses.Open',
        key: 'Open'
      },
      {
        label: 'Event.Statuses.InProgress',
        key: 'In Progress'
      },
      {
        label: 'Event.Statuses.Completed',
        key: 'Completed'
      }
    ]
  },
  {
    label: 'Labels.Priority',
    id: 'priority',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: [
      {
        label: 'Priority.Immediate',
        key: 'Immediate'
      },
      {
        label: 'Priority.Urgent',
        key: 'Urgent'
      },
      {
        label: 'Priority.Normal',
        key: 'Normal'
      },
      {
        label: 'Priority.Low',
        key: 'Low'
      }
    ]
  },
  {
    label: 'Labels.Type',
    id: 'type',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: [
      { label: 'Labels.Task', key: 'Labels.Task', translateKey: true },
      { label: 'Activities.Appointment', key: 'Activities.Appointment', translateKey: true }
    ]
  },
  {
    label: 'Labels.Owner',
    id: 'owner',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.Processor',
    id: 'processor',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.Completion',
    id: 'completion',
    type: FILTER_TYPES.number,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' }
  },
  {
    label: 'Labels.PrimaryContact',
    id: 'primaryContact',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.TaskCategories',
    id: 'taskCategory',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: []
  },
  {
    label: 'Labels.AppointmentCategories',
    id: 'appointmentCategory',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: []
  },
  {
    label: 'Labels.StartDate',
    id: 'startDate',
    type: FILTER_TYPES.date,
    operator: { key: FILTER_KEYS.IsOn, label: 'Tables.FilterValues.IsOn' }
  },
  {
    label: 'Labels.EndDate',
    id: 'endDate',
    type: FILTER_TYPES.date,
    operator: { key: FILTER_KEYS.IsOn, label: 'Tables.FilterValues.IsOn' }
  }
];
