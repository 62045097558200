import React, { useCallback, useEffect, useRef } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useSearch, useMatch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { ACTION_MODES, SORTING_ORDER, TAB_KEYS } from '@common/Constants';
import { replaceAll } from '@common/helpers/string';
import { onTabsTableFilterChange } from '@common/helpers/tables/getAdvancedFiltersForMUITable';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import { saveState } from '@common/storage/persistSlice';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import StyledMenu from '@components/StyledMenuItem/StyledMenu';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import ToolbarButton from '@components/ToolbarButton';
import {
  getFilterOperatorsBasedOnType,
  getFilterValueOptionsBasedOnType
} from '@config/filterOperators';
import { TABLE_OPTIONS } from '@config/network';
import { iltSessionsDetailsHotelPath } from '@navigation/routes/Routes';
import {
  selectList,
  fetchHotels,
  selectFilter,
  selectIsLoading,
  resetState,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  selectSelectionModel,
  setSelectionModel,
  deleteHotels,
  setExportPopupOpened,
  setExportPopupClosed,
  generateReport,
  selectExportAnchor,
  selectIsExportPopupOpen
} from '@pages/IltSession/components/Tabs/Hotel/hotelTableSlice';
import { selectFilter as selectIltSessionFilter } from '@pages/IltSession/iltSessionSlice';

import CreateHotelContingent from '../../Hotels/CreateHotelContingent/CreateHotelContingent';
import { exportTypesConfig } from '../ParticipantsTable/ParticipantsTable';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  content: {
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  container: {
    height: 450,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

const HotelContingentTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarButtons = []
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useSearch();
  const {
    data: {
      roomTypes: { data: roomTypes }
    }
  } = useMatch();
  const trackFilterModel = useRef(null);

  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const iltSessionFilter = useSelector(selectIltSessionFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);
  const exportAnchor = useSelector(selectExportAnchor);
  const isExportPopupOpen = useSelector(selectIsExportPopupOpen);

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(fetchHotels({ entityId: entityId, filter: filter }));
    }
  }, [
    entityId,
    filter?.size,
    filter?.page,
    filter?.sortBy,
    filter?.sortDirection,
    filter?.filters,
    totalElements
  ]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarButtonDisabled = (name) => {
    switch (name) {
      case 'add_sessions':
        return !entityId || !entityType;
      case 'export':
        return !entityId || !data?.length;
      case 'delete':
        return selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onToolbarButtonClick = (name, event) => {
    switch (name) {
      case 'add_sessions':
        dispatch(saveState({ filter: iltSessionFilter, selectedId: entityId }));
        navigate({
          search: (previousUrlParams) => ({ ...previousUrlParams, mode: ACTION_MODES.Create })
        });
        break;

      case 'export':
        dispatch(setExportPopupOpened(event.currentTarget));
        break;

      case 'delete': {
        dispatch(
          openConfirmDialog({
            title: Localize.get('IltSession.DeleteMsg'),
            confirmButton: Localize.get('Buttons.Delete'),
            cancelButton: Localize.get('Buttons.Cancel'),
            disabler: { confirm: isLoading, cancel: isLoading }
          })
        )
          .unwrap()
          .then((result) => {
            if (result === CONFIRM_ACTIONS.Confirm) {
              dispatch(deleteHotels({ contingentIds: selectionModel, id: entityId }));
            }
          });
        break;
      }

      default:
        break;
    }
  };

  const onRowClick = (row) => {
    const { id } = row;
    const replaceMap = { ':sessionId': entityId, ':id': id };
    const path = replaceAll(iltSessionsDetailsHotelPath, replaceMap);

    dispatch(saveState({ iltSessionFilter, selectedId: entityId }));
    navigate({ to: path, search: () => ({ activeTab: TAB_KEYS.OVERVIEW }) });
  };

  const handleClose = () => dispatch(setExportPopupClosed());

  const onMenuItemClick = (item) => dispatch(generateReport({ id: entityId, type: item.type }));

  const onFilterChange = (values) => {
    const advancedFilters = onTabsTableFilterChange(values, trackFilterModel, () =>
      dispatch(setFilterParams({ key: 'filters', value: null }))
    );
    if (advancedFilters) dispatch(setFilterParams({ key: 'filters', value: { advancedFilters } }));
  };

  const onSortChange = (sortModel) =>
    dispatch(
      setFilterParams([
        { key: 'sortBy', value: sortModel[0]?.field },
        { key: 'sortDirection', value: sortModel[0]?.sort }
      ])
    );

  const getSingleSelectValueOptions = useCallback((field) => {
    switch (field) {
      case 'roomType': {
        return roomTypes.map(({ value }) => value);
      }
      default:
        return [];
    }
  }, []);

  return (
    <div className={classes.root}>
      {search?.mode === ACTION_MODES.Create ? (
        <CreateHotelContingent />
      ) : (
        <TabContent>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('IltSession.HotelsContingentAndBookings')}
            </Typography>
            <Toolbar className={classes.toolbar}>
              {toolbarButtons?.map((button, index) => (
                <ToolbarButton
                  key={index}
                  button={button}
                  isDisabled={button.disabled || isToolbarButtonDisabled(button.name)}
                  onToolbarButtonClick={onToolbarButtonClick}
                />
              ))}
            </Toolbar>
          </div>
          <TabContainer>
            <StyledDataGrid
              keepNonExistentRowsSelected={true}
              loading={isLoading}
              rows={data}
              columns={[
                ...columns.map((column) => ({
                  ...column,
                  headerName: Localize.get(column.headerName),
                  filterOperators: getFilterOperatorsBasedOnType(column?.type),
                  valueOptions: getFilterValueOptionsBasedOnType(
                    column,
                    getSingleSelectValueOptions
                  ),
                  renderCell: (params) => (
                    <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                      {['description', 'roomType'].includes(params.field) ? (
                        <Tooltip title={params.value} arrow>
                          <Typography
                            noWrap
                            variant="body"
                            component="div"
                            sx={{
                              maxWidth: params?.colDef?.width - 10 // 10 px for three dots,
                            }}
                          >
                            {params.value}
                          </Typography>
                        </Tooltip>
                      ) : ['name'].includes(params.field) ? (
                        <Tooltip title={params.value} arrow>
                          <Link onClick={() => onRowClick(params)} sx={{ cursor: 'pointer' }}>
                            {params?.value}
                          </Link>
                        </Tooltip>
                      ) : (
                        <Typography variant="body" component="div">
                          {params.value}
                        </Typography>
                      )}
                    </div>
                  )
                }))
              ]}
              initialState={{
                pagination: {
                  pageSize: totalPages,
                  rowCount: totalElements,
                  page: filter?.page
                }
              }}
              onSelectionModelChange={(newSelectionModel) => {
                dispatch(setSelectionModel(newSelectionModel));
              }}
              selectionModel={selectionModel}
              checkboxSelection
              localeText={getLocalesText(Localize)}
              pagination
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              disableColumnMenu
              page={filter?.page}
              pageSize={filter?.size}
              rowCount={totalElements}
              rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
              components={{ Toolbar: () => <TableToolbar /> }}
              onPageChange={(page) =>
                dispatch(setFilterParams({ key: 'page', value: page >= 0 ? page : 0 }))
              }
              onPageSizeChange={(value) => dispatch(setFilterParams([{ key: 'size', value }]))}
              sortingOrder={SORTING_ORDER}
              onSortModelChange={onSortChange}
              onFilterModelChange={onFilterChange}
            />

            <StyledMenu anchorEl={exportAnchor} open={isExportPopupOpen} onClose={handleClose}>
              {exportTypesConfig.map((el) => (
                <MenuItem key={el.label} onClick={() => onMenuItemClick(el)}>
                  <ListItemIcon>
                    <Icon fontSize="small">{el.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText>{Localize.get(el.label)}</ListItemText>
                </MenuItem>
              ))}
            </StyledMenu>
          </TabContainer>
        </TabContent>
      )}
    </div>
  );
};

export default HotelContingentTable;
