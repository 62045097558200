import React, { useCallback, useEffect, useRef } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate, useSearch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles, styled } from '@mui/styles';

import { ACTION_MODES, FOCUS_FIELD_TIMEOUT, SORTING_ORDER } from '@common/Constants';
import { checkChangedFields } from '@common/helpers/helpers';
import { onTabsTableFilterChange } from '@common/helpers/tables/getAdvancedFiltersForMUITable';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import { openSidebar } from '@components/RightSidebar/rightSidebarSlice';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import ToolbarButton from '@components/ToolbarButton';
import {
  getFilterOperatorsBasedOnType,
  getFilterValueOptionsBasedOnType
} from '@config/filterOperators';
import { TABLE_OPTIONS } from '@config/network';
import {
  WEBINAR_TYPE_ID,
  selectDetails as selectIltSessionDetails
} from '@pages/IltSession/iltSessionSlice';
import { PARTICIPANT_STATUSES } from '@pages/IltSession/util/Constants';

import CreateWaitingList from './CreateWaitingList/CreateWaitingList';
import { participantSchema } from './participantSchema';
import { classroomSidebar, webinarSidebar } from './sidebarConfig';
import waitingListStatuses from './waitingListStatuses';
import {
  selectList,
  fetchWaitingLists,
  selectFilter,
  selectIsLoading,
  resetState,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  selectSelectionModel,
  setSelectionModel,
  updateStatus,
  deleteWaitingList,
  setPopupIsClose,
  setPopupIsOpen,
  selectAnchorEl,
  selectIsPopupOpen,
  setDetails,
  selectDetails,
  selectIsDetailsLoading,
  saveParticipant
} from './waitingListTableSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  }
}));

const StyledMenu = styled(Menu)(() => ({
  '& .MuiMenu-paper': {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    marginTop: '0.6rem'
  }
}));

const WaitingListTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarButtons = []
}) => {
  const {
    data: {
      participantStatuses: { data: statuses },
      cancelationReasons: { data: cancelationReasons }
    }
  } = useMatch();

  const trackFilterModel = useRef(null);

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useSearch();

  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);

  const iltSessionDetails = useSelector(selectIltSessionDetails);
  const details = useSelector(selectDetails);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);

  const anchorEl = useSelector(selectAnchorEl);
  const isPopupOpen = useSelector(selectIsPopupOpen);

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(fetchWaitingLists({ entityId: entityId, filter: filter }));
    }
  }, [
    entityId,
    filter?.page,
    filter?.sortBy,
    filter?.sortDirection,
    filter?.size,
    filter?.filters
  ]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarButtonDisabled = (name) => {
    switch (name) {
      case 'email_participant':
        return !entityId || !entityType || !selectionModel?.length;
      case 'add_participant':
        return !entityId || !entityType;
      case 'delete':
        return selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onToolbarButtonClick = (name, event) => {
    switch (name) {
      case 'email_participant':
        dispatch(setPopupIsOpen(event.currentTarget));
        return;
      case 'add_participant':
        navigate({
          search: (previousUrlParams) => ({ ...previousUrlParams, mode: ACTION_MODES.Create })
        });
        break;
      case 'delete':
        {
          dispatch(
            openConfirmDialog({
              title: Localize.get('ConfirmationMessages.Delete', {
                item: Localize.get('Events.SingularOrPluralWaitingList', {
                  selectionModel: selectionModel.length
                })?.toLowerCase()
              }),
              confirmButton: Localize.get('Buttons.Delete'),
              cancelButton: Localize.get('Buttons.Cancel')
            })
          )
            .unwrap()
            .then((result) => {
              if (result === CONFIRM_ACTIONS.Confirm) {
                dispatch(deleteWaitingList({ participantIds: selectionModel, id: entityId }));
              }
            });
        }
        return true;
      default:
        return false;
    }
  };

  const onEditMenuItemClick = (e, participantStatusId) => {
    dispatch(
      updateStatus({
        iltSessionId: entityId,
        participantIds: selectionModel,
        participantStatusId
      })
    );
    dispatch(setSelectionModel([]));
  };

  const handleClose = () => {
    dispatch(setPopupIsClose());
  };

  // Reserved - 3 and Waiting Cancelled - 8
  const visibleStatuses = statuses.filter((status) =>
    [PARTICIPANT_STATUSES.RESERVED, PARTICIPANT_STATUSES.WAITING_CANCELLED].includes(status.id)
  );

  const extendedConfig = (setFieldValue, values) => {
    return {
      ['status.value']: {
        value: values?.status.id,
        options: visibleStatuses,
        onChange: (event) => {
          setFieldValue('status', {
            id: event.target.value,
            value: visibleStatuses.find((s) => s.id === event.target.value).value
          });
        }
      },
      ['company']: {
        value: values?.company?.name ?? ''
      },
      ['hotelRequest']: {
        onChange: (e) => {
          setFieldValue('hotelRequest', e.target.checked);
          if (e.target.checked) {
            setTimeout(
              () => document.getElementsByName('arrivalDate')[0]?.focus(),
              FOCUS_FIELD_TIMEOUT
            );
          }
        }
      },
      ['arrivalDate']: {
        disabled: !values?.hotelRequest,
        required: values?.hotelRequest,
        onChange: (value) => setFieldValue('arrivalDate', value)
      },
      ['departureDate']: {
        disabled: !values?.hotelRequest,
        required: values?.hotelRequest,
        onChange: (value) => setFieldValue('departureDate', value)
      },
      ['cancellationReason']: {
        required: values?.status?.id === PARTICIPANT_STATUSES.WAITING_CANCELLED,
        isVisible: values?.status?.id === PARTICIPANT_STATUSES.WAITING_CANCELLED,
        options: cancelationReasons,
        value: values?.cancellationReason?.id ?? details?.cancellationReason?.id ?? '',
        onChange: (e) => {
          setFieldValue(
            'cancellationReason',
            cancelationReasons.find(({ id }) => id === e.target.value)
          );
        }
      },
      ['cancellationComment']: {
        isVisible: values?.status?.id === PARTICIPANT_STATUSES.WAITING_CANCELLED,
        value: values?.cancellationComment ?? ''
      }
    };
  };

  const isWebinarType = iltSessionDetails?.type?.id === WEBINAR_TYPE_ID;

  useEffect(() => {
    if (!details) {
      return;
    }

    dispatch(
      openSidebar({
        config: {
          title: Localize.get('ParticipantsTile.Details'),
          fieldsConfig: isWebinarType ? webinarSidebar : classroomSidebar
        },
        extendedConfig: extendedConfig,
        data: details,
        validationSchema: participantSchema,
        isDeleteDisabled: true,
        isDetailsLoading: isDetailsLoading,
        onSave: (values) => {
          return dispatch(
            saveParticipant({
              entityId,
              participantId: details?.id,
              data: checkChangedFields(details, values)
            })
          );
        },
        onDelete: () => null
      })
    );
  }, [details, isDetailsLoading]);

  const onRowClick = ({ row }) => dispatch(setDetails(row));

  const onFilterChange = (values) => {
    const advancedFilters = onTabsTableFilterChange(values, trackFilterModel, () =>
      dispatch(setFilterParams({ key: 'filters', value: null }))
    );
    if (advancedFilters) dispatch(setFilterParams({ key: 'filters', value: { advancedFilters } }));
  };

  const onSortChange = (sortModel) =>
    dispatch(
      setFilterParams([
        { key: 'sortBy', value: sortModel[0]?.field },
        { key: 'sortDirection', value: sortModel[0]?.sort }
      ])
    );

  const getSingleSelectValueOptions = useCallback((field) => {
    switch (field) {
      case 'status': {
        return visibleStatuses.map(({ value }) => value);
      }
      default:
        return [];
    }
  }, []);

  return (
    <div className={classes.root}>
      {search?.mode === ACTION_MODES.Create ? (
        <CreateWaitingList />
      ) : (
        <TabContent>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('Events.WaitingList')}
            </Typography>
            <Toolbar className={classes.toolbar}>
              {toolbarButtons?.map((button, index) => (
                <ToolbarButton
                  key={index}
                  button={button}
                  isDisabled={button.disabled || isToolbarButtonDisabled(button.name)}
                  onToolbarButtonClick={onToolbarButtonClick}
                />
              ))}
            </Toolbar>
          </div>
          <TabContainer>
            <StyledDataGrid
              keepNonExistentRowsSelected={true}
              loading={isLoading}
              rows={data}
              columns={columns.map((column) => ({
                ...column,
                headerName: Localize.get(column.headerName),
                filterOperators: getFilterOperatorsBasedOnType(column?.type),
                valueOptions: getFilterValueOptionsBasedOnType(column, getSingleSelectValueOptions),
                renderCell: (params) => (
                  <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                    {['name', 'company', 'hotel'].includes(params.field) ? (
                      <Tooltip title={params.value} arrow>
                        <Typography
                          noWrap
                          variant="body"
                          component="div"
                          sx={{
                            maxWidth: params?.colDef?.width - 10 // 10 px for three dots,
                          }}
                        >
                          {params.value}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography variant="body" component="div">
                        {params.value}
                      </Typography>
                    )}
                  </div>
                )
              }))}
              initialState={{
                pagination: {
                  pageSize: totalPages,
                  rowCount: totalElements,
                  page: filter?.page
                }
              }}
              onRowClick={onRowClick}
              onSelectionModelChange={(newSelectionModel) => {
                dispatch(setSelectionModel(newSelectionModel));
              }}
              selectionModel={selectionModel}
              checkboxSelection
              localeText={getLocalesText(Localize)}
              pagination
              paginationMode="server"
              disableColumnMenu
              page={filter?.page}
              pageSize={filter?.size}
              rowCount={totalElements}
              rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
              disableSelectionOnClick
              components={{ Toolbar: () => <TableToolbar /> }}
              onPageChange={(page) => dispatch(setFilterParams({ page, selectionModel }))}
              onPageSizeChange={(value) =>
                dispatch(setFilterParams([{ key: 'size', value, selectionModel }]))
              }
              sortingOrder={SORTING_ORDER}
              onSortModelChange={onSortChange}
              onFilterModelChange={onFilterChange}
            />

            <StyledMenu anchorEl={anchorEl} open={isPopupOpen} onClose={handleClose}>
              {waitingListStatuses.map((el) => (
                <MenuItem key={el.id} onClick={() => onEditMenuItemClick(el.label, el.id)}>
                  <ListItemIcon>
                    <Icon fontSize="small">{el.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText key={el.id}>{Localize.get(el.label)}</ListItemText>
                </MenuItem>
              ))}
            </StyledMenu>
          </TabContainer>
        </TabContent>
      )}
    </div>
  );
};

export default WaitingListTable;
