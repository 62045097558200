import { TAB_KEYS } from '@common/Constants';
import AttachmentsTable from '@components/AttachmentsTable/AttachmentsTable';
import NotesTable from '@components/NotesTable/NotesTable';

import EventsOverview from '../Overview/EventsOverview';
import HotelTable from './Hotel/HotelTable';
import ParticipantsTable from './ParticipantsTable/ParticipantsTable';
import PriceTable from './Price/PriceTable';
import SessionsTable from './SessionsTable/SessionsTable';
import TasksTable from './Tasks/TasksTable';

export const EVENTS_TABS_COMPONENT = {
  [TAB_KEYS.OVERVIEW]: EventsOverview,
  [TAB_KEYS.NOTE]: NotesTable,
  [TAB_KEYS.SESSION]: SessionsTable,
  [TAB_KEYS.ATTACHMENT]: AttachmentsTable,
  [TAB_KEYS.PARTICIPANTS]: ParticipantsTable,
  [TAB_KEYS.HOTEL]: HotelTable,
  [TAB_KEYS.PRICE]: PriceTable,
  [TAB_KEYS.TASK]: TasksTable
};

export default {
  activeIndex: 0,
  tabs: [
    {
      label: 'Labels.Overview',
      key: TAB_KEYS.OVERVIEW,
      icon: 'info',
      isCountVisible: false
    },
    {
      label: 'Labels.Sessions',
      key: TAB_KEYS.SESSION,
      icon: 'groups',
      toolbarButtons: [
        {
          name: 'add_sessions',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'timeBlockDate',
          headerName: 'Labels.Date',
          editable: false,
          width: 250,
          headerAlign: 'left'
        },
        {
          field: 'startTime',
          headerName: 'Labels.TimeFrom',
          editable: false,
          headerAlign: 'left',
          width: 150
        },
        {
          field: 'endTime',
          headerName: 'Labels.TimeTo',
          editable: false,
          headerAlign: 'left',
          width: 150
        }
      ]
    },
    {
      label: 'Launchpad.Hotels',
      key: TAB_KEYS.HOTEL,
      icon: 'night_shelter',
      toolbarButtons: [
        {
          name: 'add_sessions',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 100,
          maxWidth: 100,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'hotel',
          headerName: 'Labels.Name',
          width: 250,
          maxWidth: 250,
          editable: false,
          headerAlign: 'left',
          valueGetter: (params) => params?.row?.hotel?.name
        },
        {
          field: 'contingent',
          headerName: 'Labels.ContingentAmount',
          width: 200,
          maxWidth: 200,
          editable: false,
          headerAlign: 'left',
          valueGetter: (params) => params?.row?.contingent
        },
        {
          field: 'usedFreeBeds',
          headerName: 'Labels.UsedFree',
          editable: false,
          width: 200,
          maxWidth: 200,
          headerAlign: 'left',
          valueGetter: (params) => params?.row?.usedFreeBeds
        },
        {
          field: 'roomType',
          headerName: 'Hotel.RoomType',
          editable: false,
          headerAlign: 'left',
          width: 200,
          maxWidth: 200,
          valueGetter: (params) => params?.row?.accommodation?.roomType?.value
        },
        {
          field: 'price',
          headerName: 'Labels.PricePerNight',
          editable: false,
          headerAlign: 'left',
          width: 200,
          maxWidth: 200,
          valueGetter: (params) => `${params?.row?.price} ${params?.row?.currency?.value}`
        },
        {
          field: 'startDate',
          headerName: 'Hotel.ArrivalDate',
          editable: false,
          headerAlign: 'left',
          width: 200,
          maxWidth: 200
        },
        {
          field: 'endDate',
          headerName: 'Hotel.DepartureDate',
          editable: false,
          headerAlign: 'left',
          width: 200,
          maxWidth: 200
        },
        {
          field: 'validUntil',
          headerName: 'Labels.ValidUntil',
          editable: false,
          headerAlign: 'left',
          width: 200,
          maxWidth: 200
        },
        {
          field: 'description',
          headerName: 'Labels.Description',
          editable: false,
          headerAlign: 'left',
          width: 250,
          maxWidth: 250,
          valueGetter: (params) => params?.row?.accommodation?.description
        }
      ]
    },
    {
      label: 'Labels.Prices',
      key: TAB_KEYS.PRICE,
      icon: 'local_offer',
      toolbarButtons: [
        {
          name: 'add_price',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'typeId',
          headerName: 'Labels.PriceType',
          width: 250,
          editable: false,
          headerAlign: 'left',
          required: true,
          valueGetter: (params) => params?.row?.type?.value
        },
        {
          field: 'price',
          headerName: 'ResourcesTile.Price',
          width: 250,
          editable: false,
          headerAlign: 'left',
          required: true
        },
        {
          field: 'currencyId',
          headerName: 'ResourcesTile.Currency',
          width: 250,
          editable: false,
          headerAlign: 'left',
          required: true,
          valueGetter: (params) => params?.row?.currency?.value
        },
        {
          field: 'validFrom',
          headerName: 'Labels.ValidFrom',
          editable: false,
          width: 280,
          required: true
        },
        {
          field: 'validTo',
          headerName: 'Labels.ValidTo',
          editable: false,
          headerAlign: 'left',
          width: 280,
          required: true
        }
      ]
    },
    {
      label: 'Labels.Tasks',
      key: TAB_KEYS.TASK,
      icon: 'assignment',
      toolbarButtons: [
        {
          name: 'add_tasks',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          editable: false,
          headerAlign: 'left',
          required: true
        },
        {
          field: 'subject',
          headerName: 'Labels.Subject',
          editable: false,
          headerAlign: 'left',
          required: true
        },
        {
          field: 'processor',
          headerName: 'Labels.Processor',
          editable: false,
          headerAlign: 'left',
          required: true,
          valueGetter: ({ row }) =>
            row?.taskDto?.processor
              ? row?.taskDto?.processor?.firstName.concat(' ', row?.taskDto?.processor?.lastName)
              : ''
        },
        {
          field: 'status',
          headerName: 'Labels.Status',
          editable: false,
          required: true,
          valueGetter: ({ row }) => row?.status?.value
        },
        {
          field: 'completion',
          headerName: 'Labels.Completion',
          editable: false,
          required: true,
          valueGetter: ({ row }) => row?.taskDto?.completion
        },
        {
          field: 'priority',
          headerName: 'Labels.Priority',
          editable: false,
          required: true,
          valueGetter: ({ row }) => row?.priority?.value
        },
        {
          field: 'startDateTime',
          headerName: 'Labels.StartDate',
          width: 170,
          editable: false,
          required: true
        },
        {
          field: 'endDateTime',
          headerName: 'Labels.EndDate',
          width: 170,
          editable: false,
          headerAlign: 'left',
          required: true
        },
        {
          field: 'taskCategory',
          headerName: 'Labels.Category',
          editable: false,
          headerAlign: 'left',
          required: true,
          valueGetter: ({ row }) => row?.taskDto?.taskCategory?.value
        },
        {
          field: 'account',
          headerName: 'Labels.Account',
          width: 180,
          editable: false,
          headerAlign: 'left',
          required: true,
          valueGetter: ({ row }) => row?.account?.name
        },
        {
          field: 'primaryContact',
          headerName: 'Labels.PrimaryContact',
          width: 180,
          editable: false,
          headerAlign: 'left',
          required: true,
          valueGetter: ({ row }) =>
            row?.contact ? row?.contact?.firstName.concat(' ', row?.contact?.lastName) : ''
        }
      ]
    },
    {
      label: 'Labels.Participants',
      key: TAB_KEYS.PARTICIPANTS,
      icon: 'accessibility',
      toolbarButtons: [
        {
          name: 'email_participant',
          disabled: false,
          icon: 'mail'
        },
        {
          name: 'add_participant',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Participants.ParticipantID',
          width: 150,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'firstName',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left',
          valueGetter: (params) => `${params?.row?.firstName} ${params?.row?.lastName}`
        },
        {
          field: 'status',
          headerName: 'Labels.Status',
          editable: false,
          headerAlign: 'left',
          width: 200
        },
        {
          field: 'emails',
          headerName: 'Launchpad.Emails',
          editable: false,
          headerAlign: 'left',
          width: 200
        }
      ]
    },
    {
      label: 'Labels.Attachments',
      key: TAB_KEYS.ATTACHMENT,
      icon: 'attachments',
      toolbarButtons: [
        {
          name: 'add',
          disabled: false,
          icon: 'add',
          type: 'upload'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left',
          filterable: true
        },
        {
          field: 'size',
          headerName: 'Labels.Size',
          width: 250,
          editable: false,
          defaultValue: '0 KB',
          headerAlign: 'left',
          filterable: true
        },
        {
          field: 'createdBy',
          headerName: 'Labels.CreatedBy',
          width: 250,
          editable: false,
          headerAlign: 'left',
          filterable: true,
          valueGetter: (params) => params?.value?.name || 'Unknown User'
        }
      ]
    },
    {
      label: 'Labels.Notes',
      key: TAB_KEYS.NOTE,
      icon: 'note_alt',
      toolbarButtons: [
        {
          name: 'add_note',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'type.value',
          headerName: 'Labels.Type',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'text',
          headerName: 'Labels.Text',
          width: 250,
          editable: false,
          headerAlign: 'left'
        }
      ]
    }
  ]
};
