import React from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useMatch } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import { Formik } from 'formik';
import { omit } from 'lodash';

import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';
import Button from '@mui/material/Button';

import { TAB_KEYS } from '@common/Constants';
import CreateForm from '@components/CreateForm';
import InlineCreateHeaderContainer from '@components/InlineCreateHeaderContainer/InlineCreateHeaderContainer';
import { selectDetails } from '@pages/IltSession/iltSessionSlice';

import { createIltHotelContingent } from '../../Tabs/Hotel/hotelTableSlice';
import getFieldsConfig from './getFieldsConfig';
import initialValues from './initialValues';
import useCreateSchema from './useCreateSchema';

const CreateHotelContingent = () => {
  const {
    data: {
      currency: { data: currency }
    }
  } = useMatch();
  const details = useSelector(selectDetails);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const hotelContingentSchema = useCreateSchema();

  const onSubmit = (values, { setSubmitting }) => {
    dispatch(createIltHotelContingent({ data: values, sessionId: details?.id }))
      .unwrap()
      .then(() => navigate({ search: { activeTab: TAB_KEYS.HOTEL }, replace: false }))
      .catch((rejectedValueOrSerializedError) => console.error(rejectedValueOrSerializedError))
      .finally(() => setSubmitting(false));
  };

  return (
    <Formik
      validateOnMount
      enableReinitialize
      validationSchema={hotelContingentSchema}
      onSubmit={onSubmit}
      initialValues={initialValues}
    >
      {({ isSubmitting, handleSubmit, isValid, setFieldValue, values, setValues }) => (
        <>
          <InlineCreateHeaderContainer>
            <Button
              data-test-id="cancel-btn"
              variant="outlined"
              onClick={() =>
                navigate({
                  search: (previousUrlParams) => ({ ...omit(previousUrlParams, 'mode') })
                })
              }
              color="error"
              sx={{ mr: 1 }}
              startIcon={<CancelIcon />}
            >
              {Localize.get('Buttons.Cancel')}
            </Button>

            <Button
              data-test-id="save-btn"
              variant="outlined"
              disabled={isSubmitting || !isValid}
              onClick={handleSubmit}
              startIcon={<SaveIcon />}
            >
              {Localize.get('Buttons.Save')}
            </Button>
          </InlineCreateHeaderContainer>

          <CreateForm
            values={values}
            fieldsConfig={getFieldsConfig(setFieldValue, setValues, values, currency)}
          />
        </>
      )}
    </Formik>
  );
};

export default CreateHotelContingent;
