import Localize from 'react-intl-universal';

import { isNil, omit } from 'lodash';
import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ACTION_MODES, SORT_DIRECTION, TAB_KEYS } from '@common/Constants';
import { deepMerge } from '@common/helpers/deepMerge';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { EXPORTS_PATHS, ILT_SESSION_PATHS } from '@common/network/ApiPaths';
import ClassNamesForExport from '@common/network/ClassNamesForExport';
import EntityTypes from '@common/network/EntityTypes';
import { closeSidebar } from '@components/RightSidebar/rightSidebarSlice';
import { SnackbarSeverityTypes, showSnackbar } from '@components/Snackbar/snackbarSlice';
import { TABLE_OPTIONS } from '@config/network';
import { setDetails as setIltSessionDetails } from '@pages/IltSession/iltSessionSlice';
import { deleteByPath, getByPathAndParams, patchByPathAndData } from '@services/BaseApi';

export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0],
    sortBy: '',
    sortDirection: SORT_DIRECTION.ASCENDING,
    filters: null
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: [],
  anchor: null,
  exportAnchor: null,
  isExportPopupOpen: false,
  details: null,
  isDetailsLoading: false
};

export const REGISTERED_TYPE_ID = 2;

export const ILT_SESSION_PARTICIPANT_SLICE = 'iltSessionParticipants';

export const fetchParticipants = createAsyncThunk(
  `${ILT_SESSION_PARTICIPANT_SLICE}/participants`,
  (filterObj) => {
    return getByPathAndParams({
      path: ILT_SESSION_PATHS.GET_PARTICIPANTS,
      pathVariables: { id: filterObj.entityId },
      params: filterObj.filter
    })
      .then((response) => response.data)
      .catch((error) => error);
  }
);

export const deleteParticipant = createAsyncThunk(
  `${ILT_SESSION_PARTICIPANT_SLICE}/delete`,
  ({ participantIds, id }, { dispatch, rejectWithValue, getState }) => {
    return deleteByPath({
      path: ILT_SESSION_PATHS.DELETE_PARTICIPANTS,
      pathVariables: { id, participantIds }
    })
      .then(async (response) => {
        const { totalElements = 0 } = await dispatch(
          fetchParticipants({
            entityId: id,
            filter: getState().ILT_SESSION_PARTICIPANT_SLICE.filter
          })
        ).unwrap();
        dispatch(setIltSessionDetails({ counts: { [TAB_KEYS.PARTICIPANTS]: totalElements } }));
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.PARTICIPANT, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );

        return { response, participantIds };
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.PARTICIPANT, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error.response);
      });
  }
);

export const saveParticipant = createAsyncThunk(
  `${ILT_SESSION_PARTICIPANT_SLICE}/saveParticipants`,
  ({ entityId, participantId, data }, { dispatch, rejectWithValue, getState }) => {
    const { arrivalDate, departureDate } = getState().ILT_SESSION_PARTICIPANT_SLICE.details;

    if (data?.currency?.id) {
      data.currencyId = data?.currency?.id;
    }

    if (data?.status?.id) {
      data.statusId = data?.status?.id;
    }

    if ((data.arrivalDate || data.departureDate) && data.hotelRequest !== false) {
      data.hotelRequest = true;
      data.arrivalDate = data.arrivalDate
        ? moment(data.arrivalDate).format(moment.HTML5_FMT.DATE)
        : moment(arrivalDate).format(moment.HTML5_FMT.DATE);
      data.departureDate = data.departureDate
        ? moment(data.departureDate).format(moment.HTML5_FMT.DATE)
        : moment(departureDate).format(moment.HTML5_FMT.DATE);
    } else if (data.hotelRequest === false) {
      data.arrivalDate = null;
      data.departureDate = null;
    }

    if (data?.status?.id) {
      data.statusId = data?.status?.id;
    }

    // statusID for cancellation process === 5
    if (data?.cancellationReason && (data?.statusId === 5 || !data?.statusId)) {
      data.cancellationReasonId = data?.cancellationReason.id;
    }

    return patchByPathAndData({
      path: ILT_SESSION_PATHS.PATCH_PARTICIPANT,
      pathVariables: { id: entityId, participantId },
      data: { eventParticipant: { ...omit(data, 'status', 'cancellationReason', 'currency') } }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.PARTICIPANT, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );

        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.PARTICIPANT, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error);
      });
  }
);

export const generateReport = createAsyncThunk(
  `${ILT_SESSION_PARTICIPANT_SLICE}/export`,
  ({ id, type }, { rejectWithValue, dispatch }) => {
    return getByPathAndParams({
      path: EXPORTS_PATHS.GET.replace(':className', ClassNamesForExport.ILT_SESSION_PARTICIPANT)
    }).then(({ data: fieldsToExport = [] }) => {
      return getByPathAndParams({
        path: ILT_SESSION_PATHS.EXPORT_PARTICIPANTS,
        pathVariables: { iltSessionId: id, fileType: type, fieldsToExport },
        responseType: 'blob'
      })
        .then((response) => {
          dispatch(setExportPopupClosed());
          dispatch(
            showSnackbar({
              message: Localize.get('SuccessMessage.Download', {
                entity: Localize.get('ParticipantsTile.List')
              }),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
          return response;
        })
        .catch((error) => {
          dispatch(
            showSnackbar({
              message: Localize.get('InitializeError.Download', {
                entity: Localize.get('ParticipantsTile.List')
              }),
              severity: SnackbarSeverityTypes.ERROR
            })
          );
          return rejectWithValue(error);
        });
    });
  }
);

export const iltSessionParticipantTableSlice = createSlice({
  name: ILT_SESSION_PARTICIPANT_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setAnchor: (state, { payload }) => {
      state.anchor = payload;
    },
    setExportPopupOpened: (state, { payload }) => {
      state.exportAnchor = payload;
      state.isExportPopupOpen = true;
    },
    setExportPopupClosed: (state) => {
      state.exportAnchor = null;
      state.isExportPopupOpen = false;
    },
    setDetails: (state, { payload }) => {
      state.details = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};

      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }

      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }

      state.filter = { ...state.filter, ...newFilterValues, page: newFilterValues.page ?? 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchParticipants.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.data =
          payload?.content?.map(({ sessionParticipants, iltSessionParticipantResponseDto }) => ({
            ...iltSessionParticipantResponseDto,
            departureDate: iltSessionParticipantResponseDto?.departureDate
              ? moment(iltSessionParticipantResponseDto?.departureDate).toDate()
              : null,
            arrivalDate: iltSessionParticipantResponseDto?.arrivalDate
              ? moment(iltSessionParticipantResponseDto?.arrivalDate).toDate()
              : null,
            sessions: sessionParticipants
          })) ?? [];
      })
      .addCase(fetchParticipants.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchParticipants.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      .addCase(generateReport.fulfilled, (state, { payload }) => {
        let [, filename] = payload.headers['content-disposition'].split('filename=');
        filename = filename.replaceAll('"', '');

        const link = document.createElement('a');
        document.body.appendChild(link);
        link.style = 'display: none';
        link.setAttribute('download', filename);
        const blob = new Blob([payload.data]);
        const url = window.URL.createObjectURL(blob);
        link.href = url;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .addCase(saveParticipant.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(saveParticipant.fulfilled, (state, action) => {
        const {
          payload: { iltSessionParticipantResponseDto }
        } = action;
        const isPriceAdded = iltSessionParticipantResponseDto?.currency;
        let priceData = {};

        // Status changed from cancelled to registered, and BE returned price with currency so
        //  we need to update details with new standard price, advertisingSubsidyPrice, and currency
        if (isPriceAdded) {
          priceData = {
            currency: iltSessionParticipantResponseDto?.currency,
            standardPrice: iltSessionParticipantResponseDto?.standardPrice,
            advertisingSubsidyPrice: iltSessionParticipantResponseDto?.advertisingSubsidyPrice
          };
        }

        state.details = deepMerge(
          isPriceAdded ? { ...state.details, ...priceData } : state.details,
          action.meta.arg.data
        );

        state.isDetailsLoading = false;
        state.data = state.data.map((item) => {
          return item.id === action.meta.arg.participantId
            ? deepMerge(isPriceAdded ? { ...item, ...priceData } : item, action.meta.arg.data)
            : item;
        });
      })
      .addCase(saveParticipant.rejected, (state) => {
        state.isDetailsLoading = false;
      })
      .addCase(deleteParticipant.fulfilled, (state) => {
        state.selectionModel = [];
      })
      .addCase(closeSidebar, (state) => {
        state.details = null;
      });
  }
});

export const selectList = (state) => state.ILT_SESSION_PARTICIPANT_SLICE.data;
export const selectRows = (state) => state.ILT_SESSION_PARTICIPANT_SLICE.rows;
export const selectTotalElements = (state) => state.ILT_SESSION_PARTICIPANT_SLICE.totalElements;
export const selectTotalPages = (state) => state.ILT_SESSION_PARTICIPANT_SLICE.totalPages;
export const selectFilter = (state) => state.ILT_SESSION_PARTICIPANT_SLICE.filter;
export const selectIsLoading = (state) => state.ILT_SESSION_PARTICIPANT_SLICE.isLoading;
export const selectSelectionModel = (state) => state.ILT_SESSION_PARTICIPANT_SLICE.selectionModel;
export const selectExportAnchor = (state) => state.ILT_SESSION_PARTICIPANT_SLICE.exportAnchor;
export const selectDetails = (state) => state.ILT_SESSION_PARTICIPANT_SLICE.details;
export const selectIsDetailsLoading = (state) =>
  state.ILT_SESSION_PARTICIPANT_SLICE.isDetailsLoading;
export const selectIsExportPopupOpen = (state) =>
  state.ILT_SESSION_PARTICIPANT_SLICE.isExportPopupOpen;

const { actions, reducer } = iltSessionParticipantTableSlice;

export const {
  setData,
  setFilterParams,
  resetState,
  setSelectionModel,
  setExportPopupOpened,
  setExportPopupClosed,
  setDetails
} = actions;

export default reducer;
