const sidebarConfig = [
  {
    groupName: 'IltSession.DisplayName',
    fields: [
      {
        label: 'IltSession.DisplayName',
        name: 'displayName',
        required: true,
        disabled: false,
        component: 'text',
        column: 8
      }
    ]
  },
  {
    groupName: 'IltSession.VisibilityAccessSettings',
    fields: [
      {
        label: 'IltSession.Administrator',
        name: 'visibleToAdministrators',
        value: true,
        required: true,
        disabled: true,
        component: 'switch',
        column: 8
      },
      {
        label: 'IltSession.TrainersAccess',
        name: 'visibleToTrainers',
        value: false,
        required: false,
        disabled: false,
        component: 'switch',
        column: 8
      },
      {
        label: 'IltSession.ParticipantsAccess',
        name: 'visibleToParticipants',
        value: false,
        required: false,
        disabled: false,
        component: 'switch',
        column: 8
      },
      {
        label: 'IltSession.LocationResponsible',
        name: 'visibleToLocationResponsible',
        value: false,
        required: false,
        disabled: false,
        component: 'switch',
        column: 8
      }
    ]
  },
  {
    groupName: 'IltSession.File',
    fields: [
      {
        label: 'IltSession.File',
        name: 'file',
        required: true,
        disabled: true,
        component: 'filePicker',
        column: 8
      }
    ]
  }
];

export default sidebarConfig;
