import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ACTION_MODES, TAB_KEYS } from '@common/Constants';
import { successMessageFormatter } from '@common/helpers/MessageFormatter';
import { TAB_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { closeConfirmDialog } from '@components/ConfirmDialog/confirmDialogSlice';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { getByPathAndParams, postByPathAndData, deleteByPath } from '@services/BaseApi';

const formatTime = (content) =>
  content.map((el) => ({
    ...el,
    session: {
      ...el?.session,
      timeBlockDate: moment(el?.session?.timeBlockDate.join('-')).format(moment.HTML5_FMT.DATE)
    }
  }));

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  selectionModel: [],
  selectionDialogModel: [],
  isSearchDialogOpen: false,
  filter: {},
  totalPages: 0,
  totalElements: 0
};

const PARTICIPANTS_SESSIONS_SLICE = 'participantsSessions';

export const getSessions = createAsyncThunk(
  `${PARTICIPANTS_SESSIONS_SLICE}/fetchAll`,
  ({ entityId, entityType }, { rejectWithValue }) => {
    return getByPathAndParams({
      path: TAB_PATHS.SESSIONS.GET_DETAILS,
      pathVariables: { entityType, entityId }
    })
      .then(({ data }) => data)
      .catch((error) => rejectWithValue(error.response));
  },
  {
    condition: (entityId) => Boolean(entityId),
    dispatchConditionRejection: true
  }
);

export const updateSessions = createAsyncThunk(
  `${PARTICIPANTS_SESSIONS_SLICE}/updateOne`,
  ({ postData, entityId, setDetails }, { rejectWithValue, dispatch }) => {
    return postByPathAndData({
      path: TAB_PATHS.SESSIONS.POST_PARTICIPANTS,
      pathVariables: { entityId, postData }
    })
      .then(({ data: { content, count } }) => {
        dispatch(setDetails({ counts: { [TAB_KEYS.SESSION]: count } }));
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.SESSION, ACTION_MODES.Add),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return formatTime(content);
      })
      .catch((error) => rejectWithValue(error.response));
  },
  {
    condition: (entityId) => Boolean(entityId),
    dispatchConditionRejection: true
  }
);

export const removeSessions = createAsyncThunk(
  `${PARTICIPANTS_SESSIONS_SLICE}/deleteOne`,
  ({ participantId, sessionIds, setDetails }, { rejectWithValue, dispatch }) => {
    return deleteByPath({
      path: TAB_PATHS.SESSIONS.DELETE_PARTICIPANTS,
      pathVariables: { participantId, sessionIds }
    })
      .then(({ data: { count } }) => {
        dispatch(closeConfirmDialog());
        dispatch(setDetails({ counts: { [TAB_KEYS.SESSION]: count } }));
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.SESSION, ACTION_MODES.Removed),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return sessionIds;
      })
      .catch((error) => rejectWithValue(error.response));
  },
  {
    condition: (participantId) => Boolean(participantId),
    dispatchConditionRejection: true
  }
);

const participantsSessionsSlice = createSlice({
  name: PARTICIPANTS_SESSIONS_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setSelectionDialogModel: (state, { payload }) => {
      let selectionDialogModel = [];

      if (Array.isArray(payload)) {
        selectionDialogModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          selectionDialogModel = [...state.selectionModel, payload];
        } else {
          selectionDialogModel = [
            ...state.selectionDialogModel.slice(0, index),
            ...state.selectionDialogModel.slice(index + 1)
          ];
        }
      }
      state.selectionDialogModel = selectionDialogModel;
    },
    setSelectionModel: (state, { payload }) => {
      state.selectionModel = payload;
    },
    openSessionsDialog: (state) => {
      state.isSearchDialogOpen = true;
    },
    closeSessionsDialog: (state) => {
      state.isSearchDialogOpen = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSessions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSessions.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload?.content;
        state.filter = {
          ...state.filter,
          page: payload.pageable?.pageNumber,
          size: payload.pageable?.pageSize
        };
        state.totalPages = payload.totalPages;
        state.totalElements = payload.totalElements;
        state.selectionModel = [];
      })
      .addCase(getSessions.rejected, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
        state.data = [];
      })
      .addCase(updateSessions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSessions.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSearchDialogOpen = false;
        state.data = [...state.data, ...payload];
      })
      .addCase(updateSessions.rejected, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
        state.data = [];
      })
      .addCase(removeSessions.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeSessions.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = [...state.data.filter((item) => !payload.includes(item.session.id))];
        state.selectionModel = [];
      })
      .addCase(removeSessions.rejected, (state, { payload }) => {
        state.error = payload;
        state.isLoading = false;
        state.data = [];
      });
  }
});

export const selectIsLoading = (state) => state.PARTICIPANTS_SESSIONS_SLICE.isLoading;
export const selectData = (state) => state.PARTICIPANTS_SESSIONS_SLICE.data;
export const selectError = (state) => state.PARTICIPANTS_SESSIONS_SLICE.error;
export const selectSelectionModel = (state) => state.PARTICIPANTS_SESSIONS_SLICE.selectionModel;
export const selectSelectionDialogModel = (state) =>
  state.PARTICIPANTS_SESSIONS_SLICE.selectionDialogModel;
export const selectIsSearchDialogOpen = (state) =>
  state.PARTICIPANTS_SESSIONS_SLICE.isSearchDialogOpen;
export const selectFilter = (state) => state.PARTICIPANTS_SESSIONS_SLICE.filter;
export const selectTotalPages = (state) => state.PARTICIPANTS_SESSIONS_SLICE.totalPages;
export const selectTotalElements = (state) => state.PARTICIPANTS_SESSIONS_SLICE.totalElements;

const { actions, reducer } = participantsSessionsSlice;

export const {
  resetState,
  setSelectionDialogModel,
  setSelectionModel,
  openSessionsDialog,
  closeSessionsDialog
} = actions;

export default reducer;
