import { useRef } from 'react';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import { styled } from '@mui/material/styles';

const Input = styled('input')({
  display: 'none'
});

const ToolbarButton = ({
  button = {},
  index = 0,
  isDisabled = false,
  onToolbarButtonClick = null
}) => {
  const uploadRef = useRef();

  const components = {
    ['upload']: (
      <label key={index} htmlFor="icon-button-file">
        <Input
          ref={uploadRef}
          disabled={isDisabled}
          onChange={(e) => {
            onToolbarButtonClick && onToolbarButtonClick(button.name, e.target.files);

            // Resets input value so onChange can retrigger when adding same attachment.
            if (uploadRef.current) {
              uploadRef.current.value = null;
            }
          }}
          accept="image/*"
          id="icon-button-file"
          type="file"
        />
        <Button
          data-test-id="upload"
          sx={{ mr: 1 }}
          variant="outlined"
          aria-label="upload picture"
          disabled={isDisabled}
          component="span"
        >
          <Icon>{button?.icon ?? 'add'}</Icon>
        </Button>
      </label>
    ),
    ['generic']: (
      <Button
        data-test-id={button?.name ?? 'generic-test-id'}
        sx={{ mr: 1 }}
        variant={button?.variant ?? 'outlined'}
        color={button?.name === 'delete' ? 'error' : 'info'}
        aria-label={button?.name}
        key={index}
        disabled={isDisabled}
        onClick={(event) => onToolbarButtonClick && onToolbarButtonClick(button.name, event)}
      >
        <Icon>{button?.icon ?? 'add'}</Icon>
      </Button>
    )
  };

  return components[button?.type ?? 'generic'] || components['default'];
};

export default ToolbarButton;
