import { TAB_KEYS } from '@common/Constants';
import AttachmentsTable from '@components/AttachmentsTable';
import NotesTable from '@components/NotesTable';

import EmployeesOverview from '../Overview/EmployeesOverview';
import ParticipationTable from './ParticipationTable/ParticipationTable';

export const EMPLOYEES_TABS_COMPONENTS = {
  [TAB_KEYS.OVERVIEW]: EmployeesOverview,
  [TAB_KEYS.NOTE]: NotesTable,
  [TAB_KEYS.ATTACHMENT]: AttachmentsTable,
  [TAB_KEYS.PARTICIPATION]: ParticipationTable
};

export default {
  activeIndex: 0,
  tabs: [
    {
      label: 'Labels.Overview',
      key: TAB_KEYS.OVERVIEW,
      icon: 'info',
      isCountVisible: false
    },
    {
      label: 'Labels.Participations',
      key: TAB_KEYS.PARTICIPATION,
      icon: 'event',
      columns: [
        {
          field: 'name',
          headerName: 'IltSession.Name',
          width: 200,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'startDate',
          headerName: 'Events.StartDate',
          width: 200,
          editable: false,
          headerAlign: 'left',
          type: 'date'
        },
        {
          field: 'endDate',
          headerName: 'Events.EndDate',
          width: 200,
          editable: true,
          headerAlign: 'left',
          type: 'date'
        },
        {
          field: 'status',
          headerName: 'IltSession.Status',
          width: 200,
          editable: false,
          defaultValue: '',
          headerAlign: 'left',
          type: 'singleSelect',
          valueGetter: ({ row }) => {
            return row?.status?.value;
          }
        },
        {
          field: 'location',
          headerName: 'Labels.Location',
          width: 200,
          editable: false,
          defaultValue: '',
          headerAlign: 'left'
        },
        {
          field: 'type',
          headerName: 'IltSession.Type',
          width: 200,
          editable: false,
          defaultValue: '',
          type: 'singleSelect',
          headerAlign: 'left'
        }
      ]
    },
    {
      label: 'Labels.Attachments',
      key: TAB_KEYS.ATTACHMENT,
      icon: 'attachments',
      toolbarButtons: [
        {
          name: 'add',
          disabled: false,
          icon: 'add',
          type: 'upload'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left',
          filterable: true
        },
        {
          field: 'size',
          headerName: 'Labels.Size',
          width: 250,
          editable: false,
          defaultValue: '0 KB',
          headerAlign: 'left',
          filterable: false
        },
        {
          field: 'createdBy',
          headerName: 'Labels.CreatedBy',
          width: 250,
          editable: false,
          headerAlign: 'left',
          filterable: true,
          valueGetter: (params) => params?.value?.name || 'Unknown User'
        }
      ]
    },
    {
      label: 'Labels.Notes',
      key: TAB_KEYS.NOTE,
      icon: 'note_alt',
      toolbarButtons: [
        {
          name: 'add_note',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'type',
          headerName: 'Labels.Type',
          width: 250,
          editable: false,
          headerAlign: 'left',
          type: 'singleSelect'
        },
        {
          field: 'text',
          headerName: 'Labels.Text',
          width: 250,
          editable: false,
          headerAlign: 'left',
          filterable: false,
          sortable: false
        }
      ]
    }
  ]
};
