import { FILTER_KEYS, FILTER_TYPES } from '@components/FilterDialog';

export const locationsFilters = [
  {
    label: 'IltSession.Name',
    id: 'name',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.Role',
    id: 'role',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: [
      {
        label: 'Labels.Supplier',
        key: 'Labels.Supplier',
        translateKey: true
      }
    ]
  },
  {
    label: 'Labels.LocationType',
    id: 'type',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: []
  },
  {
    label: 'Labels.Owner',
    id: 'owner',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.ParentalAccount',
    id: 'parentalAccount',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.VAT',
    id: 'vatNumber',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.AbcClassification',
    id: 'abcClassification',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: []
  },
  {
    label: 'HotelFacilities.ShuttleService',
    id: 'hasShuttleService',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  },
  {
    label: 'HotelFacilities.Bar',
    id: 'hasBar',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  },
  {
    label: 'HotelFacilities.Parking',
    id: 'hasParking',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  },
  {
    label: 'HotelFacilities.AirportShuttle',
    id: 'hasAirportShuttle',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  },
  {
    label: 'HotelFacilities.WellnessCenter',
    id: 'hasWellness',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  },
  {
    label: 'HotelFacilities.FitnessCenter',
    id: 'hasFitnessCenter',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  },
  {
    label: 'HotelFacilities.PetsAllowed',
    id: 'petsAllowed',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  },
  {
    label: 'HotelFacilities.SPA',
    id: 'hasSpa',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  },
  {
    label: 'HotelFacilities.FreeWiFi',
    id: 'hasFreeWifi',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  },
  {
    label: 'HotelFacilities.Restaurant',
    id: 'hasRestaurant',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  },
  {
    label: 'Labels.Country',
    id: 'country',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: []
  }
];
