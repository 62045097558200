import Localize from 'react-intl-universal';

import moment from 'moment';
import * as yup from 'yup';

let patternTwoDigitsAfterComma = /^\d+(\.\d{0,2})?$/;

export default () => {
  return yup.object().shape({
    hotel: yup.object().shape({
      id: yup.number().nullable(true).required(Localize.get('ValidationMessages.Hotel'))
    }),
    accommodation: yup.object().shape({
      id: yup.number().nullable(true).required(Localize.get('ValidationMessages.RoomRequired'))
    }),
    contingent: yup.string().required(Localize.get('ValidationMessages.ContingentAmount')),
    price: yup
      .number()
      .required(Localize.get('ValidationMessages.PricePpNight'))
      .min(0, Localize.get('ValidationMessages.PositiveNumber'))
      .test('is-decimal', Localize.get('ValidationMessages.DecimalAllowed'), (val) => {
        if (val != undefined) {
          return patternTwoDigitsAfterComma.test(val);
        }
        return true;
      }),
    startDate: yup
      .date()
      .typeError(Localize.get('ValidationMessages.DateTimeInvalid'))
      .required(Localize.get('ValidationMessages.StartDate')),
    endDate: yup
      .date()
      .typeError(Localize.get('ValidationMessages.DateTimeInvalid'))
      .required(Localize.get('ValidationMessages.EndDate'))
      .test(
        'is-after-start-one-day',
        Localize.get('ValidationMessages.EndDateStartDate'),
        function (value, e) {
          const endDate = moment(value).toDate();
          const startDate = moment(e?.parent?.startDate).toDate();
          return moment(endDate).isAfter(startDate);
        }
      ),
    validUntil: yup
      .date()
      .max(yup.ref('startDate'), Localize.get('ValidationMessages.ValidUntilBeforeStartDate'))
      .nullable()
  });
};
