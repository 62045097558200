import React, { useCallback, useEffect, useState } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import { get, isEmpty } from 'lodash';
import moment from 'moment';

import { checkChangedFields } from '@common/helpers/helpers';
import EntityTypes from '@common/network/EntityTypes';
import { clearState, selectSavedState } from '@common/storage/persistSlice';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import { selectIsFullScreen } from '@components/DetailsToolbar/detailsToolbarSlice';
import EventStatus from '@components/EventStatus';
import {
  FILTER_ACTIONS,
  openFilterDialog,
  // openFilterDialog,
  resetFilterState,
  scrubFiltersForBE,
  selectIsActive
} from '@components/FilterDialog/filtersSlice';
import Header from '@components/Header';
import LayoutContainer, { LeftContainer, RightContainer } from '@components/LayoutContainer';
import MasterList from '@components/MasterList';
import MasterListFooter from '@components/MasterListFooter';
import MasterListItem from '@components/MasterListItem';
import MasterListTitle from '@components/MasterListTitle';
import MasterListToolbar from '@components/MasterListToolbar';
import { selectIsOpen, setMode } from '@components/RightSidebar/rightSidebarSlice';
import SortDialog from '@components/SortDialog';
import { DATE_FORMAT, SEARCH_INPUT_DELAY } from '@config/inputs';
import useDebounce from '@hooks/handlers/useDebounce';
import { eventsNewPath } from '@navigation/routes/Routes';
import Sidebar from '@pages/Events/components/Sidebar';
import {
  deleteEvent,
  fetchEventDetails,
  fetchEvents,
  initialState,
  resetState,
  saveEvent,
  selectDetails,
  selectFilter,
  selectId,
  selectIsDetailsLoading,
  selectIsLoading,
  selectList,
  selectTotalElements,
  selectTotalPages,
  setDetails,
  setFilterParams,
  setLoading,
  setSelectedId
} from '@pages/Events/eventsSlice';

import { EventsDetails, EventsTabs } from './components';
import eventsFilters from './util/fields/filters';
import getEventsOptions from './util/fields/utils';
import { SORT_DATA } from './util/sortConfig';

const Events = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isOpenDialogSort, setIsOpenDialogSort] = useState(false);

  const selectedId = useSelector(selectId);
  const data = useSelector(selectList);
  const details = useSelector(selectDetails);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const filter = useSelector(selectFilter);
  const savedState = useSelector(selectSavedState);

  const isLoading = useSelector(selectIsLoading);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);

  const isFilterActive = useSelector(selectIsActive);

  const isOpen = useSelector(selectIsOpen);
  const isFullScreen = useSelector(selectIsFullScreen);

  const idAfterSessionCreate = JSON.parse(localStorage.getItem('idAfterSessionCreate'));

  const {
    data: {
      nativeLanguage: { data: language },
      type: { data: eventTypes },
      status: { data: statuses }
    }
  } = useMatch();

  eventsFilters.find((el) => el.id === 'eventType').options = eventTypes.map((el) => ({
    label: el.value,
    key: el.value
  }));

  eventsFilters.find((el) => el.id === 'eventLanguage').options = language.map((el) => ({
    label: el.value,
    key: el.value
  }));

  eventsFilters.find((el) => el.id === 'eventStatus').options = statuses.map((el) => ({
    label: el.value,
    key: el.value
  }));

  useEffect(() => {
    if (idAfterSessionCreate) {
      dispatch(setSelectedId(idAfterSessionCreate));
      localStorage.removeItem('idAfterSessionCreate');
    }
    return () => {
      dispatch(resetState());
      dispatch(resetFilterState());
    };
  }, []);

  // Get List of entities on initialization and sort change
  useEffect(() => {
    if (!isEmpty(savedState)) {
      dispatch(fetchEvents(savedState.eventsFilter));
      dispatch(setSelectedId(savedState.selectedId));
      dispatch(clearState());
    } else {
      dispatch(fetchEvents(filter));
    }
  }, [filter.sortBy, filter.sortDirection, filter.page]);

  // Get entity details on id change
  useEffect(() => dispatch(fetchEventDetails(selectedId)), [selectedId]);

  // Get List of entities on search change
  useDebounce(() => dispatch(fetchEvents(filter)), SEARCH_INPUT_DELAY, [filter.search]);

  const onFilterClick = useCallback(() => {
    dispatch(
      openFilterDialog({
        title: `${Localize.get('Launchpad.Events')} ${Localize.get('Labels.Filter')}`,
        config: eventsFilters.map((filter) => ({
          ...filter,
          label: Localize.get(filter.label),
          operator: { label: Localize.get(filter.operator.label), key: filter.operator.key },
          options: getEventsOptions(filter.options)
        }))
      })
    ).then(({ payload }) => {
      if (payload.action === FILTER_ACTIONS.Cancel) {
        return;
      }

      if (payload.action === FILTER_ACTIONS.Reset) {
        dispatch(fetchEvents(filter));
        return;
      }

      dispatch(
        fetchEvents({
          ...filter,
          filters: { advancedFilters: scrubFiltersForBE(payload.filters) }
        })
      );
    });
  }, [details?.type?.value, filter?.sortBy, filter?.sortDirection, filter?.page]);

  const onSave = (values) => {
    return dispatch(saveEvent({ postData: checkChangedFields(details, values), id: selectedId }))
      .unwrap()
      .then(() => {
        return Promise.resolve();
      })
      .catch((rejectedValueOrSerializedError) => {
        return Promise.reject({
          rejectedValueOrSerializedError,
          entityType: EntityTypes.EVENT
        });
      });
  };

  const onDelete = () => {
    dispatch(
      openConfirmDialog({
        title: Localize.get('ConfirmationMessages.Delete', {
          item: Localize.get('Events.Item')?.toLowerCase()
        }),
        confirmButton: Localize.get('Buttons.Delete'),
        cancelButton: Localize.get('Buttons.Cancel')
      })
    )
      .unwrap()
      .then((result) => {
        if (result === CONFIRM_ACTIONS.Cancel) {
          return;
        }
        dispatch(deleteEvent(selectedId))
          .unwrap()
          .then(() => dispatch(setMode('read')))
          .catch((rejectedValueOrSerializedError) => console.error(rejectedValueOrSerializedError));
      });
  };

  const renderMasterListItem = useCallback(
    (item) => {
      return (
        <MasterListItem
          key={item.id}
          id={item.id}
          heading={{
            left: <EventStatus status={item?.status?.id}>{item?.name}</EventStatus>,
            right: item?.id
          }}
          leftDetails={{
            secondRow: item?.venueName,
            firstRow: moment(get(item, 'startDateTime', '')).format(DATE_FORMAT)
          }}
          selectedId={selectedId}
          onItemSelect={(payload) => dispatch(setSelectedId(payload))}
        />
      );
    },
    [selectedId]
  );
  return (
    <LayoutContainer>
      <LeftContainer isFullScreen={isFullScreen}>
        <Header>
          <MasterListTitle>{`${Localize.get(
            'Events.MasterListTittle'
          )} (${totalElements})`}</MasterListTitle>
        </Header>

        <MasterListToolbar
          isDisabled={data?.length === 0}
          onSortClick={() => setIsOpenDialogSort(true)}
          isFilterActive={isFilterActive}
          onFilterClick={onFilterClick}
          isSortActive={
            filter.sortBy !== initialState.filter.sortBy ||
            filter.sortDirection !== initialState.filter.sortDirection
          }
          searchProps={{
            value: filter.search,
            onSearchChange: (e) => {
              dispatch(setLoading(true));
              dispatch(setFilterParams({ key: 'search', value: e.target.value }));
            }
          }}
        />

        <MasterList isLoading={isLoading} data={data} renderMasterItem={renderMasterListItem} />
        <MasterListFooter
          onPageChange={(e, page) => dispatch(setFilterParams({ ...filter, page: page - 1 }))}
          onAddClick={() => navigate({ to: eventsNewPath, replace: false })}
          pagination={{ totalPages, page: filter.page + 1 }}
        />

        <SortDialog
          onCancel={() => setIsOpenDialogSort(false)}
          open={isOpenDialogSort}
          sortState={filter}
          fields={SORT_DATA}
          sortHandler={({ sortBy, sortDirection }) => {
            dispatch(
              setFilterParams([
                { key: 'sortBy', value: sortBy },
                { key: 'sortDirection', value: sortDirection }
              ])
            );
          }}
        />
      </LeftContainer>

      <RightContainer open={isOpen} isFullScreen={isFullScreen}>
        <EventsDetails entityId={selectedId} details={details}>
          <EventsTabs
            entityId={selectedId}
            entityType={EntityTypes.EVENT}
            details={details}
            setDetails={setDetails}
          />
        </EventsDetails>
      </RightContainer>

      <Sidebar
        isDetailsLoading={isDetailsLoading}
        data={details}
        onSave={onSave}
        onDelete={onDelete}
      />
    </LayoutContainer>
  );
};

export default Events;
