import Localize from 'react-intl-universal';

import { isEmpty, isObject } from 'lodash';

import { TAB_KEYS, dateInitFormats } from '@common/Constants';
import { dateToFormat } from '@common/helpers/dates';
import NotesTable from '@components/NotesTable';

import IltSessionOverview from '../Overview/IltSessionOverview';
import AttachmentsTable from './AttachmentsTable';
import HotelContingentTable from './Hotel/HotelTable';
import ParticipantsTable from './ParticipantsTable/ParticipantsTable';
import PriceTable from './Price/PriceTable';
import SessionsDayTable from './SessionDays/SessionDaysTable';
import SessionRoomsTable from './SessionRooms/SessionRoomsTable';
import TasksTable from './Tasks/TasksTable';
import TeamTable from './Team/TeamTable';
import WaitingListTable from './WaitingList/WaitingListTable';

export const ILT_SESSION_TABS_COMPONENTS = {
  [TAB_KEYS.OVERVIEW]: IltSessionOverview,
  [TAB_KEYS.NOTE]: NotesTable,
  [TAB_KEYS.SESSION_DAY]: SessionsDayTable,
  [TAB_KEYS.SESSION_ROOMS]: SessionRoomsTable,
  [TAB_KEYS.PARTICIPANTS]: ParticipantsTable,
  [TAB_KEYS.BOOKING_CONTINGENT]: HotelContingentTable,
  [TAB_KEYS.ATTACHMENT]: AttachmentsTable,
  [TAB_KEYS.PRICE]: PriceTable,
  [TAB_KEYS.TASK]: TasksTable,
  [TAB_KEYS.WAITING_LIST]: WaitingListTable,
  [TAB_KEYS.TEAM]: TeamTable
};

export const NO_PRICE_DEFAULT_VALUE = 0;

export default {
  activeIndex: 0,
  tabs: [
    {
      label: 'Labels.Overview',
      key: TAB_KEYS.OVERVIEW,
      icon: 'info',
      isCountVisible: false
    },
    {
      label: 'IltSession.SessionDays',
      key: TAB_KEYS.SESSION_DAY,
      icon: 'groups',
      toolbarButtons: [
        {
          name: 'add_sessions',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'export',
          disabled: false,
          icon: 'download'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'date',
          headerName: 'Labels.Date',
          editable: false,
          width: 150,
          headerAlign: 'left',
          type: 'date',
          valueGetter: (params) => {
            return params?.row?.dayDate;
          }
        },
        {
          field: 'timeFrom',
          headerName: 'Labels.TimeFrom',
          editable: false,
          headerAlign: 'left',
          width: 100,
          valueGetter: (params) => {
            return params?.row?.startTime;
          }
        },
        {
          field: 'timeTo',
          headerName: 'Labels.TimeTo',
          editable: false,
          headerAlign: 'left',
          width: 100,
          valueGetter: (params) => {
            return params?.row?.endTime;
          }
        },
        {
          field: 'instructor',
          headerName: 'IltSession.Instructor',
          width: 200,
          editable: false,
          headerAlign: 'left',
          filterable: false,
          sortable: false,
          valueGetter: (params) => {
            return params?.row?.instructors[0]?.fullName;
          }
        },
        {
          field: 'venueName',
          headerName: 'Locations.Venue',
          width: 200,
          editable: false,
          headerAlign: 'left',
          valueGetter: (params) => params?.row?.venue
        },
        {
          field: 'meetingRoomName',
          headerName: 'IltSession.Room',
          width: 150,
          editable: false,
          headerAlign: 'left',
          valueGetter: (params) => params?.row?.room
        }
      ]
    },
    {
      label: 'IltSession.SessionRooms',
      key: TAB_KEYS.SESSION_ROOMS,
      icon: 'meeting_room',
      toolbarButtons: [
        {
          name: 'edit',
          disabled: false,
          icon: 'edit'
        }
      ]
    },
    {
      label: 'Labels.Participants',
      key: TAB_KEYS.PARTICIPANTS,
      icon: 'accessibility',
      toolbarButtons: [
        {
          name: 'add_participant',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'export',
          disabled: false,
          icon: 'download'
        },
        {
          name: 'email_participant',
          disabled: true,
          icon: 'mail'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'eventParticipantName',
          headerName: 'Labels.Name',
          width: 200,
          editable: false,
          headerAlign: 'left',
          valueGetter: ({ row }) => {
            const { personDto } = row;
            return `${personDto?.firstName} ${personDto?.lastName}`;
          }
        },
        {
          field: 'companyName',
          headerName: 'Labels.Company',
          width: 150,
          editable: false,
          headerAlign: 'left',
          valueGetter: ({ row }) => {
            const { company } = row;
            return company?.name;
          }
        },
        {
          field: 'iltSessionStatus',
          headerName: 'IltSession.IltSessionStatus',
          editable: false,
          width: 150,
          headerAlign: 'left',
          align: 'left',
          type: 'boolean',
          valueGetter: (params) =>
            !params?.row?.completed
              ? Localize.get('Labels.NotCompleted')
              : Localize.get('Labels.Completed')
        },
        {
          field: 'eventParticipantStatus',
          headerName: 'IltSession.Status',
          editable: false,
          headerAlign: 'left',
          width: 150,
          type: 'singleSelect',
          valueGetter: ({ row }) => {
            const { status } = row;
            return status?.value;
          }
        },
        {
          field: 'hotelRequest',
          headerName: 'IltSession.HotelRequest',
          editable: false,
          headerAlign: 'left',
          width: 150,
          type: 'boolean',
          valueGetter: ({ row }) => {
            const { hotelRequest } = row;
            return hotelRequest ? Localize.get('Labels.Yes') : Localize.get('Labels.No');
          }
        },
        {
          field: 'standardPrice',
          headerName: 'PriceTypes.Standard',
          editable: false,
          headerAlign: 'left',
          width: 120,
          filterable: false,
          sortable: false,
          valueGetter: ({ row }) => {
            const { standardPrice = null, currency = null } = row;
            if (!standardPrice && !currency) {
              return '';
            }
            return `${standardPrice ?? NO_PRICE_DEFAULT_VALUE} ${currency?.value ?? ''}`;
          }
        },
        {
          field: 'advertisingSubsidyPrice',
          headerName: 'PriceTypes.WKZ',
          editable: false,
          headerAlign: 'left',
          width: 120,
          filterable: false,
          sortable: false,
          valueGetter: ({ row }) => {
            const { advertisingSubsidyPrice = null, currency = null } = row;
            if (!advertisingSubsidyPrice && !currency) {
              return '';
            }
            return `${advertisingSubsidyPrice ?? NO_PRICE_DEFAULT_VALUE} ${currency?.value ?? ''}`;
          }
        },
        {
          field: 'hotel',
          headerName: 'IltSession.Hotel',
          editable: false,
          headerAlign: 'left',
          width: 200,
          filterable: false,
          sortable: false,
          valueGetter: ({ row }) => {
            const { hotels } = row;
            return hotels[0]?.name;
          }
        },
        {
          field: 'arrivalDate',
          headerName: 'IltSession.Arrival',
          editable: false,
          headerAlign: 'left',
          width: 150,
          type: 'date',
          valueGetter: ({ row }) => {
            return dateToFormat(row?.arrivalDate, dateInitFormats.basicDate);
          }
        },
        {
          field: 'departureDate',
          headerName: 'IltSession.Departure',
          editable: false,
          headerAlign: 'left',
          width: 150,
          type: 'date',
          valueGetter: ({ row }) => {
            return dateToFormat(row?.departureDate, dateInitFormats.basicDate);
          }
        },
        {
          field: 'nightsBooked',
          headerName: 'IltSession.NightsBooked',
          editable: false,
          headerAlign: 'left',
          width: 150,
          filterable: false,
          sortable: false,
          valueGetter: ({ row }) => {
            return String(row?.nightsBooked);
          }
        }
      ]
    },
    {
      label: 'IltSession.WaitList',
      key: TAB_KEYS.WAITING_LIST,
      icon: 'schedule',
      toolbarButtons: [
        {
          name: 'email_participant',
          disabled: false,
          icon: 'group_add'
        },
        {
          name: 'add_participant',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          editable: false,
          headerAlign: 'left',
          required: true,
          filterable: false
        },
        {
          field: 'name',
          headerName: 'Labels.Name',
          editable: false,
          headerAlign: 'left',
          required: true,
          type: 'string',
          width: 200,
          valueGetter: ({ row }) => {
            const { personDto } = row;
            return `${personDto?.firstName} ${personDto?.lastName}`;
          }
        },
        {
          field: 'companyName',
          headerName: 'Labels.Company',
          editable: false,
          headerAlign: 'left',
          required: true,
          width: 200,
          valueGetter: ({ row }) => {
            return row?.company?.name;
          }
        },
        {
          field: 'registrationDatetime',
          headerName: 'Labels.RegistrationDate',
          editable: false,
          headerAlign: 'left',
          required: true,
          width: 200,
          type: 'date'
        },
        {
          field: 'status',
          headerName: 'Labels.Status',
          editable: false,
          required: true,
          width: 150,
          type: 'singleSelect',
          valueGetter: ({ row }) => {
            return row?.status?.value;
          }
        },
        {
          field: 'hotelRequest',
          headerName: 'IltSession.Hotel',
          editable: false,
          headerAlign: 'left',
          width: 80,
          type: 'boolean',
          filterable: false,
          sortable: false,
          valueGetter: ({ row }) => {
            const hotelRequest = row?.hotelRequest;
            return hotelRequest ? Localize.get('Labels.Yes') : Localize.get('Labels.No');
          }
        },
        {
          field: 'arrivalDate',
          headerName: 'IltSession.Arrival',
          editable: false,
          headerAlign: 'left',
          width: 150,
          type: 'date'
        },
        {
          field: 'departureDate',
          headerName: 'IltSession.Departure',
          editable: false,
          headerAlign: 'left',
          width: 150,
          type: 'date'
        }
      ]
    },
    {
      label: 'IltSession.Teams',
      key: TAB_KEYS.TEAM,
      icon: 'work_outline',
      toolbarButtons: [
        {
          name: 'add_participant',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          editable: false,
          headerAlign: 'left',
          required: true,
          filterable: false
        },
        {
          field: 'name',
          headerName: 'Labels.Name',
          editable: false,
          headerAlign: 'left',
          required: true,
          type: 'string',
          width: 200,
          valueGetter: ({ row }) => {
            const { personDto } = row;
            return `${personDto?.firstName} ${personDto?.lastName}`;
          }
        },
        {
          field: 'email',
          headerName: 'Event.Communications.Email',
          editable: false,
          filterable: false,
          headerAlign: 'left',
          required: true,
          width: 200,
          valueGetter: ({ row }) => {
            return row?.emailDtos[0]?.email;
          }
        },
        {
          field: 'teamRole',
          headerName: 'IltSession.IltSessionRole',
          editable: false,
          headerAlign: 'left',
          required: true,
          width: 200,
          valueGetter: ({ row }) => {
            return row?.teamMemberDto?.teamRole?.value;
          }
        },
        {
          field: 'sessionRoleResponse',
          headerName: 'IltSession.SessionRoles',
          editable: false,
          headerAlign: 'left',
          required: true,
          width: 200,
          valueGetter: ({ row }) => {
            const { sessionRoleResponse } = row;

            if (!isObject(sessionRoleResponse) || isEmpty(sessionRoleResponse)) {
              return '';
            }

            const firstKey = Object.keys(sessionRoleResponse)[0];
            const visibleRole = sessionRoleResponse && sessionRoleResponse[firstKey];
            const rolesCount = Object.keys(sessionRoleResponse).length ?? 0;

            if (!visibleRole || isEmpty(visibleRole)) {
              return '';
            }

            return `${visibleRole.value} ${rolesCount > 1 ? ' +' + rolesCount : ''}`;
          }
        },
        {
          field: 'companyName',
          headerName: 'Labels.Company',
          editable: false,
          required: true,
          width: 150,
          valueGetter: ({ row }) => {
            return row?.company?.name;
          }
        },
        {
          field: 'hotelRequest',
          headerName: 'IltSession.HotelRequest',
          editable: false,
          headerAlign: 'left',
          width: 200,
          filterable: false,
          valueGetter: ({ row }) => {
            const hotelRequest = row?.hotelRequest;
            return hotelRequest ? Localize.get('Labels.Yes') : Localize.get('Labels.No');
          }
        },
        {
          field: 'hotel',
          headerName: 'IltSession.Hotel',
          editable: false,
          filterable: false,
          headerAlign: 'left',
          width: 200,
          valueGetter: ({ row }) => {
            const { hotels } = row;
            return hotels[0]?.name;
          }
        },
        {
          field: 'arrivalDate',
          headerName: 'IltSession.Arrival',
          editable: false,
          headerAlign: 'left',
          width: 150
        },
        {
          field: 'departureDate',
          headerName: 'IltSession.Departure',
          editable: false,
          headerAlign: 'left',
          width: 150
        },
        {
          field: 'nightsBooked',
          headerName: 'IltSession.NightsBooked',
          editable: false,
          filterable: false,
          required: true,
          width: 150,
          valueGetter: ({ row }) => {
            return row?.nightsBooked;
          }
        }
      ]
    },
    {
      label: 'Launchpad.Hotels',
      key: TAB_KEYS.BOOKING_CONTINGENT,
      icon: 'night_shelter',
      toolbarButtons: [
        {
          name: 'add_sessions',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'export',
          disabled: false,
          icon: 'download'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          width: 100,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'name',
          headerName: 'Labels.Name',
          width: 250,
          editable: false,
          headerAlign: 'left',
          valueGetter: (params) => params?.row?.hotel?.name
        },
        {
          field: 'contingentAmount',
          headerName: 'Labels.ContingentAmount',
          width: 200,
          editable: false,
          headerAlign: 'left',
          align: 'left',
          type: 'number',
          valueGetter: (params) => params?.row?.contingent
        },
        {
          field: 'used/free',
          headerName: 'Labels.UsedFree',
          editable: false,
          width: 200,
          headerAlign: 'left',
          filterable: false,
          sortable: false,
          valueGetter: (params) => params?.row?.usedFreeBeds
        },
        {
          field: 'roomType',
          headerName: 'Hotel.RoomType',
          editable: false,
          headerAlign: 'left',
          width: 200,
          type: 'singleSelect',
          valueGetter: (params) => params?.row?.accommodation?.roomType?.value
        },
        {
          field: 'price',
          headerName: 'Labels.PricePerNight',
          headerAlign: 'left',
          width: 200,
          type: 'number',
          align: 'left',
          valueGetter: (params) => `${params?.row?.price} ${params?.row?.currency?.value}`
        },
        {
          field: 'startDate',
          headerName: 'Hotel.ArrivalDate',
          editable: false,
          headerAlign: 'left',
          width: 200,
          type: 'date'
        },
        {
          field: 'endDate',
          headerName: 'Hotel.DepartureDate',
          editable: false,
          headerAlign: 'left',
          width: 200,
          type: 'date'
        },
        {
          field: 'validTo',
          headerName: 'Labels.ValidUntil',
          editable: false,
          headerAlign: 'left',
          width: 200,
          type: 'date',
          valueGetter: (params) => params?.row?.validUntil
        },
        {
          field: 'description',
          headerName: 'Labels.Description',
          editable: false,
          headerAlign: 'left',
          width: 250,
          valueGetter: (params) => params?.row?.accommodation?.description
        }
      ]
    },
    {
      label: 'Labels.Prices',
      key: TAB_KEYS.PRICE,
      icon: 'local_offer',
      toolbarButtons: [
        {
          name: 'add_price',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'priceType',
          headerName: 'Labels.PriceType',
          width: 250,
          editable: false,
          headerAlign: 'left',
          required: true,
          type: 'singleSelect',
          valueGetter: (params) => params?.row?.type?.value
        },
        {
          field: 'price',
          headerName: 'ResourcesTile.Price',
          width: 250,
          editable: false,
          headerAlign: 'left',
          required: true,
          type: 'number',
          align: 'left'
        },
        {
          field: 'currency',
          headerName: 'ResourcesTile.Currency',
          width: 250,
          editable: false,
          headerAlign: 'left',
          required: true,
          type: 'singleSelect',
          valueGetter: (params) => params?.row?.currency?.value
        }
      ]
    },
    {
      label: 'Labels.Tasks',
      key: TAB_KEYS.TASK,
      icon: 'assignment',
      toolbarButtons: [
        {
          name: 'add_tasks',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'id',
          headerName: 'Labels.ID',
          editable: false,
          headerAlign: 'left',
          required: true,
          filterable: false
        },
        {
          field: 'subject',
          headerName: 'Labels.Subject',
          editable: false,
          headerAlign: 'left',
          required: true,
          type: 'string'
        },
        {
          field: 'processor',
          headerName: 'Labels.Processor',
          editable: false,
          headerAlign: 'left',
          required: true,
          valueGetter: ({ row }) =>
            row?.taskDto?.processor
              ? row?.taskDto?.processor?.firstName.concat(' ', row?.taskDto?.processor?.lastName)
              : ''
        },
        {
          field: 'status',
          headerName: 'Labels.Status',
          editable: false,
          required: true,
          type: 'singleSelect',
          valueGetter: ({ row }) => row?.status?.value
        },
        {
          field: 'completion',
          headerName: 'Labels.Completion',
          editable: false,
          required: true,
          width: 130,
          type: 'number',
          align: 'left',
          valueGetter: ({ row }) => row?.taskDto?.completion
        },
        {
          field: 'priority',
          headerName: 'Labels.Priority',
          editable: false,
          required: true,
          type: 'singleSelect',
          valueGetter: ({ row }) => row?.priority?.value
        },
        {
          field: 'startDate',
          headerName: 'Labels.StartDate',
          width: 170,
          editable: false,
          required: true,
          type: 'date',
          valueGetter: ({ row }) => row?.startDateTime
        },
        {
          field: 'endDate',
          headerName: 'Labels.EndDate',
          width: 170,
          editable: false,
          headerAlign: 'left',
          required: true,
          type: 'date',
          valueGetter: ({ row }) => row?.endDateTime
        },
        {
          field: 'taskCategory',
          headerName: 'Labels.Category',
          editable: false,
          headerAlign: 'left',
          required: true,
          type: 'singleSelect',
          valueGetter: ({ row }) => row?.taskDto?.taskCategory?.value
        },
        {
          field: 'account',
          headerName: 'Labels.Account',
          width: 180,
          editable: false,
          headerAlign: 'left',
          required: true,
          valueGetter: ({ row }) => row?.account?.name
        },
        {
          field: 'primaryContact',
          headerName: 'Labels.PrimaryContact',
          width: 180,
          editable: false,
          headerAlign: 'left',
          required: true,
          valueGetter: ({ row }) =>
            row?.contact ? row?.contact?.firstName.concat(' ', row?.contact?.lastName) : ''
        }
      ]
    },
    {
      label: 'Labels.Attachments',
      key: TAB_KEYS.ATTACHMENT,
      icon: 'attachments',
      toolbarButtons: [
        {
          name: 'add_attachment',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'displayName',
          headerName: 'IltSession.FileNameDisplay',
          width: 200,
          editable: false,
          headerAlign: 'left',
          filterable: false,
          sortable: false
        },
        {
          field: 'name',
          headerName: 'IltSession.File',
          width: 200,
          editable: false,
          headerAlign: 'left'
        },
        {
          field: 'visibilityAccessGranted',
          headerName: 'IltSession.VisibilityAccessGranted',
          width: 250,
          editable: false,
          headerAlign: 'left',
          filterable: false,
          sortable: false
        },
        {
          field: 'extension',
          headerName: 'IltSession.FileType',
          width: 150,
          editable: false,
          headerAlign: 'left',
          filterable: false,
          sortable: false
        },
        {
          field: 'size',
          headerName: 'Labels.Size',
          width: 150,
          editable: false,
          defaultValue: '0 KB',
          headerAlign: 'left',
          filterable: false
        },
        {
          field: 'createdAt',
          headerName: 'IltSession.UploadAt',
          width: 170,
          editable: false,
          headerAlign: 'left',
          filterable: false,
          sortable: false
        },
        {
          field: 'createdBy',
          headerName: 'IltSession.UploadBy',
          width: 200,
          editable: false,
          headerAlign: 'left',
          valueGetter: (params) => params?.value?.name || 'Unknown User'
        },
        {
          field: 'url',
          headerName: 'IltSession.DownloadFile',
          width: 150,
          editable: false,
          headerAlign: 'left',
          align: 'center',
          filterable: false,
          sortable: false
        }
      ]
    },
    {
      label: 'Labels.Notes',
      key: TAB_KEYS.NOTE,
      icon: 'note_alt',
      toolbarButtons: [
        {
          name: 'add_note',
          disabled: false,
          icon: 'add'
        },
        {
          name: 'delete',
          disabled: false,
          icon: 'delete'
        }
      ],
      columns: [
        {
          field: 'type',
          headerName: 'Labels.Type',
          width: 250,
          editable: false,
          headerAlign: 'left',
          type: 'singleSelect'
        },
        {
          field: 'text',
          headerName: 'Labels.Text',
          width: 250,
          editable: false,
          headerAlign: 'left',
          filterable: false,
          sortable: false
        }
      ]
    }
  ]
};
