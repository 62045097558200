// Available filter values supported on BE:
// Attachments Tab - Filter by fields: name, createdAt
// Notes Tab - Filter by fields: text, type
// Accommodations Tab (Hotel tile) - Filter by fields: roomType, roomSize, occupancy, total
// Contacts Tab (Hotel tile) - Filter by fields: locationName, name, function, department
// Participants Tab (Hotel tile) - Filter by fields: name, startDate, endDate, type
// Contingents Tab (Hotel tile) - Filter by fields: year, roomType, contingent, booked, price
import {
  getGridStringOperators,
  getGridNumericOperators,
  getGridDateOperators,
  getGridSingleSelectOperators,
  GridFilterInputSingleSelect
} from '@mui/x-data-grid';

import { FILTER_KEYS } from '@components/FilterDialog';

const availableFilterValues = ['contains', 'startsWith', 'endsWith', 'equals'];
const eventDialogOperators = ['contains'];
const equalNumberDialogOperators = ['='];

export const BACKEND_FILTER_VALUES = {
  contains: 'contains',
  startsWith: 'starting',
  endsWith: 'ending',
  equals: 'equal'
};

export const MUI_TABLE_FILTER_OPERATORS = {
  contains: FILTER_KEYS.Contains,
  startsWith: FILTER_KEYS.StartsWith,
  endsWith: FILTER_KEYS.EndsWith,
  '=': FILTER_KEYS.Equals,
  '!=': FILTER_KEYS.NotEqual,
  '>': FILTER_KEYS.GreaterThen,
  '<': FILTER_KEYS.LessThen,
  '>=': FILTER_KEYS.GreaterThenOrEqual,
  '<=': FILTER_KEYS.LessThenOrEqual,
  isEmpty: FILTER_KEYS.IsEmpty,
  isNotEmpty: FILTER_KEYS.IsNotEmpty,
  equals: FILTER_KEYS.Equals,
  notEqual: FILTER_KEYS.NotEqual,
  not: FILTER_KEYS.IsNot,
  is: FILTER_KEYS.Is,
  after: FILTER_KEYS.IsAfter,
  onOrAfter: FILTER_KEYS.IsOnOrAfter,
  before: FILTER_KEYS.IsBefore,
  onOrBefore: FILTER_KEYS.IsOnOrBefore,
  isNotOn: FILTER_KEYS.IsNotOn,
  isOn: FILTER_KEYS.IsOn
};

export const attachmentTableFilters = getGridStringOperators()
  .filter((operator) => availableFilterValues.includes(operator.value))
  .map((operator) => operator);

export const containsOnlyDialogFilters = getGridStringOperators().filter(({ value }) =>
  eventDialogOperators.includes(value)
);

export const customBooleanOperators = getGridSingleSelectOperators().filter(
  ({ value }) => !['isAnyOf'].includes(value)
);

export const customSingleSelectOperators = getGridStringOperators()
  .filter(({ value }) => ['equals', 'isEmpty', 'isNotEmpty'].includes(value))
  .map((item) => ({
    ...item,
    InputComponent: ['equals', 'notEqual'].includes(item.value)
      ? GridFilterInputSingleSelect
      : item.InputComponent
  }));

customSingleSelectOperators.splice(1, 0, {
  ...getGridSingleSelectOperators()[1],
  value: 'notEqual'
});

const customGridDateOperators = getGridDateOperators().map((item) =>
  item.value === 'is'
    ? { ...item, value: 'isOn' }
    : item.value === 'not'
    ? { ...item, value: 'isNotOn' }
    : item
);

export const equalNumbersDialogFilters = getGridNumericOperators().filter(({ value }) =>
  equalNumberDialogOperators.includes(value)
);

const numberOperatorsWithValue = getGridNumericOperators()
  .filter(({ value }) => !['isEmpty', 'isNotEmpty', 'isAnyOf'].includes(value))
  .map(({ value }) => value);

export const isNumberOperator = (operator) => numberOperatorsWithValue.includes(operator);

export const getFilterOperatorsBasedOnType = (type) => {
  switch (type) {
    case 'number':
      return getGridNumericOperators().filter(({ value }) => !['isAnyOf'].includes(value));

    case 'date':
      return customGridDateOperators;

    case 'boolean':
      return customBooleanOperators;

    case 'singleSelect':
      return customSingleSelectOperators;

    default:
      return getGridStringOperators().filter(({ value }) => !['isAnyOf'].includes(value));
  }
};

export const getFilterValueOptionsBasedOnType = (column, getSingleSelectValueOptions) => {
  if (!column) return [];
  switch (column?.type) {
    case 'boolean':
      return ['true'];

    case 'singleSelect':
      return getSingleSelectValueOptions ? getSingleSelectValueOptions(column?.field) : [];

    default:
      return column?.valueOptions;
  }
};
