import React, { useEffect, useRef } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useSearch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';

import { ACTION_MODES, SORTING_ORDER } from '@common/Constants';
import { onTabsTableFilterChange } from '@common/helpers/tables/getAdvancedFiltersForMUITable';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import { saveState } from '@common/storage/persistSlice';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarButton from '@components/ToolbarButton';
import { getFilterOperatorsBasedOnType } from '@config/filterOperators';
import { TABLE_OPTIONS } from '@config/network';
import {
  selectLocationDetails,
  selectFilter as selectFilterBp
} from '@pages/BusinessPartners/businessPartnersSlice';
import {
  selectList,
  fetchMeetingRooms,
  selectFilter,
  selectIsLoading,
  resetState,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  selectSelectionModel,
  setSelectionModel,
  getVenueId
} from '@pages/Locations/components/Tabs/MeetingRoomTable/meetingRoomTableSlice';
import { CreateMeetingRoom } from '@pages/MeetingRooms';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  }
}));

const MeetingRoomTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarButtons = []
}) => {
  const classes = useStyles();
  const search = useSearch();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const trackFilterModel = useRef(null);

  const details = useSelector(selectLocationDetails);
  const { roles = [] } = details || {};
  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const filterBp = useSelector(selectFilterBp);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);

  useEffect(() => {
    const venueId = getVenueId(roles);
    if (venueId) {
      dispatch(fetchMeetingRooms({ entityId: venueId, filter: filter }));
    }
  }, [
    roles[0]?.venue?.id,
    roles[0].supplierType?.id,
    filter?.page,
    filter?.sortBy,
    filter?.sortDirection,
    filter?.size,
    filter?.filters
  ]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarButtonDisabled = (name) => {
    switch (name) {
      case 'add_meeting_room':
        return !entityId || !entityType;

      case 'delete':
        return true; // selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onToolbarButtonClick = (name) => {
    switch (name) {
      case 'add_meeting_room':
        dispatch(saveState({ selectedId: entityId, businessPartnerFilter: filterBp }));
        navigate({
          search: (previousUrlParams) => ({
            ...previousUrlParams,
            mode: ACTION_MODES.Create,
            venueId: entityId
          })
        });
        return;
      case 'delete':
        return;
      default:
        return;
    }
  };

  const onFilterChange = (values) => {
    const advancedFilters = onTabsTableFilterChange(values, trackFilterModel, () =>
      dispatch(setFilterParams({ key: 'filters', value: null }))
    );
    if (advancedFilters) dispatch(setFilterParams({ key: 'filters', value: { advancedFilters } }));
  };

  const onSortChange = (sortModel) =>
    dispatch(
      setFilterParams([
        { key: 'sortBy', value: sortModel[0]?.field },
        { key: 'sortDirection', value: sortModel[0]?.sort }
      ])
    );

  return (
    <>
      <div className={classes.root}>
        {search?.mode === ACTION_MODES.Create ? (
          <CreateMeetingRoom venue={details} />
        ) : (
          <TabContent>
            <div className={classes.toolbarContainer}>
              <Typography variant="h6" component="h6" color="primary">
                {Localize.get('Launchpad.MeetingRooms')}
              </Typography>
              <Toolbar className={classes.toolbar}>
                {toolbarButtons?.map((button, index) => (
                  <ToolbarButton
                    key={index}
                    button={button}
                    isDisabled={button.disabled || isToolbarButtonDisabled(button.name)}
                    onToolbarButtonClick={onToolbarButtonClick}
                  />
                ))}
              </Toolbar>
            </div>
            <TabContainer>
              <DataGrid
                keepNonExistentRowsSelected={true}
                loading={isLoading}
                rows={data ?? []}
                columns={[
                  ...columns.map((column) => ({
                    ...column,
                    headerName: Localize.get(column.headerName),
                    filterOperators: getFilterOperatorsBasedOnType(column?.type),
                    renderCell: (params) => (
                      <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                        {['name', 'phone', 'floor'].includes(params.field) ? (
                          <Tooltip title={params.value} arrow>
                            <Typography
                              noWrap
                              variant="body"
                              component="div"
                              sx={{
                                maxWidth: params?.colDef?.width - 10 // 10 px for three dots
                              }}
                            >
                              {params.value}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography variant="body" component="div">
                            {params.value}
                          </Typography>
                        )}
                      </div>
                    )
                  }))
                ]}
                initialState={{
                  pagination: {
                    pageSize: totalPages,
                    rowCount: totalElements,
                    page: filter?.page
                  }
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  dispatch(setSelectionModel(newSelectionModel));
                }}
                selectionModel={selectionModel}
                checkboxSelection
                localeText={getLocalesText(Localize)}
                pagination
                paginationMode="server"
                sortingMode="server"
                filterMode="server"
                disableColumnMenu
                page={filter?.page}
                pageSize={filter?.size}
                rowCount={totalElements}
                rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
                disableSelectionOnClick
                components={{
                  Toolbar: () => <TableToolbar />
                }}
                onPageChange={(page) =>
                  dispatch(setFilterParams({ key: 'page', value: page >= 0 ? page : 0 }))
                }
                onPageSizeChange={(value) => dispatch(setFilterParams([{ key: 'size', value }]))}
                sortingOrder={SORTING_ORDER}
                onSortModelChange={onSortChange}
                onFilterModelChange={onFilterChange}
              />
            </TabContainer>
          </TabContent>
        )}
      </div>
    </>
  );
};

export default MeetingRoomTable;
