import { Navigate } from 'react-location';

import { BUSINESS_PARTNERS_PATHS, LOCALES_PATHS, SETTINGS_PATH } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import Locations, { CreateLocation } from '@pages/Locations';
import { getByPathAndParams } from '@services/BaseApi';

import { locationsPath, newPath, rootPath } from '../Routes';

const LocationRoute = (routeCache) => ({
  path: locationsPath,
  children: [
    {
      path: rootPath,
      element: <Locations />,
      loader: routeCache.createLoader(
        async () => ({
          config: await getByPathAndParams({
            path: SETTINGS_PATH.GET,
            pathVariables: { tile: EntityTypes.BUSINESS_PARTNER }
          }),
          countries: await getByPathAndParams({
            path: LOCALES_PATHS.COUNTRIES
          }),
          abcClassification: await getByPathAndParams({
            path: BUSINESS_PARTNERS_PATHS.ABC_CLASSIFICATION
          }),
          supplierTypes: await getByPathAndParams({
            path: BUSINESS_PARTNERS_PATHS.SUPPLIER_TYPES
          })
        }),
        { policy: 'cache-first' }
      )
    },
    {
      path: newPath,
      element: <CreateLocation />,
      loader: routeCache.createLoader(
        async () => ({
          supplierTypes: await getByPathAndParams({
            path: BUSINESS_PARTNERS_PATHS.SUPPLIER_TYPES
          }),
          roleTypes: await getByPathAndParams({
            path: BUSINESS_PARTNERS_PATHS.BUSINESS_PARTNER_ROLE_TYPES
          }),
          abcClassification: await getByPathAndParams({
            path: BUSINESS_PARTNERS_PATHS.ABC_CLASSIFICATION
          })
        }),
        { policy: 'cache-first' }
      )
    },
    {
      element: <Navigate to={locationsPath} />
    }
  ]
});

export default LocationRoute;
