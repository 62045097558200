import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { SORT_DIRECTION, dateFormatTime } from '@common/Constants';
import { EMPLOYEES_PATHS } from '@common/network/ApiPaths';
import { TABLE_OPTIONS } from '@config/network';
import { getByPathAndParams } from '@services/BaseApi';

export const initialState = {
  data: [],
  rows: [],
  filter: {
    search: '',
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0],
    sortBy: '',
    sortDirection: SORT_DIRECTION.ASCENDING,
    filters: null
  },
  totalPages: 0,
  totalElements: 0,
  isLoading: false,
  selectionModel: []
};

export const EMPLOYEE_PARTICIPATIONS_SLICE = 'employeeParticipations';

export const fetchParticipations = createAsyncThunk(
  `${EMPLOYEE_PARTICIPATIONS_SLICE}/participations`,
  async ({ entityId, filter }, { rejectWithValue }) => {
    try {
      const response = await getByPathAndParams({
        path: EMPLOYEES_PATHS.GET_PARTICIPATIONS,
        pathVariables: { id: entityId },
        params: filter
      });

      return response.data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const employeeParticipationsTableSlice = createSlice({
  name: EMPLOYEE_PARTICIPATIONS_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setFilterParams: (state, action) => {
      let newFilterValues = {};
      // Case when search value is reset to empty and search bar is closed
      if (action.payload.key === 'search' && !action.payload.value && !state.filter.search) {
        state.isLoading = false;
        return;
      }
      if (Array.isArray(action.payload)) {
        newFilterValues = action.payload.reduce(
          (obj, item) => ((obj[item.key] = item.value), obj),
          {}
        );
      } else {
        newFilterValues = { [action.payload.key]: action.payload.value };
      }
      state.filter = { ...state.filter, ...newFilterValues, page: newFilterValues.page ?? 0 };
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchParticipations.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = payload?.totalElements ?? state.totalElements;
        state.data = payload?.content.map((el) => ({
          ...el,
          startDate: moment(el.startDateTime).format(dateFormatTime.dateTime),
          endDate: moment(el.endDateTime).format(dateFormatTime.dateTime)
        }));
      })
      .addCase(fetchParticipations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchParticipations.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      });
  }
});
export const selectList = (state) => state.EMPLOYEE_PARTICIPATIONS_SLICE.data;
export const selectRows = (state) => state.cEMPLOYEE_PARTICIPATIONS_SLICE.rows;
export const selectTotalElements = (state) => state.EMPLOYEE_PARTICIPATIONS_SLICE.totalElements;
export const selectTotalPages = (state) => state.EMPLOYEE_PARTICIPATIONS_SLICE.totalPages;
export const selectFilter = (state) => state.EMPLOYEE_PARTICIPATIONS_SLICE.filter;
export const selectIsLoading = (state) => state.EMPLOYEE_PARTICIPATIONS_SLICE.isLoading;
export const selectSelectionModel = (state) => state.EMPLOYEE_PARTICIPATIONS_SLICE.selectionModel;

const { actions, reducer } = employeeParticipationsTableSlice;
export const { setData, setFilterParams, resetState, setSelectionModel } = actions;

export default reducer;
