import Localize from 'react-intl-universal';

const getEventsOptions = (eventsOption) => {
  return eventsOption?.map((option) => {
    if (option?.label) {
      return {
        ...option,
        key: option?.translateKey ? Localize.get(option.key) : option.key,
        label: option?.translateKey ? Localize.get(option.label) : option.label
      };
    }
    return Localize.get(option);
  });
};

export default getEventsOptions;
