const EntityTypes = Object.freeze({
  EVENT: 'EVENT',
  SESSION: 'SESSION',
  SESSION_DAY: 'SESSION_DAY',
  CONTACT: 'CONTACT',
  CONTINGENT: 'CONTINGENT',
  EMPLOYEE: 'EMPLOYEE',
  EVENT_SERIES: 'EVENT_SERIES',
  HOTEL: 'HOTEL',
  PARTICIPANT: 'PARTICIPANT',
  RESOURCE: 'RESOURCE',
  MEETING_ROOM: 'MEETING_ROOM',
  VENUE: 'VENUE',
  INDIVIDUAL_CUSTOMER: 'INDIVIDUAL_CUSTOMER',
  ACCOUNT: 'ACCOUNT',
  APPOINTMENT: 'APPOINTMENT',
  TASK: 'TASK',
  BUSINESS_PARTNER: 'BUSINESS_PARTNER',
  ILT_SESSION: 'ILT_SESSION',
  //ORG_STRUCTURE: 'ORG_STRUCTURE',
  ACTIVITY: 'ACTIVITY',
  LOCATION: 'LOCATION',
  NOTES: 'NOTES',
  ATTACHMENTS: 'ATTACHMENTS',
  PRICES: 'PRICES',
  ACCOMMODATION: 'ACCOMMODATION',
  WAITING_LIST: 'WAITING_LIST',
  TEAM: 'TEAM'
});

const UIEntityTypes = {
  [EntityTypes.EVENT]: { label: 'Event.Event' },
  [EntityTypes.SESSION]: { label: 'Labels.Sessions' },
  [EntityTypes.SESSION_DAY]: { label: 'IltSession.SessionDay' },
  [EntityTypes.CONTINGENT]: { label: 'Labels.Contingent' },
  [EntityTypes.CONTACT]: { label: 'ContactsTile.Contact' },
  [EntityTypes.EMPLOYEE]: { label: 'Employees.Item' },
  [EntityTypes.PARTICIPANT]: { label: 'ParticipantsTile.Item' },
  [EntityTypes.RESOURCE]: { label: 'ResourcesTile.Resource' },
  [EntityTypes.MEETING_ROOM]: { label: 'MeetingRooms.Item' },
  [EntityTypes.TASK]: { label: 'Labels.Task' },
  [EntityTypes.BUSINESS_PARTNER]: { label: 'BusinessPartners.Item' },
  [EntityTypes.ILT_SESSION]: { label: 'IltSession.Item' },
  [EntityTypes.ACTIVITY]: { label: 'Activities.Item' },
  [EntityTypes.LOCATION]: { label: 'Locations.Item' },
  [EntityTypes.NOTES]: { label: 'Notes.Title' },
  [EntityTypes.ATTACHMENTS]: { label: 'Labels.Attachments' },
  [EntityTypes.PRICES]: { label: 'ResourcesTile.Price' },
  [EntityTypes.ACCOMMODATION]: { label: 'Labels.Accommodations' },
  [EntityTypes.HOTEL]: { label: 'IltSession.HotelContingents' },
  [EntityTypes.WAITING_LIST]: { label: 'IltSession.WaitList' },
  [EntityTypes.TEAM]: { label: 'IltSession.Teams' }
};

export default EntityTypes;
export { UIEntityTypes };
