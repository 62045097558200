import { filter, isNil, omit } from 'lodash';
import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  ACTION_MODES,
  dateInitFormats,
  SORT_DIRECTION,
  NAVIGATE_TOASTER_TIMEOUT,
  TAB_KEYS
} from '@common/Constants';
import { deepMerge } from '@common/helpers/deepMerge';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { ILT_SESSION_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { closeSidebar } from '@components/RightSidebar/rightSidebarSlice';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { TABLE_OPTIONS } from '@config/network';
import { setDetails as setIltSessionDetails } from '@pages/IltSession/iltSessionSlice';
import {
  deleteByPath,
  getByPathAndParams,
  postByPathAndData,
  patchByPathAndData
} from '@services/BaseApi';

import { fetchParticipants } from '../ParticipantsTable/participantTableSlice';

export const initialState = {
  data: [],
  rows: [],
  selectedId: null,
  filter: {
    search: '',
    sortBy: '',
    sortDirection: SORT_DIRECTION.ASCENDING,
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0],
    filters: null
  },
  totalPages: 0,
  details: null,
  totalElements: 0,
  isLoading: false,
  selectionModel: [],
  isPopupOpen: false,
  anchorEl: null,
  isDetailsLoading: false
};

export const REGISTERED_TYPE_ID = 2;

export const ILT_SESSION_WAITING_LIST_SLICE = 'iltSessionWaitingList';

export const fetchWaitingLists = createAsyncThunk(
  `${ILT_SESSION_WAITING_LIST_SLICE}/fetchWaitingLists`,
  ({ entityId, filter }, { rejectWithValue }) => {
    return getByPathAndParams({
      path: ILT_SESSION_PATHS.GET_WAITING_LISTS,
      pathVariables: { iltSessionId: entityId },
      params: filter
    })
      .then((response) => response.data)
      .catch((error) => {
        return rejectWithValue(error.response);
      });
  }
);

export const updateStatus = createAsyncThunk(
  `${ILT_SESSION_WAITING_LIST_SLICE}/updateStatus`,
  (
    { iltSessionId, participantIds, participantStatusId },
    { rejectWithValue, dispatch, getState }
  ) => {
    dispatch(setPopupIsClose());
    return patchByPathAndData({
      path: ILT_SESSION_PATHS.PATCH_BULK_WAITING_LIST,
      pathVariables: { iltSessionId, participantIds, participantStatusId }
    })
      .then(async (response) => {
        const { totalElements = 0 } = await dispatch(
          fetchWaitingLists({
            entityId: iltSessionId,
            filter: getState().ILT_SESSION_PARTICIPANT_SLICE.filter
          })
        ).unwrap();

        const { totalElements: totalElementsParticipant = 0 } = await dispatch(
          fetchParticipants({
            entityId: iltSessionId,
            filter: getState().ILT_SESSION_PARTICIPANT_SLICE.filter
          })
        ).unwrap();
        dispatch(
          setIltSessionDetails({
            counts: {
              [TAB_KEYS.WAITING_LIST]: totalElements,
              [TAB_KEYS.PARTICIPANTS]: totalElementsParticipant
            }
          })
        );
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.WAITING_LIST, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );

        dispatch(fetchWaitingLists({ entityId: iltSessionId, filter }));
        dispatch(fetchParticipants({ entityId: iltSessionId, filter }));

        return response?.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.WAITING_LIST, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
        return rejectWithValue(error?.response);
      });
  }
);

export const saveParticipant = createAsyncThunk(
  `${ILT_SESSION_WAITING_LIST_SLICE}/saveParticipants`,
  ({ entityId, participantId, data }, { dispatch, rejectWithValue, getState }) => {
    const { arrivalDate, departureDate } = getState().ILT_SESSION_WAITING_LIST_SLICE.details;

    if (data?.status?.id) {
      data.statusId = data?.status?.id;
    }

    if ((data.arrivalDate || data.departureDate) && data.hotelRequest !== false) {
      data.hotelRequest = true;
      data.arrivalDate = data.arrivalDate
        ? moment(data.arrivalDate).format(moment.HTML5_FMT.DATE)
        : moment(arrivalDate).format(moment.HTML5_FMT.DATE);
      data.departureDate = data.departureDate
        ? moment(data.departureDate).format(moment.HTML5_FMT.DATE)
        : moment(departureDate).format(moment.HTML5_FMT.DATE);
    } else if (data.hotelRequest === false) {
      data.arrivalDate = null;
      data.departureDate = null;
    }

    if (data?.status?.id) {
      data.statusId = data?.status?.id;
    }

    // statusID for waiting cancelled process === 8
    if (data?.cancellationReason && (data?.statusId === 8 || !data?.statusId)) {
      data.cancellationReasonId = data?.cancellationReason.id;
    }

    return patchByPathAndData({
      path: ILT_SESSION_PATHS.PATCH_WAITING_LIST,
      pathVariables: { iltSessionId: entityId, participantId },
      data: { eventParticipant: { ...omit(data, 'status', 'cancellationReason', 'currency') } }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.PARTICIPANT, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );

        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.PARTICIPANT, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error);
      });
  }
);

export const deleteWaitingList = createAsyncThunk(
  `${ILT_SESSION_WAITING_LIST_SLICE}/delete`,
  ({ participantIds, id }, { dispatch, rejectWithValue, getState }) => {
    return deleteByPath({
      path: ILT_SESSION_PATHS.DELETE_PARTICIPANTS,
      pathVariables: { id, participantIds }
    })
      .then(async (response) => {
        const { totalElements = 0 } = await dispatch(
          fetchWaitingLists({
            entityId: id,
            filter: getState().ILT_SESSION_PARTICIPANT_SLICE.filter
          })
        ).unwrap();
        dispatch(setIltSessionDetails({ counts: { [TAB_KEYS.WAITING_LIST]: totalElements } }));
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.WAITING_LIST, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );

        return { response, participantIds };
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.WAITING_LIST, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error?.response);
      });
  }
);

export const createWaitingList = createAsyncThunk(
  `${ILT_SESSION_WAITING_LIST_SLICE}/create`,
  (data, { dispatch, rejectWithValue, getState }) => {
    const { selectedId } = getState().ILT_SESSION_SLICE;
    const { filter } = getState().ILT_SESSION_PARTICIPANT_SLICE;
    const { details } = getState().ILT_SESSION_SLICE;

    return postByPathAndData({
      path: ILT_SESSION_PATHS.POST_WAITING_LIST.replace(':id', selectedId),
      data: {
        personId: data.personDto?.id,
        companyId: data?.company?.id,
        invoiceCompanyId: data?.invoiceCompany?.id,
        statusId: data?.status,
        cancellationReasonId: data?.cancellationReason?.id,
        resultId: data?.result?.id,
        nativeLanguageId: data?.nativeLanguage?.id,
        ...omit(
          data,
          'addressDtos',
          'phoneDtos',
          'emailDtos',
          'personDto',
          'company',
          'iltSession',
          'invoiceCompany',
          'result',
          'status',
          'arrivalDate',
          'departureDate'
        ),
        arrivalDate:
          data?.hotelRequest === false
            ? null
            : moment(data.arrivalDate).format(moment.HTML5_FMT.DATE),
        departureDate:
          data?.hotelRequest === false
            ? null
            : moment(data.departureDate).format(moment.HTML5_FMT.DATE)
      }
    })
      .then((response) => {
        setTimeout(() => {
          dispatch(
            showSnackbar({
              message: successMessageFormatter(EntityTypes.WAITING_LIST, ACTION_MODES.Create),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
        }, NAVIGATE_TOASTER_TIMEOUT);
        dispatch(fetchWaitingLists({ entityId: selectedId, filter: filter }));

        dispatch(
          setIltSessionDetails({
            ...details,
            counts: {
              ...details.counts,
              waiting_list: response.data.counts.waiting_list
            }
          })
        );
        return response.data;
      })
      .catch((error) => rejectWithValue(error.response));
  }
);

export const iltSessionWaitingListTableSlice = createSlice({
  name: ILT_SESSION_WAITING_LIST_SLICE,
  initialState,
  reducers: {
    setDetails: (state, { payload }) => {
      state.details = payload;
    },
    setPopupIsOpen: (state, { payload }) => {
      state.anchorEl = payload;
      state.isPopupOpen = true;
    },
    setPopupIsClose: (state) => {
      state.anchorEl = null;
      state.isPopupOpen = false;
    },
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setFilterParams: (state, action) => {
      const newFilterValues = Array.isArray(action.payload)
        ? action.payload.reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {})
        : { [action.payload.key]: action.payload.value };

      state.filter = {
        ...state.filter,
        ...newFilterValues,
        page: action.payload.page ?? 0
      };
      state.isLoading = !!(action.payload.key === 'search' && !action.payload.value);
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchWaitingLists.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.data = payload?.content.map((el) => ({
          ...el,
          registrationDatetime: moment(el.registrationDatetime).format(dateInitFormats.basicDate),
          departureDate: el.departureDate
            ? moment(el.departureDate).format(dateInitFormats.basicDate)
            : null,
          arrivalDate: el.arrivalDate
            ? moment(el.arrivalDate).format(dateInitFormats.basicDate)
            : null
        }));
      })
      .addCase(fetchWaitingLists.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchWaitingLists.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      .addCase(deleteWaitingList.fulfilled, (state) => {
        state.selectionModel = [];
      })
      //update
      .addCase(updateStatus.fulfilled, (state, { payload, meta }) => {
        state.data = state.data.map((item) => {
          return meta.arg.participantIds.includes(item.id)
            ? deepMerge(item, { status: payload[0].status })
            : item;
        });
        state.details = deepMerge(state.details, payload);
        state.isLoading = false;
      })
      .addCase(updateStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateStatus.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(saveParticipant.pending, (state) => {
        state.isDetailsLoading = true;
      })
      .addCase(saveParticipant.fulfilled, (state, action) => {
        const {
          payload: { iltSessionParticipantResponseDto }
        } = action;
        const isPriceAdded = iltSessionParticipantResponseDto?.currency;
        let priceData = {};

        // Status changed from cancelled to registered, and BE returned price with currency so
        //  we need to update details with new standard price, advertisingSubsidyPrice, and currency
        if (isPriceAdded) {
          priceData = {
            currency: iltSessionParticipantResponseDto?.currency,
            standardPrice: iltSessionParticipantResponseDto?.standardPrice,
            advertisingSubsidyPrice: iltSessionParticipantResponseDto?.advertisingSubsidyPrice
          };
        }

        state.details = deepMerge(
          isPriceAdded ? { ...state.details, ...priceData } : state.details,
          action.meta.arg.data
        );

        state.isDetailsLoading = false;
        state.data = state.data.map((item) => {
          return item.id === action.meta.arg.participantId
            ? deepMerge(isPriceAdded ? { ...item, ...priceData } : item, action.meta.arg.data)
            : item;
        });
      })
      .addCase(saveParticipant.rejected, (state) => {
        state.isDetailsLoading = false;
      })
      .addCase(closeSidebar, (state) => {
        state.details = null;
      });
  }
});

export const selectAnchorEl = (state) => state.ILT_SESSION_WAITING_LIST_SLICE.anchorEl;
export const selectIsPopupOpen = (state) => state.ILT_SESSION_WAITING_LIST_SLICE.isPopupOpen;

export const selectId = (state) => state.ILT_SESSION_SLICE.selectedId;
export const selectList = (state) => state.ILT_SESSION_WAITING_LIST_SLICE.data;
export const selectRows = (state) => state.ILT_SESSION_WAITING_LIST_SLICE.rows;
export const selectTotalElements = (state) => state.ILT_SESSION_WAITING_LIST_SLICE.totalElements;
export const selectTotalPages = (state) => state.ILT_SESSION_WAITING_LIST_SLICE.totalPages;
export const selectFilter = (state) => state.ILT_SESSION_WAITING_LIST_SLICE.filter;
export const selectIsLoading = (state) => state.ILT_SESSION_WAITING_LIST_SLICE.isLoading;
export const selectSelectionModel = (state) => state.ILT_SESSION_WAITING_LIST_SLICE.selectionModel;
export const selectDetails = (state) => state.ILT_SESSION_WAITING_LIST_SLICE.details;
export const selectIsDetailsLoading = (state) =>
  state.ILT_SESSION_WAITING_LIST_SLICE.isDetailsLoading;

const { actions, reducer } = iltSessionWaitingListTableSlice;

export const {
  setPopupIsClose,
  setPopupIsOpen,
  setData,
  setFilterParams,
  resetState,
  setSelectionModel,
  setDetails
} = actions;

export default reducer;
