import { memo, useCallback, useEffect, useRef } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useMatch, useSearch } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import Icon from '@mui/material/Icon';
import Link from '@mui/material/Link';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TablePagination from '@mui/material/TablePagination';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles, styled } from '@mui/styles';

import { ACTION_MODES, SORTING_ORDER, TAB_KEYS } from '@common/Constants';
import { replaceAll } from '@common/helpers/string';
import { onTabsTableFilterChange } from '@common/helpers/tables/getAdvancedFiltersForMUITable';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import { saveState } from '@common/storage/persistSlice';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import ToolbarButton from '@components/ToolbarButton';
import { getFilterOperatorsBasedOnType } from '@config/filterOperators';
import { TABLE_OPTIONS } from '@config/network';
import { iltSessionsSessionDayPath } from '@navigation/routes/Routes';
import {
  selectDetails,
  selectFilter as selectIltSessionFilter,
  selectId as selectSessionId
} from '@pages/IltSession/iltSessionSlice';

import { CreateSessionDay } from '../../SessionDay';
import { exportTypesConfig } from '../ParticipantsTable/ParticipantsTable';
import {
  fetchSessionDays,
  selectIsLoading,
  selectList,
  selectSelectionModel,
  selectTotalElements,
  setFilterParams,
  setSelectionModel,
  resetState,
  selectFilter,
  setExportPopupOpened,
  setExportPopupClosed,
  selectExportAnchor,
  selectIsExportPopupOpen,
  generateReport,
  selectIsExportLoading
} from './sessionDaysSlice';

const StyledMenu = styled(Menu)(() => ({
  '& .MuiMenu-paper': {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    marginTop: '0.6rem'
  }
}));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  container: {
    height: 400,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  }
}));

const SessionsDayTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarButtons = []
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { params } = useMatch();
  const search = useSearch();
  const trackFilterModel = useRef(null);

  const details = useSelector(selectDetails);
  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const sessionsFilter = useSelector(selectIltSessionFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const selectionModel = useSelector(selectSelectionModel);
  const exportAnchor = useSelector(selectExportAnchor);
  const isExportPopupOpen = useSelector(selectIsExportPopupOpen);
  const isExportLoading = useSelector(selectIsExportLoading);
  const sessionId = useSelector(selectSessionId);

  useEffect(() => {
    if (entityId) {
      dispatch(fetchSessionDays({ filter: { ...filter, sessionId: entityId } }));
    }
  }, [
    entityId,
    filter?.size,
    filter?.page,
    filter?.sortBy,
    filter?.sortDirection,
    filter?.filters
  ]);

  useEffect(() => {
    if (sessionId) {
      dispatch(
        setFilterParams([
          { key: 'page', value: 0 },
          { key: 'sessionId', value: sessionId }
        ])
      );
    }
  }, [sessionId]);

  useEffect(() => {
    if (params?.sessionId) {
      dispatch(
        setFilterParams([
          { key: 'page', value: 0 },
          { key: 'sessionId', value: params.sessionId }
        ])
      );
    }
  }, [params.sessionId]);

  const onPageChange = useCallback(
    (e, value) => dispatch(setFilterParams({ key: 'page', value })),
    []
  );

  const onPageSizeChange = useCallback(({ target }) => {
    dispatch(
      setFilterParams([
        { key: 'page', value: 0 },
        { key: 'size', value: target.value }
      ])
    );
  }, []);

  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const onRowClick = ({ id }) => {
    const sessionIdentifier = sessionId || params?.sessionId;

    if (sessionIdentifier) {
      const replaceMap = { ':sessionId': sessionIdentifier, ':id': id };
      const path = replaceAll(iltSessionsSessionDayPath, replaceMap);
      dispatch(saveState({ sessionsFilter, sessionsSelectedId: sessionIdentifier }));
      navigate({
        to: path,
        search: () => ({ activeTab: TAB_KEYS.OVERVIEW }),
        replace: false
      });
    } else {
      alert('Invalid session identifier');
    }
  };

  const onMenuItemClick = (item) => dispatch(generateReport({ id: entityId, type: item.type }));

  const handleClose = () => dispatch(setExportPopupClosed());

  const onToolbarButtonClick = (name, event) => {
    switch (name) {
      case 'add_sessions': {
        if (event.length > 1) {
          alert('Batch upload currently not supported');
          break;
        }

        const sessionIdentifier = sessionId || params?.sessionId;
        if (sessionIdentifier) {
          navigate({
            search: (previousUrlParams) => ({ ...previousUrlParams, mode: ACTION_MODES.Create })
          });
        } else {
          alert('Invalid session identifier');
        }
        break;
      }

      case 'delete': {
        if (selectionModel.length > 1) {
          alert('Batch delete currently not supported');
          break;
        }
        break;
      }

      case 'export':
        dispatch(setExportPopupOpened(event.currentTarget));
        break;

      default:
        break;
    }
  };

  const isToolbarButtonDisabled = (button) => {
    switch (button) {
      case 'add_sessions':
        return !(sessionId && entityType) && !params?.sessionId;
      case 'delete':
        return true;
      case 'export':
        return isExportLoading || !entityId || !data?.length;
      default:
        return false;
    }
  };

  const renderTablePagination = useCallback(
    (props) => {
      return (
        <TablePagination
          sx={{ borderBottom: 'none' }}
          rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
          rowsPerPage={filter?.size}
          page={filter?.page}
          count={totalElements}
          onPageChange={onPageChange}
          onRowsPerPageChange={onPageSizeChange}
          labelDisplayedRows={({ from, to, count }) => {
            return <span>{`${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`}</span>;
          }}
          backIconButtonProps={
            isLoading
              ? {
                  disabled: isLoading
                }
              : undefined
          }
          nextIconButtonProps={
            isLoading
              ? {
                  disabled: isLoading
                }
              : undefined
          }
          {...props}
        />
      );
    },
    [filter.page, filter.size, isLoading]
  );

  const onFilterChange = (values) => {
    const advancedFilters = onTabsTableFilterChange(values, trackFilterModel, () =>
      dispatch(setFilterParams({ key: 'filters', value: null }))
    );
    if (advancedFilters) dispatch(setFilterParams({ key: 'filters', value: { advancedFilters } }));
  };

  const onSortChange = (sortModel) =>
    dispatch(
      setFilterParams([
        { key: 'sortBy', value: sortModel[0]?.field },
        { key: 'sortDirection', value: sortModel[0]?.sort }
      ])
    );

  return (
    <div className={classes.root}>
      {search?.mode === ACTION_MODES.Create ? (
        <CreateSessionDay session={details} />
      ) : (
        <TabContent>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('Labels.Sessions')}
            </Typography>
            <Toolbar className={classes.toolbar}>
              {toolbarButtons?.map((button, index) => (
                <ToolbarButton
                  key={index}
                  button={button}
                  isDisabled={button.disabled || isToolbarButtonDisabled(button.name)}
                  onToolbarButtonClick={onToolbarButtonClick}
                />
              ))}
            </Toolbar>
          </div>
          <TabContainer>
            <StyledDataGrid
              loading={isLoading}
              rows={data}
              getRowClassName={() => classes.tableRow}
              columns={[
                ...columns.map((column) => ({
                  ...column,
                  headerName: Localize.get(column.headerName),
                  filterOperators: getFilterOperatorsBasedOnType(column?.type),
                  renderCell: (params) => (
                    <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                      {['meetingRoomName', 'venueName'].includes(params.field) ? (
                        <Tooltip title={params.value} arrow>
                          <Typography
                            noWrap
                            variant="body"
                            component="div"
                            sx={{
                              display: 'block',
                              cursor: 'pointer',
                              maxWidth: params?.colDef?.width - 10 // 10 px for three dots,
                            }}
                          >
                            {params?.value}
                          </Typography>
                        </Tooltip>
                      ) : params.field === 'name' ? (
                        <Tooltip title={params.value} arrow>
                          <Link
                            sx={{
                              display: 'block',
                              cursor: 'pointer',
                              maxWidth: params?.colDef?.width
                            }}
                            onClick={() => onRowClick(params)}
                          >
                            {params?.value}
                          </Link>
                        </Tooltip>
                      ) : (
                        <Typography variant="body" component="div">
                          {params.value}
                        </Typography>
                      )}
                    </div>
                  )
                }))
              ]}
              localeText={getLocalesText(Localize)}
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              checkboxSelection
              disableColumnMenu
              rowCount={totalElements}
              disableSelectionOnClick
              components={{
                Toolbar: TableToolbar,
                Pagination: (props) => (
                  <table>
                    <tbody>
                      <tr>{renderTablePagination(props)}</tr>
                    </tbody>
                  </table>
                )
              }}
              sortingOrder={SORTING_ORDER}
              onSortModelChange={onSortChange}
              onFilterModelChange={onFilterChange}
              onSelectionModelChange={(values) => dispatch(setSelectionModel(values))}
            />
            <StyledMenu anchorEl={exportAnchor} open={isExportPopupOpen} onClose={handleClose}>
              {exportTypesConfig.map((el) => (
                <MenuItem key={el.label} onClick={() => onMenuItemClick(el)}>
                  <ListItemIcon>
                    <Icon fontSize="small">{el.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText>{Localize.get(el.label)}</ListItemText>
                </MenuItem>
              ))}
            </StyledMenu>
          </TabContainer>
        </TabContent>
      )}
    </div>
  );
};

export default memo(SessionsDayTable);
