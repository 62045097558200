import Localize from 'react-intl-universal';

import * as yup from 'yup';

export default () => {
  return yup.object().shape({
    hotelRequest: yup.boolean(),
    arrivalDate: yup.date().when('hotelRequest', {
      is: true,
      then: yup
        .date()
        .nullable()
        .max(yup.ref('departureDate'), Localize.get('ValidationMessages.ArrivalDepartureRule'))
        .required(Localize.get('ValidationMessages.DateRequired'))
        .typeError(Localize.get('ValidationMessages.DateInvalid'))
    }),
    departureDate: yup.date().when('hotelRequest', {
      is: true,
      then: yup
        .date()
        .nullable()
        .min(yup.ref('arrivalDate'), Localize.get('ValidationMessages.DepartureArrivalRule'))
        .required(Localize.get('ValidationMessages.DateRequired'))
        .typeError(Localize.get('ValidationMessages.DateInvalid'))
    }),
    person: yup.object().shape({
      id: yup.number().nullable(true).required(Localize.get('ValidationMessages.PersonRequired'))
    })
  });
};
