import { useEffect, useState, useMemo, useRef, useCallback } from 'react';
import Localize from 'react-intl-universal';
import { useMatch } from 'react-location';
import { useDispatch, useSelector } from 'react-redux';

import { get } from 'lodash';

import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { DataGrid } from '@mui/x-data-grid';

import { SORTING_ORDER } from '@common/Constants';
import { isJsonString } from '@common/helpers/string';
import { onTabsTableFilterChange } from '@common/helpers/tables/getAdvancedFiltersForMUITable';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import EntityTypes from '@common/network/EntityTypes';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import noteTypeConfig, { IltSessionNotes } from '@components/NotesTable/noteTypesConfig';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import StyledMenu from '@components/StyledMenuItem/StyledMenu';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import ToolbarButton from '@components/ToolbarButton';
import {
  getFilterOperatorsBasedOnType,
  getFilterValueOptionsBasedOnType
} from '@config/filterOperators';
import { TABLE_OPTIONS } from '@config/network';

import NotesDialog from './components/NoteDialog/NoteDialog';
import {
  resetState,
  selectIsLoading,
  selectData,
  selectSelectionModel,
  selectIsNotesDialogOpen,
  selectSelectedNote,
  selectError,
  selectNotesType,
  setSelectionModel,
  selectFilter,
  selectTotalElements,
  openNotesDialog,
  closeNotesDialog,
  setNotesType,
  getNotes,
  createNote,
  updateNote,
  removeNote,
  setFilterParams,
  selectDataTypeIdArr
} from './notesTableSlice';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  container: {
    height: 400,
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.grey[400]} !important`,
      cursor: 'pointer'
    }
  }
}));

const readStyledText = (params) => {
  let text = get(params.row, params.field);
  if (isJsonString(text)) {
    return get(JSON.parse(text), 'blocks[0].text');
  } else {
    return text;
  }
};

const NotesTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarButtons = [],
  setDetails = () => {}
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {
    data: {
      noteTypes: { data: noteTypesList }
    }
  } = useMatch();
  const trackFilterModel = useRef(null);

  const isLoading = useSelector(selectIsLoading);
  const data = useSelector(selectData);
  const selectionModel = useSelector(selectSelectionModel);
  const isNotesDialogOpen = useSelector(selectIsNotesDialogOpen);
  const selectedNote = useSelector(selectSelectedNote);
  const error = useSelector(selectError);
  const notesType = useSelector(selectNotesType);
  const filter = useSelector(selectFilter);
  const totalElements = useSelector(selectTotalElements);
  const dataTypeIdArr = useSelector(selectDataTypeIdArr);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const entityNotesConfig = useMemo(() => {
    return EntityTypes.ILT_SESSION === entityType ? [...IltSessionNotes] : [...noteTypeConfig];
  }, [entityType]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, []);

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(getNotes({ entityId, entityType, filter }));
    }
  }, [
    entityId,
    entityType,
    filter?.size,
    filter?.page,
    filter?.sortBy,
    filter?.sortDirection,
    filter?.filters,
    totalElements
  ]);

  useEffect(() => {
    if (error) {
      const message = error.message;
      dispatch(
        showSnackbar({
          message: message,
          severity: SnackbarSeverityTypes.ERROR
        })
      );
    }
  }, [error]);

  const onToolbarButtonClick = (name, event) => {
    switch (name) {
      case 'add_note':
        setAnchorEl(event.currentTarget);
        return;
      case 'delete': {
        if (selectionModel.length > 1) {
          alert('Batch delete currently not supported');
          return;
        }

        dispatch(
          openConfirmDialog({
            title: Localize.get('ConfirmationMessages.Delete', {
              item: Localize.get('Labels.Note')?.toLowerCase()
            }),
            confirmButton: Localize.get('Buttons.Delete'),
            cancelButton: Localize.get('Buttons.Cancel')
          })
        )
          .unwrap()
          .then((result) => {
            if (result === CONFIRM_ACTIONS.Confirm) {
              dispatch(
                removeNote({
                  entityId,
                  entityType,
                  id: selectionModel[0],
                  setDetails,
                  noteTypeId: data.find((el) => el.id === selectionModel[0])?.type?.id
                })
              );
            }
          });
        return;
      }

      default:
        return false;
    }
  };

  const onMenuClick = (item) => {
    setAnchorEl(null);

    dispatch(setNotesType(item));
    dispatch(openNotesDialog());
  };

  const isToolbarButtonDisabled = (fieldName) => {
    switch (fieldName) {
      case 'add_note':
        return !entityId || !entityType;
      case 'delete':
        return selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onNotesDialogClose = (value, id) => {
    if (!value) {
      dispatch(closeNotesDialog());
      return;
    }

    id
      ? dispatch(
          updateNote({
            postData: {
              entityId,
              noteId: id,
              entityType,
              text: value,
              type: selectedNote.type.value
            }
          })
        )
      : dispatch(
          createNote({
            postData: {
              entityId,
              entityType,
              text: value,
              type: notesType
            },
            setDetails,
            noteTypeId: entityNotesConfig.find((el) => el.type === notesType)?.id
          })
        );
  };

  const onFilterChange = (values) => {
    const advancedFilters = onTabsTableFilterChange(values, trackFilterModel, () =>
      dispatch(setFilterParams({ key: 'filters', value: null }))
    );
    if (advancedFilters) dispatch(setFilterParams({ key: 'filters', value: { advancedFilters } }));
  };

  const onSortChange = (sortModel) =>
    dispatch(
      setFilterParams([
        { key: 'sortBy', value: sortModel[0]?.field },
        { key: 'sortDirection', value: sortModel[0]?.sort }
      ])
    );

  const getSingleSelectValueOptions = useCallback((field) => {
    switch (field) {
      case 'type': {
        return noteTypesList
          .sort((a, b) => a.value.localeCompare(b.value))
          .map(({ value }) => value);
      }
      default:
        return [];
    }
  }, []);

  return (
    <div className={classes.root}>
      <TabContent>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6" component="h2" color="primary">
            {Localize.get('Labels.Notes')}
          </Typography>
          <Toolbar className={classes.toolbar}>
            {toolbarButtons?.map((button, index) => (
              <ToolbarButton
                key={index}
                button={button}
                isDisabled={button.disabled || isToolbarButtonDisabled(button.name)}
                onToolbarButtonClick={onToolbarButtonClick}
              />
            ))}
          </Toolbar>
        </div>
        <TabContainer>
          <DataGrid
            loading={isLoading}
            rows={data}
            getRowClassName={() => classes.tableRow}
            columns={[
              ...columns.map((column) => ({
                ...column,
                headerName: Localize.get(column.headerName),
                filterOperators: getFilterOperatorsBasedOnType(column?.type),
                valueOptions: getFilterValueOptionsBasedOnType(column, getSingleSelectValueOptions),
                renderCell: (params) => (
                  <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                    {params.field === 'text' ? readStyledText(params) : params?.row?.type?.value}
                  </div>
                )
              }))
            ]}
            disableColumnMenu
            checkboxSelection
            disableSelectionOnClick
            components={{
              Toolbar: () => <TableToolbar />
            }}
            onSelectionModelChange={(values) => dispatch(setSelectionModel(values))}
            onRowClick={({ row }) => dispatch(openNotesDialog(row))}
            localeText={getLocalesText(Localize)}
            initialState={{
              pagination: {
                pageSize: 1,
                rowCount: totalElements,
                page: filter?.page
              }
            }}
            paginationMode="server"
            pagination
            sortingMode="server"
            filterMode="server"
            page={filter?.page}
            pageSize={filter?.size}
            rowCount={totalElements}
            rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
            sortingOrder={SORTING_ORDER}
            onPageChange={(page) =>
              dispatch(setFilterParams({ key: 'page', value: page >= 0 ? page : 0 }))
            }
            onPageSizeChange={(value) => dispatch(setFilterParams([{ key: 'size', value }]))}
            onSortModelChange={onSortChange}
            onFilterModelChange={onFilterChange}
          />

          <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
            {entityNotesConfig.map((el) => (
              <MenuItem
                key={el.type}
                onClick={() => onMenuClick(el?.type)}
                disabled={dataTypeIdArr.includes(el?.id)}
              >
                <ListItemIcon>
                  <Icon fontSize="small">{el.icon}</Icon>
                </ListItemIcon>
                <ListItemText> {Localize.get(`Notes.${el?.type}`)}</ListItemText>
              </MenuItem>
            ))}
          </StyledMenu>
        </TabContainer>
      </TabContent>

      <NotesDialog
        note={selectedNote}
        open={isNotesDialogOpen}
        onClose={onNotesDialogClose}
        type={notesType}
      />
    </div>
  );
};

export default NotesTable;
