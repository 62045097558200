import React, { useEffect, useState } from 'react';
import Localize from 'react-intl-universal';
import { useNavigate, useSearch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { ACTION_MODES } from '@common/Constants';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import { DIALOG_PATHS } from '@common/network/ApiPaths';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import StyledMenu from '@components/StyledMenuItem/StyledMenu';
import TabContainer from '@components/TabContainer';
import TableToolbar from '@components/TableToolbar';
import TokenValueArrayDialog from '@components/TokenValueArrayDialog/components/TokenValueArrayDialog';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import ToolbarButton from '@components/ToolbarButton';
import { TABLE_OPTIONS } from '@config/network';

import CreateTask from './CreateTask/CreateTask';
import dialogColumns from './dialogColumnsConfig';
import {
  deleteTasks,
  fetchTasks,
  resetState,
  saveTasks,
  selectAnchorEl,
  selectFilter,
  selectIsLoading,
  selectIsPopupOpen,
  selectList,
  selectSelectionModel,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  setPopupIsClose,
  setPopupIsOpen,
  setSelectionModel
} from './tasksTableSlice';
import taskTypeConfig from './taskTypesConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  content: {
    margin: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}));

const TasksTable = ({ columns = [], entityId = null, entityType = null, toolbarButtons = [] }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useSearch();

  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);

  const anchorEl = useSelector(selectAnchorEl);
  const isPopupOpen = useSelector(selectIsPopupOpen);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleClose = () => {
    dispatch(setPopupIsClose());
  };

  const onMenuClick = (item) => {
    if (item === taskTypeConfig[0].label) {
      setIsModalOpen(true);
      return;
    }
    dispatch(setPopupIsClose());

    navigate({
      search: (previousUrlParams) => ({ ...previousUrlParams, mode: ACTION_MODES.Create })
    });
  };

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(fetchTasks({ entityId, filter }));
    }
  }, [entityId, filter?.page, filter?.sortBy, filter?.sortDirection, filter?.size]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarButtonDisabled = (name) => {
    switch (name) {
      case 'add_tasks':
        return !entityId || !entityType;
      case 'delete':
        return selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onToolbarButtonClick = (name, event) => {
    switch (name) {
      case 'add_tasks':
        dispatch(setPopupIsOpen(event.currentTarget));
        break;
      case 'delete':
        {
          dispatch(
            openConfirmDialog({
              title: Localize.get('ConfirmationMessages.Delete', {
                item: Localize.get('Events.SingularOrPluralTask', {
                  selectionModel: selectionModel.length
                })?.toLowerCase()
              }),
              confirmButton: Localize.get('Buttons.Delete'),
              cancelButton: Localize.get('Buttons.Cancel')
            })
          )
            .unwrap()
            .then((result) => {
              if (result === CONFIRM_ACTIONS.Confirm) {
                dispatch(deleteTasks({ taskIds: selectionModel, id: entityId }));
              }
            });
        }
        return true;
      default:
        return false;
    }
  };

  const selectItem = (items) => {
    dispatch(saveTasks({ ids: items, eventId: entityId }));
  };

  return (
    <>
      <div className={classes.root}>
        {search?.mode === ACTION_MODES.Create ? (
          <div>
            <CreateTask />
          </div>
        ) : (
          <Paper className={classes.content}>
            <div className={classes.toolbarContainer}>
              <Typography variant="h6" component="h6" color="primary">
                {Localize.get('Labels.Tasks')}
              </Typography>
              <Toolbar className={classes.toolbar}>
                {toolbarButtons?.map((button, index) => (
                  <ToolbarButton
                    key={index}
                    button={button}
                    isDisabled={button.disabled || isToolbarButtonDisabled(button.name)}
                    onToolbarButtonClick={onToolbarButtonClick}
                  />
                ))}
              </Toolbar>
            </div>
            <TabContainer>
              <StyledDataGrid
                keepNonExistentRowsSelected={true}
                loading={isLoading}
                rows={data}
                columns={[
                  ...columns.map((column) => ({
                    ...column,
                    headerName: Localize.get(column.headerName),
                    renderCell: (params) => (
                      <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                        {[
                          'subject',
                          'processor',
                          'primaryContact',
                          'account',
                          'taskCategory'
                        ].includes(params.field) ? (
                          <Tooltip title={params.value} arrow>
                            <Typography
                              variant="body"
                              component="div"
                              sx={{
                                maxWidth: params?.colDef?.width - 10, // 10 px for three dots
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis'
                              }}
                            >
                              {params.value}
                            </Typography>
                          </Tooltip>
                        ) : (
                          <Typography variant="body" component="div">
                            {params.value}
                          </Typography>
                        )}
                      </div>
                    )
                  }))
                ]}
                initialState={{
                  pagination: {
                    pageSize: totalPages,
                    rowCount: totalElements,
                    page: filter?.page
                  }
                }}
                onSelectionModelChange={(newSelectionModel) => {
                  dispatch(setSelectionModel(newSelectionModel));
                }}
                selectionModel={selectionModel}
                checkboxSelection
                localeText={getLocalesText(Localize)}
                pagination
                paginationMode="server"
                disableColumnMenu
                page={filter?.page}
                pageSize={filter?.size}
                rowCount={totalElements}
                rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
                disableSelectionOnClick
                components={{
                  Toolbar: () => <TableToolbar />
                }}
                onPageChange={(page) => dispatch(setFilterParams({ page, selectionModel }))}
                onPageSizeChange={(value) =>
                  dispatch(setFilterParams([{ key: 'size', value, selectionModel }]))
                }
              />
              <StyledMenu anchorEl={anchorEl} open={isPopupOpen} onClose={handleClose}>
                {taskTypeConfig.map((el, index) => (
                  <MenuItem key={el.label} onClick={() => onMenuClick(el.label, index)}>
                    <ListItemIcon>
                      <Icon fontSize="small">{el.icon}</Icon>
                    </ListItemIcon>
                    <ListItemText>{Localize.get(el.label)}</ListItemText>
                  </MenuItem>
                ))}
              </StyledMenu>
            </TabContainer>
          </Paper>
        )}

        <TokenValueArrayDialog
          selectItem={selectItem}
          modalOpen={isModalOpen}
          setModalOpen={setIsModalOpen}
          dialogTitle="Labels.SearchTasks"
          fetchDataPath={DIALOG_PATHS.TASKS}
          dialogColumns={dialogColumns}
        />
      </div>
    </>
  );
};

export default TasksTable;
