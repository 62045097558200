import { isNil } from 'lodash';
import moment from 'moment';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ACTION_MODES, dateInitFormats, SORT_DIRECTION, TAB_KEYS } from '@common/Constants';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { ILT_SESSION_PATHS, PARTICIPANTS_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { TABLE_OPTIONS } from '@config/network';
import { setDetails as setIltSessionDetails } from '@pages/IltSession/iltSessionSlice';
import { deleteByPath, getByPathAndParams, postByPathAndData } from '@services/BaseApi';

export const initialState = {
  data: [],
  rows: [],
  selectedId: null,
  filter: {
    search: '',
    sortBy: '',
    sortDirection: SORT_DIRECTION.ASCENDING,
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0],
    filters: null
  },
  totalPages: 0,
  details: null,
  totalElements: 0,
  isLoading: false,
  selectionModel: []
};

export const ILT_SESSION_TEAM_SLICE = 'iltSessionTeam';

export const fetchTeams = createAsyncThunk(
  `${ILT_SESSION_TEAM_SLICE}/fetchTeams`,
  ({ entityId, filter }, { rejectWithValue }) => {
    return getByPathAndParams({
      path: ILT_SESSION_PATHS.GET_TEAMS,
      pathVariables: { iltSessionId: entityId },
      params: filter
    })
      .then((response) => response.data)
      .catch((error) => {
        return rejectWithValue(error.response);
      });
  }
);

export const createTeam = createAsyncThunk(
  `${ILT_SESSION_TEAM_SLICE}/create`,
  (data, { dispatch, rejectWithValue, getState }) => {
    const { selectedId } = getState().ILT_SESSION_SLICE;
    const { filter } = getState().ILT_SESSION_PARTICIPANT_SLICE;
    const { details } = getState().ILT_SESSION_SLICE;

    const sessionIdRoleIdObject = Object.keys(data)
      // SessionIdRole keys
      .filter((k) => k.indexOf('sessionIdRole') === 0)
      .reduce((newData, k) => {
        const key = k.split('-')[1];
        newData[key] = data[k];
        return newData;
      }, {});

    return postByPathAndData({
      path: PARTICIPANTS_PATHS.POST,
      data: {
        personDto: { id: data.person?.id },
        companyId: data?.company?.organizationPersonId ?? null,
        teamMemberDto: { teamRoleId: data.iltSessionRole },
        isEventParticipant: false,
        statusId: 1,
        hotelRequest: data.hotelRequest,
        sessionIdRoleIdRequest: sessionIdRoleIdObject,
        typeId: 2, // Hardcoded
        eventId: selectedId,
        arrivalDate:
          data?.hotelRequest === false
            ? null
            : moment(data.arrivalDate).format(moment.HTML5_FMT.DATE),
        departureDate:
          data?.hotelRequest === false
            ? null
            : moment(data.departureDate).format(moment.HTML5_FMT.DATE)
      }
    })
      .then(async (response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.TEAM, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );

        const { totalElements = 0 } = await dispatch(
          fetchTeams({ entityId: selectedId, filter: filter })
        ).unwrap();

        dispatch(
          setIltSessionDetails({
            ...details,
            counts: { ...details.counts, team: totalElements }
          })
        );

        return response.data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, 'TEAM', ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error.response);
      });
  }
);

export const deleteTeams = createAsyncThunk(
  `${ILT_SESSION_TEAM_SLICE}/delete`,
  ({ participantIds, id }, { dispatch, rejectWithValue, getState }) => {
    return deleteByPath({
      path: ILT_SESSION_PATHS.DELETE_PARTICIPANTS,
      pathVariables: { id, participantIds }
    })
      .then(async (response) => {
        const { totalElements = 0 } = await dispatch(
          fetchTeams({
            entityId: id,
            filter: getState().ILT_SESSION_TEAM_SLICE.filter
          })
        ).unwrap();
        dispatch(setIltSessionDetails({ counts: { [TAB_KEYS.TEAM]: totalElements } }));
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.TEAM, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );

        return { response, participantIds };
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.TEAM, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );

        return rejectWithValue(error?.response);
      });
  }
);

export const iltSessionWaitingListTableSlice = createSlice({
  name: ILT_SESSION_TEAM_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setData: (state, { payload }) => {
      state.data = payload;
    },
    setSelectionModel: (state, { payload }) => {
      if (Array.isArray(payload)) {
        state.selectionModel = [...payload];
      } else {
        const index = state.selectionModel.findIndex((s) => s === payload);
        if (index === -1) {
          state.selectionModel = [...state.selectionModel, payload];
        } else {
          state.selectionModel = [
            ...state.selectionModel.slice(0, index),
            ...state.selectionModel.slice(index + 1)
          ];
        }
      }
    },
    setFilterParams: (state, action) => {
      const newFilterValues = Array.isArray(action.payload)
        ? action.payload.reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {})
        : { [action.payload.key]: action.payload.value };

      state.filter = {
        ...state.filter,
        ...newFilterValues,
        page: action.payload.page ?? 0
      };
      state.isLoading = !!(action.payload.key === 'search' && !action.payload.value);
    }
  },
  extraReducers: (builder) => {
    builder
      // Get all
      .addCase(fetchTeams.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalPages = payload?.totalPages || state.totalPages;
        state.totalElements = isNil(payload?.totalElements)
          ? state.totalElements
          : payload?.totalElements;
        state.data = payload?.content.map((el) => ({
          ...el,
          registrationDatetime: moment(el.registrationDatetime).format(dateInitFormats.basicDate)
        }));
      })
      .addCase(fetchTeams.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchTeams.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
      })
      //delete
      .addCase(deleteTeams.fulfilled, (state) => {
        state.selectionModel = [];
      });
  }
});

export const selectId = (state) => state.ILT_SESSION_SLICE.selectedId;
export const selectList = (state) => state.ILT_SESSION_TEAM_SLICE.data;
export const selectRows = (state) => state.ILT_SESSION_TEAM_SLICE.rows;
export const selectTotalElements = (state) => state.ILT_SESSION_TEAM_SLICE.totalElements;
export const selectTotalPages = (state) => state.ILT_SESSION_TEAM_SLICE.totalPages;
export const selectFilter = (state) => state.ILT_SESSION_TEAM_SLICE.filter;
export const selectIsLoading = (state) => state.ILT_SESSION_TEAM_SLICE.isLoading;
export const selectSelectionModel = (state) => state.ILT_SESSION_TEAM_SLICE.selectionModel;

const { actions, reducer } = iltSessionWaitingListTableSlice;

export const { setData, setSelectedId, setFilterParams, resetState, setSelectionModel } = actions;

export default reducer;
