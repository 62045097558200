// https://github.com/mui/mui-x/blob/HEAD/packages/grid/x-data-grid/src/internals/constants/localeTextConstants.ts

function getLocalesText(Localize) {
  return {
    // Root
    noRowsLabel: Localize.get('Tables.NoRows'),
    noResultsOverlayLabel: Localize.get('Tables.NoRows'),
    errorOverlayDefaultLabel: Localize.get('Tables.Error'),

    // Columns selector toolbar button text
    toolbarColumns: Localize.get('Tables.Columns'),

    // Filters toolbar button text
    toolbarFilters: Localize.get('Tables.Filters'),

    // Density selector toolbar button text
    toolbarDensity: Localize.get('Tables.Density'),
    toolbarDensityCompact: Localize.get('Tables.Compact'),
    toolbarDensityStandard: Localize.get('Tables.Standard'),
    toolbarDensityComfortable: Localize.get('Tables.Comfortable'),

    // Checkbox selection text
    checkboxSelectionHeaderName: Localize.get('Tables.CheckboxSelection'),
    checkboxSelectionSelectAllRows: Localize.get('Tables.SelectAllRows'),
    checkboxSelectionUnselectAllRows: Localize.get('Tables.UnselectAllRows'),
    checkboxSelectionSelectRow: Localize.get('Tables.SelectRow'),
    checkboxSelectionUnselectRow: Localize.get('Tables.UnselectRow'),

    // Rows selected footer text
    footerRowSelected: (count) => `${Localize.get('Tables.RowSelected')}: ${count}`,

    // Filter operators text
    filterOperatorContains: Localize.get('Tables.FilterValues.Contains'),
    filterOperatorEndsWith: Localize.get('Tables.FilterValues.EndsWith'),
    filterOperatorStartsWith: Localize.get('Tables.FilterValues.StartsWith'),
    filterOperatorEquals: Localize.get('Tables.FilterValues.Equals'),

    filterOperatorNotEqual: Localize.get('Tables.FilterValues.NotEqual'),
    filterOperatorIs: Localize.get('Tables.FilterValues.Is'),
    filterOperatorIsNot: Localize.get('Tables.FilterValues.IsNot'),
    filterOperatorIsEmpty: Localize.get('Tables.FilterValues.IsEmpty'),
    filterOperatorIsNotEmpty: Localize.get('Tables.FilterValues.IsNotEmpty'),
    filterOperatorIsAfter: Localize.get('Tables.FilterValues.IsAfter'),
    filterOperatorIsOnOrAfter: Localize.get('Tables.FilterValues.IsOnOrAfter'),
    filterOperatorIsBefore: Localize.get('Tables.FilterValues.IsBefore'),
    filterOperatorIsOnOrBefore: Localize.get('Tables.FilterValues.IsOnOrBefore'),
    filterOperatorIsOn: Localize.get('Tables.FilterValues.IsOn'),
    filterOperatorGreaterThen: Localize.get('Tables.FilterValues.GreaterThen'),
    filterOperatorGreaterThenOrEqual: Localize.get('Tables.FilterValues.GreaterThenOrEqual'),
    filterOperatorLessThen: Localize.get('Tables.FilterValues.LessThen'),
    filterOperatorLessThenOrEqual: Localize.get('Tables.FilterValues.LessThenOrEqual'),
    filterOperatorIsNotOn: Localize.get('Tables.FilterValues.IsNotOn'),

    // Filter panel text
    filterPanelColumns: Localize.get('Tables.Columns'),
    filterPanelOperators: Localize.get('Tables.Operators'),
    filterPanelInputPlaceholder: Localize.get('Tables.InputPlaceholder'),
    filterPanelInputLabel: Localize.get('Tables.Value'),

    // Columns panel text
    columnsPanelTextFieldLabel: Localize.get('Tables.FindColumn'),
    columnsPanelTextFieldPlaceholder: Localize.get('Tables.ColumnTitle'),
    columnsPanelShowAllButton: Localize.get('Tables.ShowAll'),
    columnsPanelHideAllButton: Localize.get('Tables.HideAll'),

    MuiTablePagination: { labelRowsPerPage: Localize.get('Tables.RowPerPage') }
  };
}

export default getLocalesText;
