import React, { useCallback, useEffect, useRef } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import Link from '@mui/material/Link';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { SORTING_ORDER, TAB_KEYS } from '@common/Constants';
import { onTabsTableFilterChange } from '@common/helpers/tables/getAdvancedFiltersForMUITable';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import { openSidebar, setIsEditDisabled } from '@components/RightSidebar/rightSidebarSlice';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar/TableToolbar';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import ToolbarButton from '@components/ToolbarButton';
import {
  getFilterOperatorsBasedOnType,
  getFilterValueOptionsBasedOnType
} from '@config/filterOperators';
import { TABLE_OPTIONS } from '@config/network';
import { eventsDetailPath, iltSessionsDetailPath } from '@navigation/routes/Routes';
import { selectLocationDetails } from '@pages/BusinessPartners/businessPartnersSlice';

import {
  fetchContingents,
  selectList,
  selectFilter,
  selectIsLoading,
  resetState,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  selectSelectionModel,
  setSelectionModel,
  selectDetails as selectContingentDetails,
  setDetails as setContingentDetails
} from './contingentTableSlice';
import sidebarConfig from './sidebarConfig';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  }
}));

const ContingentTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarButtons = []
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: {
      roomTypes: { data: roomTypes }
    }
  } = useMatch();
  const trackFilterModel = useRef(null);

  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);
  const details = useSelector(selectLocationDetails);
  const contingentDetails = useSelector(selectContingentDetails);

  useEffect(() => {
    if (details?.roles[0]?.hotel?.id) {
      dispatch(fetchContingents({ entityId: details?.roles[0]?.hotel?.id, filter }));
    }
  }, [
    details?.roles[0]?.hotel?.id,
    filter?.size,
    filter?.page,
    filter?.sortBy,
    filter?.sortDirection,
    filter?.filters
  ]);

  useEffect(() => {
    dispatch(setIsEditDisabled(true));
  }, []);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarButtonDisabled = (fieldName) => {
    switch (fieldName) {
      case 'edit':
        return true;
      case 'add':
        return true;
      case 'delete':
        return true;
      default:
        return false;
    }
  };

  const onToolbarButtonClick = (fieldName) => {
    switch (fieldName) {
      case 'edit':
        break;
      case 'add':
        return !entityId || !entityType;
      case 'delete':
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (!contingentDetails) {
      return;
    }

    dispatch(
      openSidebar({
        config: {
          title: Localize.get('IltSession.HotelContingentDetails'),
          fieldsConfig: sidebarConfig
        },
        data: contingentDetails
      })
    );
  }, [contingentDetails]);

  const onRowClick = ({ row }) => dispatch(setContingentDetails(row));

  const onFilterChange = (values) => {
    const advancedFilters = onTabsTableFilterChange(values, trackFilterModel, () =>
      dispatch(setFilterParams({ key: 'filters', value: null }))
    );
    if (advancedFilters) dispatch(setFilterParams({ key: 'filters', value: { advancedFilters } }));
  };

  const onSortChange = (sortModel) =>
    dispatch(
      setFilterParams([
        { key: 'sortBy', value: sortModel[0]?.field },
        { key: 'sortDirection', value: sortModel[0]?.sort }
      ])
    );

  const getSingleSelectValueOptions = useCallback((field) => {
    switch (field) {
      case 'roomType': {
        return roomTypes.map(({ value }) => value);
      }
      default:
        return [];
    }
  }, []);

  return (
    <div className={classes.root}>
      <TabContent>
        <div className={classes.toolbarContainer}>
          <Typography variant="h6" component="h6" color="primary">
            {Localize.get('Labels.Contingent')}
          </Typography>
          <Toolbar className={classes.toolbar}>
            {toolbarButtons?.map((button, index) => (
              <ToolbarButton
                key={index}
                button={button}
                isDisabled={button.disabled || isToolbarButtonDisabled(button.name)}
                onToolbarButtonClick={onToolbarButtonClick}
              />
            ))}
          </Toolbar>
        </div>
        <TabContainer>
          <StyledDataGrid
            onRowClick={onRowClick}
            keepNonExistentRowsSelected={true}
            loading={isLoading}
            rows={data}
            columns={[
              ...columns.map((column) => ({
                ...column,
                headerName: Localize.get(column.headerName),
                filterOperators: getFilterOperatorsBasedOnType(column?.type),
                valueOptions: getFilterValueOptionsBasedOnType(column, getSingleSelectValueOptions),
                renderCell: (params) => (
                  <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                    {['roomType'].includes(params.field) ? (
                      <Tooltip title={params?.value} arrow>
                        <Typography
                          noWrap
                          variant="body"
                          component="div"
                          sx={{
                            display: 'block',
                            cursor: 'pointer',
                            maxWidth: params?.colDef?.width - 10 // 10 px for three dots
                          }}
                        >
                          {params?.value}
                        </Typography>
                      </Tooltip>
                    ) : params.field === 'usedIn' ? (
                      <Link
                        sx={{
                          display: 'block',
                          cursor: 'pointer',
                          maxWidth: params?.colDef?.width
                        }}
                        onClick={(event) => {
                          event.stopPropagation();
                          navigate({
                            to: params?.row?.event?.isEvent
                              ? eventsDetailPath.replace(':eventId', params.row.event.id)
                              : iltSessionsDetailPath.replace(':sessionId', params.row.event.id),
                            replace: false,
                            search: () => ({ activeTab: TAB_KEYS.OVERVIEW })
                          });
                        }}
                      >
                        <Tooltip title={params?.value} arrow>
                          <Typography
                            noWrap
                            variant="body"
                            component="div"
                            sx={{
                              display: 'block',
                              cursor: 'pointer',
                              maxWidth: params?.colDef?.width - 10 // 10 px for three dots
                            }}
                          >
                            {params?.value}
                          </Typography>
                        </Tooltip>
                      </Link>
                    ) : (
                      <Typography variant="body" component="div">
                        {params.value}
                      </Typography>
                    )}
                  </div>
                )
              }))
            ]}
            initialState={{
              pagination: {
                pageSize: totalPages,
                rowCount: totalElements,
                page: filter?.page
              }
            }}
            onSelectionModelChange={(newSelectionModel) =>
              dispatch(setSelectionModel(newSelectionModel))
            }
            components={{
              Toolbar: () => <TableToolbar />
            }}
            selectionModel={selectionModel}
            checkboxSelection
            localeText={getLocalesText(Localize)}
            pagination
            paginationMode="server"
            sortingMode="server"
            filterMode="server"
            disableColumnMenu
            page={filter?.page}
            pageSize={filter?.size}
            rowCount={totalElements}
            rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
            disableSelectionOnClick
            onPageChange={(page) =>
              dispatch(setFilterParams({ key: 'page', value: page >= 0 ? page : 0 }))
            }
            onPageSizeChange={(value) => dispatch(setFilterParams([{ key: 'size', value }]))}
            sortingOrder={SORTING_ORDER}
            onSortModelChange={onSortChange}
            onFilterModelChange={onFilterChange}
          />
        </TabContainer>
      </TabContent>
    </div>
  );
};

export default ContingentTable;
