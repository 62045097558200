import Localize from 'react-intl-universal';

const getFirstRow = (roles, message) =>
  roles?.length === 1 ? `${roles[0]?.supplierType?.value}` : message;

const getLocationsOptions = (locationsOption) => {
  return locationsOption?.map((option) => {
    if (option?.label) {
      return {
        ...option,
        key: option?.translateKey ? Localize.get(option.key) : option.key,
        label: option?.translateKey ? Localize.get(option.label) : option.label
      };
    }
    return Localize.get(option);
  });
};

export { getFirstRow, getLocationsOptions };
