import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { ACTION_MODES, TAB_KEYS, SORT_DIRECTION } from '@common/Constants';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { isJsonString } from '@common/helpers/string';
import { TAB_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { closeConfirmDialog } from '@components/ConfirmDialog/confirmDialogSlice';
import { SnackbarSeverityTypes, showSnackbar } from '@components/Snackbar/snackbarSlice';
import { TABLE_OPTIONS } from '@config/network';
import {
  deleteByPath,
  getByPathAndParams,
  postByPathAndData,
  putByPathAndData
} from '@services/BaseApi';

const initialState = {
  data: [],
  isLoading: false,
  error: null,
  selectionModel: [],
  isNotesDialogOpen: false,
  selectedNote: null,
  notesType: 'NOTE',
  filter: {
    sortBy: '',
    sortDirection: SORT_DIRECTION.ASCENDING,
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0],
    filters: null
  },
  totalElements: 0,
  dataTypeIdArr: [] // this is hack, we should receive this data from server
};

const NOTES_SLICE = 'notesSlice';

export const getNotes = createAsyncThunk(
  `${NOTES_SLICE}/fetchAll`,
  ({ entityId, entityType, filter }, { rejectWithValue }) => {
    return getByPathAndParams({
      path: TAB_PATHS.NOTES.GET,
      pathVariables: { id: entityId, entity: entityType },
      params: filter
    })
      .then(({ data }) => data)
      .catch((error) => rejectWithValue({ message: error?.message, time: error?.timestamp }));
  }
);

export const createNote = createAsyncThunk(
  `${NOTES_SLICE}/createOne`,
  ({ postData, setDetails, noteTypeId }, { dispatch, rejectWithValue }) => {
    return postByPathAndData({
      path: TAB_PATHS.NOTES.POST,
      pathVariables: {
        entityType: postData.entityType,
        noteType: postData.type,
        entityId: postData.entityId
      },
      data: postData
    })
      .then(({ data: { count } }) => {
        dispatch(setDetails({ counts: { [TAB_KEYS.NOTE]: count } }));
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.NOTES, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return noteTypeId;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.NOTES, ACTION_MODES.Create),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
        return rejectWithValue({ message: error?.message, time: error?.timestamp });
      });
  }
);

export const updateNote = createAsyncThunk(
  `${NOTES_SLICE}/updateOne`,
  ({ postData }, { dispatch, rejectWithValue }) => {
    return putByPathAndData({
      path: TAB_PATHS.NOTES.PUT,
      pathVariables: { id: postData.noteId },
      data: postData
    })
      .then(({ data }) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.NOTES, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return data;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.NOTES, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
        return rejectWithValue({ message: error?.message, time: error?.timestamp });
      });
  }
);

export const removeNote = createAsyncThunk(
  `${NOTES_SLICE}/deleteAll`,
  ({ entityId, entityType, id, setDetails, noteTypeId }, { dispatch, rejectWithValue }) => {
    return deleteByPath({
      path: TAB_PATHS.NOTES.DELETE,
      pathVariables: { entityId, entityType, id }
    })
      .then(({ data: { count } }) => {
        dispatch(closeConfirmDialog());
        dispatch(setDetails({ counts: { [TAB_KEYS.NOTE]: count } }));

        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.NOTES, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return { count, noteTypeId };
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.NOTES, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
        return rejectWithValue({ message: error?.message, time: error?.timestamp });
      });
  },
  {
    condition: (entityId) => Boolean(entityId),
    dispatchConditionRejection: true
  }
);

export const notesSlice = createSlice({
  name: NOTES_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setSelectionModel: (state, { payload }) => {
      state.selectionModel = payload;
    },
    setFilterParams: (state, action) => {
      const newFilterValues = Array.isArray(action.payload)
        ? action.payload.reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {})
        : { [action.payload.key]: action.payload.value };

      state.filter = {
        ...state.filter,
        ...newFilterValues,
        page: newFilterValues.page ?? 0
      };
      state.isLoading = !!(action.payload.key === 'search' && !action.payload.value);
    },
    openNotesDialog: (state, { payload }) => {
      state.isNotesDialogOpen = true;
      state.selectedNote = payload;
    },
    closeNotesDialog: (state) => {
      state.isNotesDialogOpen = false;
    },
    setNotesType: (state, { payload }) => {
      state.notesType = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getNotes.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.data = payload?.content.map((el) => ({
          ...el,
          text: isJsonString(el?.text)
            ? el?.text
            : JSON.stringify({
                blocks: [
                  {
                    text: el?.text ? el?.text : '',
                    type: 'unstyled',
                    depth: 0,
                    inlineStyleRanges: [],
                    entityRanges: [],
                    data: {}
                  }
                ],
                entityMap: {}
              })
        }));
        state.totalElements = payload?.totalElements;
        state.dataTypeIdArr = state.filter.filters
          ? state.dataTypeIdArr
          : payload?.content?.map((el) => el?.type?.id);
      })
      .addCase(getNotes.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.error = payload;
        state.data = [];
      })
      .addCase(createNote.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createNote.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalElements += 1;
        state.isNotesDialogOpen = false;
        if (payload) state.dataTypeIdArr.push(payload);
      })
      .addCase(createNote.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isNotesDialogOpen = false;
        state.error = payload;
      })
      .addCase(updateNote.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateNote.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isNotesDialogOpen = false;
        state.data = [...state.data.map((item) => (item.id === payload.id ? payload : item))];
      })
      .addCase(updateNote.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isNotesDialogOpen = false;
        state.error = payload;
      })
      .addCase(removeNote.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeNote.fulfilled, (state, { payload }) => {
        const { count, noteTypeId } = payload;

        state.totalElements = count;
        state.filter.page = Math.min(
          state.filter.page,
          Math.max(0, Math.floor((state.totalElements - 1) / state.filter.size))
        );
        state.isLoading = false;
        state.selectionModel = [];
        state.dataTypeIdArr = state.dataTypeIdArr.filter((el) => el !== noteTypeId);
      })
      .addCase(removeNote.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.selectionModel = [];
        state.error = payload;
      });
  }
});

export const selectIsLoading = (state) => state.NOTES_SLICE.isLoading;
export const selectData = (state) => state.NOTES_SLICE.data;
export const selectSelectionModel = (state) => state.NOTES_SLICE.selectionModel;
export const selectIsNotesDialogOpen = (state) => state.NOTES_SLICE.isNotesDialogOpen;
export const selectSelectedNote = (state) => state.NOTES_SLICE.selectedNote;
export const selectError = (state) => state.NOTES_SLICE.error;
export const selectNotesType = (state) => state.NOTES_SLICE.notesType;
export const selectFilter = (state) => state.NOTES_SLICE.filter;
export const selectTotalElements = (state) => state.NOTES_SLICE.totalElements;
export const selectDataTypeIdArr = (state) => state.NOTES_SLICE.dataTypeIdArr;

const { actions, reducer } = notesSlice;

export const {
  resetState,
  setSelectionModel,
  openNotesDialog,
  closeNotesDialog,
  setNotesType,
  setFilterParams
} = actions;

export default reducer;
