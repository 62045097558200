import { FILTER_KEYS, FILTER_TYPES } from '@components/FilterDialog';

const eventsFilters = [
  {
    label: 'Event.Name',
    id: 'eventName',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Labels.Status',
    id: 'eventStatus',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: []
  },
  {
    label: 'Events.EventType',
    id: 'eventType',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: []
  },
  {
    label: 'Events.MaxParticipants',
    id: 'maxParticipants',
    type: FILTER_TYPES.number,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' }
  },
  {
    label: 'Events.MinParticipants',
    id: 'minParticipants',
    type: FILTER_TYPES.number,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' }
  },
  {
    label: 'Events.OptParticipants',
    id: 'optParticipants',
    type: FILTER_TYPES.number,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Equals' }
  },
  {
    label: 'Events.VenueName',
    id: 'venueName',
    type: FILTER_TYPES.string,
    operator: { key: FILTER_KEYS.Contains, label: 'Tables.FilterValues.Contains' }
  },
  {
    label: 'Events.EventLanguage',
    id: 'eventLanguage',
    type: FILTER_TYPES.select,
    operator: { key: FILTER_KEYS.Equals, label: 'Tables.FilterValues.Contains' },
    options: []
  },
  {
    label: 'IltSession.WaitList',
    id: 'hasWaitingList',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  },
  {
    label: 'Events.Private',
    id: 'isPrivate',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  },
  {
    label: 'Events.Web',
    id: 'isWebPublished',
    type: FILTER_TYPES.boolean,
    operator: { key: FILTER_KEYS.Is, label: 'Tables.FilterValues.Is' },
    options: ['Tables.FilterValues.True']
  }
];

export default eventsFilters;
