import moment from 'moment';

export default {
  hotel: {
    id: '',
    name: ''
  },
  contingent: '',
  accommodation: { id: '' },
  price: '',
  currency: 1,
  startDate: new Date(),
  validUntil: '',
  endDate: moment(new Date()).add(1, 'd').toDate(),
  maxOccupancy: 0
};
