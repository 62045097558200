import React, { useCallback, useEffect, useRef } from 'react';
import Localize from 'react-intl-universal';
import { useMatch, useNavigate, useSearch } from 'react-location';
import { useSelector, useDispatch } from 'react-redux';

import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';

import { ACTION_MODES, FOCUS_FIELD_TIMEOUT, SORTING_ORDER } from '@common/Constants';
import { checkChangedFields } from '@common/helpers/helpers';
import { onTabsTableFilterChange } from '@common/helpers/tables/getAdvancedFiltersForMUITable';
import getLocalesText from '@common/helpers/tables/getLocalesText';
import { CONFIRM_ACTIONS, openConfirmDialog } from '@components/ConfirmDialog';
import { openSidebar } from '@components/RightSidebar/rightSidebarSlice';
import StyledMenu from '@components/StyledMenuItem/StyledMenu';
import TabContainer from '@components/TabContainer';
import TabContent from '@components/TabContent/TabContent';
import TableToolbar from '@components/TableToolbar';
import { StyledDataGrid } from '@components/TokenValueModalTrigger/components/DialogDataGrid';
import ToolbarButton from '@components/ToolbarButton';
import {
  getFilterOperatorsBasedOnType,
  getFilterValueOptionsBasedOnType
} from '@config/filterOperators';
import { TABLE_OPTIONS } from '@config/network';
import {
  selectList,
  fetchParticipants,
  selectFilter,
  selectIsLoading,
  resetState,
  selectTotalElements,
  selectTotalPages,
  setFilterParams,
  selectSelectionModel,
  setSelectionModel,
  generateReport,
  selectExportAnchor,
  selectIsExportPopupOpen,
  setExportPopupOpened,
  setExportPopupClosed,
  saveParticipant,
  selectDetails,
  setDetails,
  selectIsDetailsLoading,
  REGISTERED_TYPE_ID,
  deleteParticipant
} from '@pages/IltSession/components/Tabs/ParticipantsTable/participantTableSlice';
import {
  WEBINAR_TYPE_ID,
  selectDetails as selectIltSessionDetails
} from '@pages/IltSession/iltSessionSlice';
import { PARTICIPANT_STATUSES } from '@pages/IltSession/util/Constants';

import CreateParticipant from '../../CreateParticipant/CreateParticipant';
import { participantSchema } from './participantSchema';
import { webinarSidebar, classroomSidebar } from './sidebarConfig';

export const exportTypesConfig = [
  { type: 'csv', label: 'FileTypes.CSV', icon: 'download' },
  { type: 'xlsx', label: 'FileTypes.XLSX', icon: 'download' }
];

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  },
  toolbarContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    alignItems: 'center'
  },
  toolbar: {
    minHeight: '3rem'
  },
  noNightsBooked: { color: theme.palette.error.main },
  someNightsBooked: { color: theme.palette.warning.main },
  allNightsBooked: { color: theme.palette.success.light }
}));

const statusColor = (nightsBookedData, classes) => {
  if (!nightsBookedData) {
    return;
  }

  const nightsBooked = nightsBookedData.slice(0, nightsBookedData.indexOf('/'));
  const nightsTotal = nightsBookedData.slice(nightsBookedData.indexOf('/') + 1);

  return nightsBooked === '0'
    ? classes.noNightsBooked
    : nightsBooked !== nightsTotal
    ? classes.someNightsBooked
    : classes.allNightsBooked;
};

const ParticipantsTable = ({
  columns = [],
  entityId = null,
  entityType = null,
  toolbarButtons = []
}) => {
  const {
    data: {
      currencyType: { data: currencyType },
      participantStatuses: { data: statuses },
      cancelationReasons: { data: cancelationReasons }
    }
  } = useMatch();

  const trackFilterModel = useRef(null);

  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const search = useSearch();

  const data = useSelector(selectList);
  const filter = useSelector(selectFilter);
  const isLoading = useSelector(selectIsLoading);
  const totalElements = useSelector(selectTotalElements);
  const totalPages = useSelector(selectTotalPages);
  const selectionModel = useSelector(selectSelectionModel);
  const exportAnchor = useSelector(selectExportAnchor);
  const iltSessionDetails = useSelector(selectIltSessionDetails);
  const isExportPopupOpen = useSelector(selectIsExportPopupOpen);

  const details = useSelector(selectDetails);
  const isDetailsLoading = useSelector(selectIsDetailsLoading);

  useEffect(() => {
    if (entityId && entityType) {
      dispatch(fetchParticipants({ entityId: entityId, filter: filter }));
    }
  }, [
    entityId,
    filter?.size,
    filter?.page,
    filter?.sortBy,
    filter?.sortDirection,
    filter?.filters
  ]);

  // Resets state on component destroy
  useEffect(() => {
    return () => dispatch(resetState());
  }, []);

  const isToolbarButtonDisabled = (name) => {
    switch (name) {
      case 'add_participant':
        return !entityId || !entityType;
      case 'export':
        return !entityId || !data?.length;
      case 'delete':
        return selectionModel.length === 0;
      default:
        return false;
    }
  };

  const onToolbarButtonClick = (name, event) => {
    switch (name) {
      case 'add_participant':
        navigate({
          search: (previousUrlParams) => ({ ...previousUrlParams, mode: ACTION_MODES.Create })
        });
        break;

      case 'delete':
        {
          dispatch(
            openConfirmDialog({
              title: Localize.get('ConfirmationMessages.Delete', {
                item: Localize.get('Events.SingularOrPluralParticipant', {
                  selectionModel: selectionModel.length
                })?.toLowerCase()
              }),
              confirmButton: Localize.get('Buttons.Delete'),
              cancelButton: Localize.get('Buttons.Cancel')
            })
          )
            .unwrap()
            .then((result) => {
              if (result === CONFIRM_ACTIONS.Confirm) {
                dispatch(deleteParticipant({ participantIds: selectionModel, id: entityId }));
              }
            });
        }
        break;

      case 'export':
        dispatch(setExportPopupOpened(event.currentTarget));
        break;

      default:
        break;
    }
  };

  const handleClose = () => dispatch(setExportPopupClosed());

  const onMenuItemClick = (item) => dispatch(generateReport({ id: entityId, type: item.type }));

  // Only include Completed, Canceled, Registered, Not Completed
  const visibleStatuses = statuses.filter((status) => [2, 5, 6, 9].includes(status.id));

  const extendedConfig = (setFieldValue, values) => {
    return {
      // Registered ID - 6, Completed ID - 2, Cancelled ID - 5, Not Completed - 9
      ['status.value']: {
        options: visibleStatuses.map((item) => {
          // Status is cancelled, only change to registered
          if (details?.status?.id === 5) {
            return item.id === 6 ? { ...item, disabled: false } : { ...item, disabled: true };
          }

          // Status is not completed, only change to completed
          if (details?.status?.id === 9) {
            return item.id === 2 ? { ...item, disabled: false } : { ...item, disabled: true };
          }

          // Status is completed, only change to not completed
          if (details?.status?.id === 2) {
            return item.id === 9 ? { ...item, disabled: false } : { ...item, disabled: true };
          }

          return { ...item, disabled: false };
        }),
        value: values?.status.id,
        onChange: (e) => {
          setFieldValue(
            'status',
            statuses.find(({ id }) => id === e.target.value)
          );
        }
      },
      ['completed']: {
        value: values?.completed ? 1 : 0,
        options: [
          { id: 0, value: Localize.get('Labels.NotCompleted') },
          { id: 1, value: Localize.get('Labels.Completed') }
        ],
        onChange: (e) => setFieldValue('completed', e.target.value)
      },
      ['sessions']: {
        arrayConfig: {
          actions: {
            areButtonsHidden: true
          }
        }
      },
      ['hotelRequest']: {
        onChange: (e) => {
          setFieldValue('hotelRequest', e.target.checked);
          if (e.target.checked) {
            setTimeout(
              () => document.getElementsByName('arrivalDate')[0]?.focus(),
              FOCUS_FIELD_TIMEOUT
            );
          }
        }
      },
      ['company']: {
        value: values?.company?.name ?? ''
      },
      ['arrivalDate']: {
        disabled: !values?.hotelRequest,
        required: values?.hotelRequest,
        onChange: (value) => setFieldValue('arrivalDate', value)
      },
      ['departureDate']: {
        disabled: !values?.hotelRequest,
        required: values?.hotelRequest,
        onChange: (value) => setFieldValue('departureDate', value)
      },
      ['standardPrice']: {
        disabled: values?.status?.id === REGISTERED_TYPE_ID,
        // If no currency don't show the price since price is not yet assigned to Ilt Session
        isVisible: !!values?.currency
      },
      ['advertisingSubsidyPrice']: {
        disabled: values?.status?.id === REGISTERED_TYPE_ID,
        // If no currency don't show the price since price is not yet assigned to Ilt Session
        isVisible: !!values?.currency
      },
      ['currency.id']: {
        isVisible: !!values?.currency,
        disabled: values?.status?.id === 2,
        options: currencyType,
        onChange: (event) => {
          setFieldValue('currency', {
            id: event.target.value,
            value: currencyType.find((s) => s.id === event.target.value).value
          });
        }
      },
      ['cancellationReason']: {
        required: values?.status?.id === PARTICIPANT_STATUSES.CANCELLED,
        isVisible: values?.status?.id === PARTICIPANT_STATUSES.CANCELLED,
        options: cancelationReasons,
        value: values?.cancellationReason?.id ?? details?.cancellationReason?.id ?? '',
        onChange: (e) => {
          setFieldValue(
            'cancellationReason',
            cancelationReasons.find(({ id }) => id === e.target.value)
          );
        }
      },
      ['cancellationComment']: {
        isVisible: values?.status?.id === PARTICIPANT_STATUSES.CANCELLED,
        value: values?.cancellationComment ?? ''
      }
    };
  };

  const isWebinarType = iltSessionDetails?.type?.id === WEBINAR_TYPE_ID;

  useEffect(() => {
    if (!details) {
      return;
    }

    dispatch(
      openSidebar({
        config: {
          title: Localize.get('ParticipantsTile.ParticipantAndSessionDetails'),
          fieldsConfig: isWebinarType ? webinarSidebar : classroomSidebar
        },
        extendedConfig: extendedConfig,
        data: details,
        validationSchema: participantSchema,
        isDeleteDisabled: true,
        isDetailsLoading: isDetailsLoading,
        onSave: (values) => {
          return dispatch(
            saveParticipant({
              entityId,
              participantId: details?.id,
              data: checkChangedFields(details, values)
            })
          );
        },
        onDelete: () => null
      })
    );
  }, [details, isDetailsLoading]);

  const onRowClick = ({ row }) => dispatch(setDetails(row));

  const onFilterChange = (values) => {
    const advancedFilters = onTabsTableFilterChange(values, trackFilterModel, () =>
      dispatch(setFilterParams({ key: 'filters', value: null }))
    );
    if (advancedFilters) dispatch(setFilterParams({ key: 'filters', value: { advancedFilters } }));
  };

  const onSortChange = (sortModel) =>
    dispatch(
      setFilterParams([
        { key: 'sortBy', value: sortModel[0]?.field },
        { key: 'sortDirection', value: sortModel[0]?.sort }
      ])
    );

  const getSingleSelectValueOptions = useCallback((field) => {
    switch (field) {
      case 'eventParticipantStatus': {
        return visibleStatuses.map(({ value }) => value);
      }
      default:
        return [];
    }
  }, []);

  return (
    <div className={classes.root}>
      {search?.mode === ACTION_MODES.Create ? (
        <CreateParticipant session={iltSessionDetails} />
      ) : (
        <TabContent>
          <div className={classes.toolbarContainer}>
            <Typography variant="h6" component="h6" color="primary">
              {Localize.get('Labels.Participants')}
            </Typography>
            <Toolbar className={classes.toolbar}>
              {toolbarButtons?.map((button, index) => (
                <ToolbarButton
                  key={index}
                  button={button}
                  isDisabled={button.disabled || isToolbarButtonDisabled(button.name)}
                  onToolbarButtonClick={onToolbarButtonClick}
                />
              ))}
            </Toolbar>
          </div>
          <TabContainer>
            <StyledDataGrid
              keepNonExistentRowsSelected
              getRowId={(row) => row?.id}
              loading={isLoading}
              rows={data}
              autoFilter={false}
              columns={columns.map((column) => ({
                ...column,
                headerName: Localize.get(column.headerName),
                filterOperators: getFilterOperatorsBasedOnType(column?.type),
                valueOptions: getFilterValueOptionsBasedOnType(column, getSingleSelectValueOptions),
                renderCell: (params) => (
                  <div data-test-id={`${params.field}-${params?.row?.id ?? 'default'}`}>
                    {['nightsBooked'].includes(params.field) ? (
                      <Typography
                        className={statusColor(params?.value, classes)}
                        variant="body"
                        component="div"
                      >
                        {params.value}
                      </Typography>
                    ) : [
                        'eventParticipantName',
                        'companyName',
                        'hotel',
                        'standardPrice',
                        'advertisingSubsidyPrice',
                        'iltSessionStatus',
                        'eventParticipantStatus'
                      ].includes(params.field) ? (
                      <Tooltip title={params.value} arrow>
                        <Typography
                          noWrap
                          variant="body"
                          component="div"
                          sx={{
                            maxWidth: params?.colDef?.width - 10 // 10 px for three dots,
                          }}
                        >
                          {params.value}
                        </Typography>
                      </Tooltip>
                    ) : (
                      <Typography variant="body" component="div">
                        {params.value}
                      </Typography>
                    )}
                  </div>
                )
              }))}
              initialState={{
                pagination: {
                  pageSize: totalPages,
                  rowCount: totalElements,
                  page: filter?.page
                }
              }}
              onRowClick={onRowClick}
              onSelectionModelChange={(values) => dispatch(setSelectionModel(values))}
              selectionModel={selectionModel}
              checkboxSelection
              localeText={getLocalesText(Localize)}
              pagination
              paginationMode="server"
              sortingMode="server"
              filterMode="server"
              disableColumnMenu
              page={filter?.page}
              pageSize={filter?.size}
              rowCount={totalElements}
              rowsPerPageOptions={TABLE_OPTIONS.PAGE_SIZE_OPTIONS}
              disableSelectionOnClick
              components={{ Toolbar: () => <TableToolbar /> }}
              onPageChange={(page) =>
                dispatch(setFilterParams({ key: 'page', value: page >= 0 ? page : 0 }))
              }
              onPageSizeChange={(value) => dispatch(setFilterParams([{ key: 'size', value }]))}
              sortingOrder={SORTING_ORDER}
              onSortModelChange={onSortChange}
              onFilterModelChange={onFilterChange}
            />

            <StyledMenu anchorEl={exportAnchor} open={isExportPopupOpen} onClose={handleClose}>
              {exportTypesConfig.map((el) => (
                <MenuItem key={el.label} onClick={() => onMenuItemClick(el)}>
                  <ListItemIcon>
                    <Icon fontSize="small">{el.icon}</Icon>
                  </ListItemIcon>
                  <ListItemText>{Localize.get(el.label)}</ListItemText>
                </MenuItem>
              ))}
            </StyledMenu>
          </TabContainer>
        </TabContent>
      )}
    </div>
  );
};

export default ParticipantsTable;
