import Localize from 'react-intl-universal';

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  ACTION_MODES,
  NAVIGATE_TOASTER_TIMEOUT,
  TAB_KEYS,
  SORT_DIRECTION
} from '@common/Constants';
import formatBytes from '@common/formatters/formatBytes';
import { deepMerge } from '@common/helpers/deepMerge';
import { errorMessageFormatter, successMessageFormatter } from '@common/helpers/MessageFormatter';
import { TAB_PATHS } from '@common/network/ApiPaths';
import EntityTypes from '@common/network/EntityTypes';
import { closeConfirmDialog } from '@components/ConfirmDialog/confirmDialogSlice';
import { showSnackbar, SnackbarSeverityTypes } from '@components/Snackbar/snackbarSlice';
import { TABLE_OPTIONS } from '@config/network';
import {
  getByPathAndParams,
  deleteByPath,
  patchByPathAndData,
  postByPathAndData
} from '@services/BaseApi';

const initialState = {
  data: [],
  isLoading: false,
  isError: false,
  filter: {
    sortBy: '',
    sortDirection: SORT_DIRECTION.ASCENDING,
    page: 0,
    size: TABLE_OPTIONS.PAGE_SIZE_OPTIONS[0],
    filters: null
  },
  totalElements: 0,
  selectionModel: [],
  isCreateDialogOpen: false,
  details: null
};

const createVisibilityFieldText = (item) => {
  if (typeof item !== 'object' || !item) return '';
  const visibilityLabels = [];
  if (item.visibleToAdministrators) visibilityLabels.push('Administrator');
  if (item.visibleToTrainers) visibilityLabels.push('Trainers');
  if (item.visibleToParticipants) visibilityLabels.push(Localize.get('Launchpad.Participants'));
  if (item.visibleToLocationResponsible)
    visibilityLabels.push(Localize.get('IltSession.LocationResponsible'));

  return visibilityLabels.join(', ');
};

export const ILT_ATTACHMENTS_SLICE = 'attachmentsSlice';

export const getAttachments = createAsyncThunk(
  `${ILT_ATTACHMENTS_SLICE}/fetchAll`,
  ({ entityId, entityType, filter }, { rejectWithValue }) => {
    return getByPathAndParams({
      path: TAB_PATHS.ATTACHMENTS.GET,
      pathVariables: { entityId, entityType },
      params: filter
    })
      .then(({ data }) => {
        const formatted = data?.content?.map((item) => {
          if (item.size) {
            item.size = formatBytes(item.size);
          }

          item.visibilityAccessGranted = createVisibilityFieldText(item);
          return item;
        });

        return { ...data, content: formatted };
      })
      .catch((error) => rejectWithValue(error.response));
  },
  {
    condition: (entityId) => Boolean(entityId),
    dispatchConditionRejection: true
  }
);

export const removeAttachments = createAsyncThunk(
  `${ILT_ATTACHMENTS_SLICE}/removeAll`,
  ({ entityId, entityType, id, setDetails }, { rejectWithValue, dispatch }) => {
    return deleteByPath({
      path: TAB_PATHS.ATTACHMENTS.DELETE,
      pathVariables: { entityId, entityType, id }
    })
      .then(({ data: { count } }) => {
        dispatch(closeConfirmDialog());
        dispatch(setDetails({ counts: { [TAB_KEYS.ATTACHMENT]: count } }));
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.ATTACHMENTS, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return count;
      })
      .catch((error) => {
        dispatch(
          showSnackbar({
            message: errorMessageFormatter(error, EntityTypes.ATTACHMENTS, ACTION_MODES.Delete),
            severity: SnackbarSeverityTypes.ERROR
          })
        );
        return rejectWithValue(error.response);
      });
  },
  {
    condition: (entityId) => Boolean(entityId),
    dispatchConditionRejection: true
  }
);

export const uploadAttachment = createAsyncThunk(
  `${ILT_ATTACHMENTS_SLICE}/uploadOne`,
  ({ formData, setDetails }, { rejectWithValue, dispatch }) => {
    const formFile = new FormData();

    Object.entries(formData).forEach(([key, value]) => {
      formFile.append(key, value);
    });

    return postByPathAndData({
      path: TAB_PATHS.ATTACHMENTS.POST,
      data: formFile
    })
      .then(({ data: { count } }) => {
        dispatch(setDetails({ counts: { [TAB_KEYS.ATTACHMENT]: count } }));

        setTimeout(() => {
          dispatch(
            showSnackbar({
              message: successMessageFormatter(EntityTypes.ATTACHMENTS, ACTION_MODES.Upload),
              severity: SnackbarSeverityTypes.SUCCESS
            })
          );
        }, NAVIGATE_TOASTER_TIMEOUT);

        return count;
      })
      .catch((error) => rejectWithValue(error.response));
  },
  {
    condition: (entityId) => Boolean(entityId),
    dispatchConditionRejection: true
  }
);

export const patchAttachment = createAsyncThunk(
  `${ILT_ATTACHMENTS_SLICE}/patchAttachment`,
  ({ data, id }, { rejectWithValue, dispatch }) => {
    return patchByPathAndData({
      path: TAB_PATHS.ATTACHMENTS.PATCH,
      data: data,
      pathVariables: { id }
    })
      .then((response) => {
        dispatch(
          showSnackbar({
            message: successMessageFormatter(EntityTypes.ATTACHMENTS, ACTION_MODES.Edit),
            severity: SnackbarSeverityTypes.SUCCESS
          })
        );
        return response?.data;
      })
      .catch((error) => {
        return rejectWithValue(error.response);
      });
  }
);

export const attachmentsSlice = createSlice({
  name: ILT_ATTACHMENTS_SLICE,
  initialState,
  reducers: {
    resetState: () => initialState,
    setLoading: (state, { payload }) => {
      state.isLoading = payload;
    },
    setAttachmentDetails: (state, { payload }) => {
      state.details = { ...payload };
    },
    toggleCreateDialog: (state, { payload }) => {
      state.isCreateDialogOpen = payload;
    },
    setFilterParams: (state, action) => {
      const newFilterValues = Array.isArray(action.payload)
        ? action.payload.reduce((obj, item) => ({ ...obj, [item.key]: item.value }), {})
        : { [action.payload.key]: action.payload.value };

      state.filter = {
        ...state.filter,
        ...newFilterValues,
        page: newFilterValues.page ?? 0
      };
      state.isLoading = !!(action.payload.key === 'search' && !action.payload.value);
    },
    setSelectionModel: (state, { payload }) => {
      state.selectionModel = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAttachments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAttachments.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isError = false;
        state.data = payload.content;
        state.filter = {
          ...state.filter,
          page: payload?.pageable?.pageNumber ?? state.filter.page,
          size: payload?.pageable?.pageSize ?? state.filter.size
        };
        state.totalElements = payload?.totalElements ?? state.totalElements;
      })
      .addCase(getAttachments.rejected, (state) => {
        state.data = [];
        state.isLoading = false;
        state.isError = true;
        state.totalElements = 0;
      })
      .addCase(removeAttachments.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeAttachments.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.selectionModel = [];
        state.totalElements = payload;
        state.filter.page = Math.min(
          state.filter.page,
          Math.max(0, Math.floor((state.totalElements - 1) / state.filter.size))
        );
      })
      .addCase(removeAttachments.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
      })
      .addCase(uploadAttachment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(uploadAttachment.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.totalElements = payload;
        state.isCreateDialogOpen = false;
      })
      .addCase(uploadAttachment.rejected, (state) => {
        state.isError = true;
        state.isLoading = false;
        state.isCreateDialogOpen = false;
      })
      .addCase(patchAttachment.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(patchAttachment.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(patchAttachment.fulfilled, (state, { payload, meta }) => {
        payload.visibilityAccessGranted = createVisibilityFieldText(payload);
        state.data = state.data.map((item) =>
          item.id === meta.arg.id ? deepMerge(item, payload) : item
        );
        state.details = deepMerge(state.details, payload);
        state.isLoading = false;
      });
  }
});

export const selectIsLoading = (state) => state.ILT_ATTACHMENTS_SLICE.isLoading;
export const selectData = (state) => state.ILT_ATTACHMENTS_SLICE.data;
export const selectFilter = (state) => state.ILT_ATTACHMENTS_SLICE.filter;
export const selectTotalElements = (state) => state.ILT_ATTACHMENTS_SLICE.totalElements;
export const selectSelectionModel = (state) => state.ILT_ATTACHMENTS_SLICE.selectionModel;
export const selectIsCreateDialogOpen = (state) => state.ILT_ATTACHMENTS_SLICE.isCreateDialogOpen;
export const selectDetails = (state) => state.ILT_ATTACHMENTS_SLICE.details;

const { actions, reducer } = attachmentsSlice;

export const {
  resetState,
  setLoading,
  filterUpdate,
  setSelectionModel,
  toggleCreateDialog,
  setAttachmentDetails,
  setFilterParams
} = actions;

export default reducer;
